import React, { Component } from 'react'
import { connect } from 'react-redux'

import Grid from './common/grid/grid'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper'

import { loadNextEvents, loadPrevEvents } from '../store/entities/events'
import Paginator from './common/paginator/paginator'
import './incidentGrid.scss'

class IncidentGrid extends Component {
  state = {
    resultsArr: [this.props.threatFilters.offset + 20]
  }

  componentDidMount(){
    const {events, threatFilters} = this.props
    const { offset } = threatFilters
    if (events.length > 0) {
      this.setState({resultsArr: [...this.state.resultsArr, offset + 20]})
    }
  }

  componentDidUpdate(prevProps){
    const {events, selectedCustomer, threatFilters} = this.props
    const { offset } = threatFilters
    if (prevProps.events !== events && events.length > 0) {
      this.setState({resultsArr: [...this.state.resultsArr, offset + 20]})
    }
    
    let filterChange = false
    if(prevProps.threatFilters['endTime'] && threatFilters['endTime'] && prevProps.threatFilters['endTime'] !== threatFilters['endTime']) {
      filterChange = true
    }
    if(prevProps.threatFilters['startTime'] && threatFilters['startTime'] && prevProps.threatFilters['startTime'] !== threatFilters['startTime']) {
      filterChange = true
    }
    if(prevProps.threatFilters['type'] && threatFilters['type'] && prevProps.threatFilters['type'] !== threatFilters['type']) {
      filterChange = true
    }
    if(prevProps.threatFilters['cleared'] && threatFilters['cleared'] && prevProps.threatFilters['cleared'] !== threatFilters['cleared']) {
      filterChange = true
    }
    if(prevProps.threatFilters['order'] && threatFilters['order'] && prevProps.threatFilters['order'] !== threatFilters['order']) {
      filterChange = true
    }
    if((typeof prevProps.threatFilters['extensionUserId'] === undefined && threatFilters['extensionUserId']) || (prevProps.threatFilters['extensionUserId'] && typeof threatFilters['extensionUserId'] === undefined)) {
      filterChange = true
    }
    if(prevProps.threatFilters['extensionUserId'] && threatFilters['extensionUserId'] && prevProps.threatFilters['extensionUserId'] !== threatFilters['extensionUserId']) {
      filterChange = true
    }
    if((typeof prevProps.threatFilters['brand'] === undefined && threatFilters['brand']) || (prevProps.threatFilters['brand'] && typeof threatFilters['brand'] === undefined)) {
      filterChange = true
    }
    if(prevProps.threatFilters['brand'] && threatFilters['brand'] && prevProps.threatFilters['brand'] !== threatFilters['brand']) {
      filterChange = true
    }
    if((typeof prevProps.threatFilters['triagedEvent'] === undefined && threatFilters['triagedEvent']) || (prevProps.threatFilters['triagedEvent'] && typeof threatFilters['triagedEvent'] === undefined)) {
      filterChange = true
    }
    if(prevProps.threatFilters['triagedEvent'] && threatFilters['triagedEvent'] && prevProps.threatFilters['triagedEvent'] !== threatFilters['triagedEvent']) {
      filterChange = true
    }
    if((typeof prevProps.threatFilters['removeRepeateUrl'] === undefined && threatFilters['removeRepeateUrl']) || (prevProps.threatFilters['removeRepeateUrl'] && typeof threatFilters['removeRepeateUrl'] === undefined)) {
      filterChange = true
    }
    if((typeof prevProps.threatFilters['removeThreatUrl'] === undefined && threatFilters['removeThreatUrl']) || (prevProps.threatFilters['removeThreatUrl'] && typeof threatFilters['removeThreatUrl'] === undefined)) {
      filterChange = true
    }
    if((typeof prevProps.threatFilters['includeFalsePositive'] === undefined && threatFilters['includeFalsePositive']) || (prevProps.threatFilters['includeFalsePositive'] && typeof threatFilters['includeFalsePositive'] === undefined)) {
      filterChange = true
    }

    if(filterChange || prevProps.selectedCustomer !== selectedCustomer) {      
      this.setState({resultsArr: [offset + 20]})
    }
  }

  render() {
    const {
      events,
      totalSize,
      loading,
      threatFilters,
      loadNextEvents,
      loadPrevEvents,
    } = this.props
    const { offset, pageSize } = threatFilters

    return (
      <div className="d-flex flex-column pageContainer">
        <LoaderWrapper 
          loading={loading} data={events}
          styles={{ minHeight: '620px' }} placeholderText={"No Threats Detected"}
        > 
          <>
            <div className={'pl-4 ml-2'}>
              <span className='resultText'>Results (
                                          {totalSize % 21 !== 0
                                          ? events.length
                                          : `More than ${Math.max(...this.state.resultsArr)}`})
              </span>
            </div>
            <div className="d-flex flex-column grid-container mt-3">
              <div className={'grid-body'} >
                <Grid
                  records={events.slice(offset, offset + 20)}
                  onDetails={this.props.onDetails}
                  role={this.props.tokenRoles}
                />
              </div>
              <div className={`d-flex justify-content-center align-items-end`} >
                <Paginator
                  onNext={loadNextEvents}
                  onPrev={loadPrevEvents}
                  filters={threatFilters}
                  totalSize={totalSize % 21 !== 0 ? events.length : events.length + 1}
                  cacheSize={events.length}
                  from={"incidentGrid"}
                />
              </div>
            </div>
          </>
        </LoaderWrapper>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  events: state.entities.events.list,
  threatFilters: state.entities.events.threat.filters,
  loading: state.entities.events.loading,
  totalSize: state.entities.events.totalSize,
  tokenRoles: state.entities.user.roles,
  selectedCustomer: state.entities.customers.selected,
})

const mapDispatchToProps = (dispatch) => ({
  loadNextEvents: (offset, customerId, defaultCustomerId, isGlobalView) => dispatch(loadNextEvents(offset, customerId, defaultCustomerId, isGlobalView)),
  loadPrevEvents: (offset, customerId, defaultCustomerId, isGlobalView) => dispatch(loadPrevEvents(offset, customerId, defaultCustomerId, isGlobalView)),
})

export default connect(mapStateToProps, mapDispatchToProps)(IncidentGrid)
