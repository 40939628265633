import React, { useEffect, useState } from 'react'
import { Doughnut } from 'react-chartjs-2'
import './Charts.scss'
import Loading from '../common/loading'
import ChartDataLabels from 'chartjs-plugin-datalabels'

const ChartNestedDoughnut = (props) => {
  const [showLoader, setShowLoader] = useState(true)
  const [legend1, setLegend1] = useState([])
  const [values1, setValues1] = useState([])
  const [legend2, setLegend2] = useState([])
  const [values2, setValues2] = useState([])
  const [loaderStyles, setLoaderStyles] = useState({
    marginTop: '140px',
  })
  const blueShades = [
    '#2d5fa7',
    '#859dce',
    '#e3e3e3',
    '#008ce0',
    '#a3a3a3',
    '#859dce',
    '#979797',
  ]
  const greenShades = [
    '#85ceae',
    '#c9e2d7',
    '#093a3b',
    '#1a5859',
    '#467575',
    '#3f9092',
    '#58a5ae',
  ]

  useEffect(() => {
    if (legend1 && values1 && (legend1.length > 0 || values1.length > 0)) {
      setLegend1([])
      setValues1([])
    }
    if (legend2 && values2 && (legend2.length > 0 || values2.length > 0)) {
      setLegend2([])
      setValues2([])
    }
    if (props.data[0] && props.data[1]) {
      let arr1 = props.data[0]['values'].count
      let arr2 = props.data[1]['values'].count
      let v1_total = arr1.reduce((acc, curr) => acc + curr, 0)
      let v2_total = arr2.reduce((acc, curr) => acc + curr, 0)
      let v1 = arr1.map((ele) => Math.round((ele / v1_total) * 100))
      let v2 = arr2.map((ele) => Math.round((ele / v2_total) * 100))
      let valObj1 = {}
      let valObj2 = {}
      valObj1.percentValues = v1
      valObj1.actualValues = arr1
      valObj2.percentValues = v2
      valObj2.actualValues = arr2
      setLegend1(props.data[0]['legend'])
      setValues1(valObj1)
      setLegend2(props.data[1]['legend'])
      setValues2(valObj2)
      setShowLoader(props.loading)
    }
  }, [props])

  const data = {
    datasets: [
      {
        data: values2 && values2.percentValues ,
        countData: values2.actualValues,
        backgroundColor: blueShades,
        radius: 105,
        cutout: '65%',
        // borderWidth: 1,
        // borderColor: '#E3E3E3'
      },
      {
        data: values1 && values1.percentValues,
        countData: values1.actualValues,
        backgroundColor: greenShades,
        radius: 60,
        cutout: '40%',
        // borderWidth: 1,
        // borderColor: '#E3E3E3'
      },
    ],
  }

  const toolTip = (context) => {
    let value = context.dataset.countData[context.dataIndex] 
    let toolTipText= ` Count: ${value > 999999
            ? (value / 1000000).toFixed(2)+ 'M'
            : value > 999
            ? (value / 1000).toFixed(2) + 'K'
            : value}`
    toolTipText = `${toolTipText} (${context.formattedValue}%)`
    
    return toolTipText
  }
  
  const options = {
    scales: {
      yAxes: [{
          ticks: {
              beginAtZero:true
          }
      }]
  },
    plugins: {
      tooltip: {
        callbacks: {
          label: toolTip,
        },
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'end',
        formatter: function(value) {
            return value + '%';
        },
        font: {
          weight: 600
        }
      },
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    elements: {
      arc: {
        borderWidth: 1,
        borderColor: '#E3E3E3',
      },
    },
  }

  const plugins = [ChartDataLabels]

  return (
    <>
      {!showLoader ? (
        <>
          <div className="pt-3 pl-3 d-flex">
            <div className="legendHead">System</div>
            <ul style={{ listStyleType: 'none' }}>
              {legend1.map((legend, i) => {
                return (
                  <li key={i} className="d-inline-flex">
                    <div
                      className="legendOval"
                      style={{ background: greenShades[i] }}
                    ></div>
                    <span className="legendItem">
                      {legend === 'ios' ? 'iOS' : legend === 'cros'? 'Chrome OS': legend.charAt(0).toUpperCase() + legend.slice(1)}
                    </span>
                  </li>
                )
              })}
            </ul>
          </div>
          <div>
            <Doughnut data={data} options={options} plugins={plugins} height={258} width={238} />
          </div>
          <div className="pl-3 pt-4 d-flex">
            <div className="legendHead">Browser</div>
            <ul style={{ listStyleType: 'none' }}>
              {legend2.map((legend, i) => {
                return (
                  <li key={i} className="d-inline-flex">
                    <div
                      className="legendOval"
                      style={{ background: blueShades[i] }}
                    ></div>
                    <span className="legendItem">
                      {legend.charAt(0).toUpperCase() + legend.slice(1)}
                    </span>
                  </li>
                )
              })}
            </ul>
          </div>
        </>
      ) : (
        <div style={{ height: '302x', width: '416px', position: 'relative' }}>
          <Loading loading={true} styles={loaderStyles} />
        </div>
      )}
    </>
  )
}

export default ChartNestedDoughnut
