import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import ChartDoubleBar from './ChartDoubleBar'

const ChartDoubleBarBuild = (props) => {
  const [eventsDates, setEventsDates] = useState([])
  const [verifiedPhish, setVerifiedPhish] = useState([])
  const [suspicious, setSuspicious] = useState([])
  let verifiedTotal = 0
  let suspiciousTotal = 0

  let diffDays = 0

  useEffect(() => {
    if (props.records !== null) {
      buildChart()
    }
  }, [props])

  const buildChart = () => {
    setEventsDates([])
    setVerifiedPhish([])
    setSuspicious([])
    let _eventDates = [];
    let _verifiedPhish = [];
    let _suspicious = []; 
    props.records.forEach(element => {
      _eventDates.push(moment(element['detectionTime']).format('DD MMM'));
      if(props.triagedEvent == null){
        _verifiedPhish.push(element['verified']);
        _suspicious.push(element['suspicious']);
      }
      else if(props.triagedEvent == 1){
        _verifiedPhish.push(element['verified']);
        _suspicious.push(0);
      }
      else if(props.triagedEvent == 0){
        _verifiedPhish.push(0);
        _suspicious.push(element['suspicious']);
      }
     
    });

    setEventsDates(_eventDates)
    setVerifiedPhish(_verifiedPhish)
    setSuspicious(_suspicious)
  }

  return (
    <ChartDoubleBar
      xaxis={eventsDates.length > 0 ? eventsDates : null}
      dataArray1={suspicious.length > 0 ? suspicious : null}
      dataArray2={verifiedPhish.length > 0 ? verifiedPhish : null}
    />
  )
}

const mapStateToProps = (state) => ({
  filters: state.entities.events.filters,
  totalSize: state.entities.events.chartTotalSize,
})

export default connect(mapStateToProps)(ChartDoubleBarBuild)
