import React, { Component,} from 'react';
import Style from './AdvancedSettings.module.css';
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
    getCustomerAlertConfig,
    updateCustomerAlertConfig,
} from '../store/entities/customers';
import ModalPopup from '../components/common/ModalPopup';

const DEFAULT_EXTENSION_SETTINGS =  [
  { type: "incident", report: true, alert: true},
  { type: "threat", report: true, alert: true}
];

class ExtensionSettings extends Component {

  constructor(props) {
    super(props)
    this.state = {
        extn_settings : DEFAULT_EXTENSION_SETTINGS
      };
  }

  componentDidMount() {
    this.props.getCustomerAlertConfig(this.props.selectedCustomer?.id)
  }

  componentDidUpdate(prevProps) {
    if (this.props.alertConfig && prevProps.alertConfig !== this.props.alertConfig) {
      let {extn_settings} = this.props.alertConfig
      if(extn_settings){
          this.setState({
              extn_settings
          })
      }
      else {
          console.warn("Extension settings not found! Setting default settings")
          this.setState({
              extn_settings : DEFAULT_EXTENSION_SETTINGS
          })
      }
    }

    if(prevProps.selectedCustomer != this.props.selectedCustomer){
      this.props.getCustomerAlertConfig(this.props.selectedCustomer.id)
    }
  }

  onChangeSetting = (type, setting) => {
    this.setState(prevState => ({
      extn_settings: prevState.extn_settings.map(item => {
          if (item.type === type) {
              let newItem = { ...item, [setting]: !item[setting] };
              // If report is turned off, also turn off alert
              if (setting === 'report' && !newItem.report) {
                  newItem.alert = false;
              }
              return newItem;
          }
          return item;
      })
    }));
  }

  handleOnUpdate = () => {
    // console.log("Updating the settings ", this.state.extn_settings)
    this.props.updateCustomerAlertConfig(this.props.selectedCustomer.id, {
    ...this.props.alertConfig,
    extn_settings : this.state.extn_settings,
    })
  }

  render(){
    return (
      <div  className={`Container`} >
      {/* // style={{padding: '0 1.8% 0 2.4%',margin: '0 0 0 43px',}}> */}

        <div className={`mt-3`}>
            <div className={`${Style.profileFontStyle}`}>
                Extension Settings
            </div>
            <div className={`${Style.containerStyle} pb-2 px-4`}>
                {
                    this.props.alertConfigLoading ?
                    <LoaderWrapper loading={this.props.alertConfigLoading} waitForData={false} isFlex={true} styles={{top: '100px'}}></LoaderWrapper>
                    :
                    <div className={`${Style.textStyle} pt-4`}>
                        {
                            this.state.extn_settings.map(item => (
                                <div className={Style.settings_container} key={item.type}>
                                    <h4 id='setting_title'>{item.type == "threat" ? "Threat" : item.type == "incident" ? "Incident" : "NA"}</h4>                                
                                    <div className={Style.setting_switch}>
                                        <div className={Style.setting}>
                                            Reporting
                                            <Form.Check
                                            type="switch"
                                            id={`${item.type}_report`}
                                            checked={item.report}
                                            value={item.report}
                                            onChange={() => this.onChangeSetting(item.type, "report")}
                                            />
                                        </div>
                                        <Form.Text muted>If turned off, {item.type} reporting will be turned off. Also turns off the {item.type} Alerts.</Form.Text><br/>
                                        <div className={Style.setting}>
                                            Alert
                                            <Form.Check
                                            type="switch"
                                            id={`${item.type}_alert`}
                                            checked={item.alert}
                                            value={item.alert}
                                            onChange={() => this.onChangeSetting(item.type, "alert")}
                                            disabled = {!item.report}
                                            />
                                        </div>
                                        <Form.Text muted>If turned off, {item.type} Alerts will not be displayed to user.</Form.Text>
                                        </div>
                                    <div className={`${Style.border} mt-3`} />
                                </div>                            
                            ))
                        }
                        <div className={`mt-5`}>
                            <button onClick={this.handleOnUpdate} className={`${Style.submitButton} focus-none`} > Update Info </button>
                        </div>
                    </div>
                }
            </div>
        </div>
        {this.props.statusCode && <ModalPopup statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
    </div>
    )
  }
}
const mapStateToProps = (state) => ({
  userRole: state.entities.user.roles,
  selectedCustomer: state.entities.customers.selected,
  alertConfig: state.entities.customers.alertConfig,
  statusCode : state.entities.customers.statusCode,
  userMsg : state.entities.customers.userMsg,
  alertConfigLoading : state.entities.customers.alertConfigLoading
})

const mapDispatchToProps = (dispatch) => ({
  getCustomerAlertConfig: (customerId) => dispatch(getCustomerAlertConfig(customerId)),
  updateCustomerAlertConfig: (customerId, data) => dispatch(updateCustomerAlertConfig(customerId, data)),
})
export default connect(mapStateToProps, mapDispatchToProps)(ExtensionSettings)
