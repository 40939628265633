export const globalViewText = "Global View";

export const isGlobalView = (organizationName) => organizationName === globalViewText;

export const addGlobalViewAsCustomer = (tenant) => {
  let globalEntry = {
    "id": tenant.tenantId,
    "isActive": true,
    "isProvisioned": true,
    "created": null,
    "organizationName": globalViewText,
    "tenantId": tenant.tenantId,
    "firstName": globalViewText,
    "lastName": "",
    "email": tenant.email,
    "phone": null
  };
  return globalEntry;
}

// uncomment below menus data to activate menus on navbar
export const menus = [
  // {
  //   'menuId': 'menu.dashboard',
  //   'name': 'Dashboard',
  //   'roles': ['PixmAdmin'],
  //   'route': '/dashboard'
  // },
  // {
  //   'menuId': 'menu.threatReview',
  //   'name': 'Threat Review',
  //   'roles': ['PixmAdmin', 'CustomerAdmin', 'TenantAdmin'],
  //   'route': '/account/customerId/incidents'
  // },
  // {
  //   // 'menuId': 'menu.admin.deployment',
  //   'menuId': 'menu.deployment',
  //   'name': 'Deployment',
  //   'roles': ['PixmAdmin', 'CustomerAdmin', 'TenantAdmin'],
  //   'route': '/account/customerId/deployment'
  // },
  // {
  //   //'menuId': 'menu.admin.licenseManagement',
  //   'menuId': 'menu.licenseManagement',
  //   'name': 'License Management',
  //   'roles': ['PixmAdmin', 'CustomerAdmin', 'TenantAdmin'],
  //   'route': '/account/customerId/license-management'
  // }
]

export const escapeDropdown = [
  // 'tenant-admin'
]

export const confirmVerify = {
  icon: '',
  type: 'warning',
  title: 'Are You Sure ?',
  description: 'Do you want to confirm this URL as threat or incident ?',
  buttons: 'Y,C',
}

export const confirmUnverify = {
  icon: '',
  type: 'info',
  title: 'Are You Sure ?',
  description: 'Do you want to mark this URL  as unverified ?',
  buttons: 'Y,C',
}

export const confirmFalsePositive = {
  icon: '',
  type: 'info',
  title: 'Are You Sure ?',
  description: 'Do you want to mark this URL as false positive ?',
  buttons: 'Y,C',
}

export const confirmClear = {
  icon: '',
  type: 'error',
  title: 'Are You Sure ?',
  description: 'Do you want to delete this URL ?',
  buttons: 'Y,C',
}

export const hideSafeOnMsg = "By turning it off, the 'This is Safe' button on alert page will be visible"

export const hideSafeOffMsg = "By turning it on, the 'This is Safe' button on alert page will be hidden"