import React, { Component,} from 'react';
import { connect } from 'react-redux';
import { Card, Form } from 'react-bootstrap';
import Style from './AdvancedSettings.module.css';
import {
  getCustomerAlertConfig,
  updateCustomerAlertConfig,
} from '../store/entities/customers';
import LoaderWrapper from "./common/LoaderWrapper/LoaderWrapper";
import ModalPopup from '../components/common/ModalPopup';


class GoogleFormSettings extends Component {

  constructor(props) {
    super(props)
    this.state = {
        isGFScanningEnabled : false
      };
  }

  componentDidMount() {
    // this.props.getCustomerAlertConfig(this.props.selectedCustomer?.id)
  }

  componentDidUpdate(prevProps) {
    if (this.props.alertConfig && prevProps.alertConfig !== this.props.alertConfig) {
      let isGFSEnabled = this.props.isGFormScanningEnabled
      if(isGFSEnabled){
          this.setState({
              isGFScanningEnabled : isGFSEnabled
          })
      }
      else {
          console.warn("isGFScanningEnabled settings not found! Setting default settings")
          this.setState({
              isGFScanningEnabled : false
          })
      }
    }

    if(prevProps.selectedCustomer != this.props.selectedCustomer){
      this.props.getCustomerAlertConfig(this.props.selectedCustomer.id)
    }
  }

  handleOnGFEnabledChange = (e) => {
    this.setState({
      isGFScanningEnabled: e.target.checked
    });
    this.props.updateCustomerAlertConfig(this.props.selectedCustomer?.id, 
      {    ...this.props.alertConfig,
        isGFormScanningEnabled: e.target.checked
      });
  }
  


render() {
  let {alertConfigLoading, isGFormScanningEnabled} = this.props;
  return (

    <div className={`mt-3 h-100`}>
      <>
        <div className={`${Style.profileFontStyle}`}>
               Google Form Settings
            </div>
        <Card className="" style={{width: "641px", height:"auto", minHeight:"150px" }}>
        {
      alertConfigLoading ?
      <LoaderWrapper loading={alertConfigLoading} waitForData={false}></LoaderWrapper>
      :
            <Card.Body>
              <Form>
                <Form.Group className={`mb-1 ${Style.setting}`} controlId="formBasicEmail">
                <Form.Label style={{ fontSize: '20px' }}>Enable Google Form Phishing Detection</Form.Label>

                  <Form.Check
                    type="switch"
                    id="google-form-switch"
                    label=""
                    checked={isGFormScanningEnabled}
                    onChange={(e) => this.handleOnGFEnabledChange(e)}
                  />
                  
                </Form.Group>
                <Form.Text muted>If turned ON users will be protected against Phishing attacks attempted by using Google Forms.</Form.Text><br/>

              </Form>
            </Card.Body>
        }
        </Card>
        </>  
        {this.props.statusCode && <ModalPopup statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
    </div>
  );
};
}

const mapStateToProps = (state) => ({
  userRole: state.entities.user.roles,
  selectedCustomer: state.entities.customers.selected,
  alertConfig: state.entities.customers.alertConfig,
  isGFormScanningEnabled: state.entities.customers.isGFormScanningEnabled,
  statusCode : state.entities.customers.statusCode,
  userMsg : state.entities.customers.userMsg,
  alertConfigLoading : state.entities.customers.alertConfigLoading
});

const mapDispatchToProps = (dispatch) => ({
  getCustomerAlertConfig: (customerId) => dispatch(getCustomerAlertConfig(customerId)),
  updateCustomerAlertConfig: (customerId, data) => dispatch(updateCustomerAlertConfig(customerId, data)),
})  

export default connect(mapStateToProps, mapDispatchToProps)(GoogleFormSettings);