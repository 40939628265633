import React from "react";
import PopupIcon from '../assets/popup_menu.PNG';
import MiniDash from '../assets/Pixm_logo.PNG';
import AddOrg from '../assets/add_org.PNG';
import Style from './TAPopup.module.css'

const Popup = props => {
  
  const clickHandler = () => {
    props.showOrHidePopup(false);
  }

  return (
    <div className="popup-box">
      <div className="box">
        <div className={Style.innerBox}>  
            <div className={Style.headerDiv}>
              <p style= {{margin:'5px'}}>Welcome to the MSP Center</p>
            </div>  
            <div className={Style.orgDiv}>
            <span style= {{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}><p>To get started, add your organization(s) by clicking the Add Organization button.</p></span>
            <span id={Style.addOrg}><img style={{ width: '180px' }} src={AddOrg} alt="add-org-icon" /></span> 
            </div>
            <div className={Style.menuDiv}>
            <span style= {{display: 'flex', flexDirection: 'column', justifyContent: 'center'}}><p>Navigate to Deployment in main menu to start installing Pixm and stopping attacks.</p></span>
            <span id={Style.menuImg}><img style={{ width: '180px' }} src={PopupIcon} alt="popup-icon" /></span> 
            </div> 
            <img className={Style.MiniDash} src={MiniDash} alt={'mini-icon'} />
          <div className= {Style.buttonDiv}>
            <button className= {Style.buttonOk} onClick={clickHandler}>Ok</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Popup;