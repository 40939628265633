import React, { Fragment } from 'react'

const Option = (props) => {
  return (
    <Fragment>
      <div
        className="option"
        htmlFor={props.optionId}
        onClick={(e) => props.onOptionClick(e, props.optionName)}
      >
        <input
          type="checkbox"
          className="checkbox"
          id={props.optionId}
          name={props.optionName}
          value={props.label}
        />
        <label style={{ margin: 0 }}>{props.label}</label>
      </div>
      <span style={{ borderBottom: '1px solid #e3e2e2' }}></span>
    </Fragment>
  )
}

export default Option
