import React, {Component} from 'react'
import Style from './AdminManagement.module.css'
import { getUsersList, removeAdmin } from '../store/entities/customers'
import { connect } from 'react-redux'
import { formatDetectionDatetime } from '../utilities/datetime'
import { withRouter } from 'react-router-dom';
import ModalPopup from '../components/common/ModalPopup'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper'


import DashIcon from '../assets/minus.png' 
import PlusIcon from '../assets/pluss.png'
import { height } from '@fortawesome/free-brands-svg-icons/fa42Group'

class AdminManagement extends Component {

    componentDidMount() {
        if(this.props.selectedCustomer && this.props.tokenRoles === 'TenantAdmin') {
            this.props.getUsersList(null, this.props.selectedCustomer.id, 'CustomerAdmin')
        } else if(this.props.tokenRoles === 'CustomerAdmin') {
            this.props.getUsersList(null, null, null)
        } else if(this.props.selectedCustomer && this.props.tokenRoles === 'PixmAdmin') {
            let tenantDetailsForCustomer = this.props.tenantList.filter((item) => item.tenantId === this.props.selectedCustomer.tenantId)
            this.props.getUsersList(tenantDetailsForCustomer[0] && tenantDetailsForCustomer[0].gcipTenantId, this.props.selectedCustomer.id, 'CustomerAdmin')
        }   
    }

    componentDidUpdate(prevProps) {
        if(prevProps.userFlag !== this.props.userFlag) {
            if(this.props.userFlag && this.props.tokenRoles === 'TenantAdmin') {
                this.props.getUsersList(null, this.props.selectedCustomer.id, 'CustomerAdmin')
            } else if(this.props.tokenRoles === 'CustomerAdmin') {
                this.props.getUsersList(null, null, null)
            } else if(this.props.selectedCustomer && this.props.tokenRoles === 'PixmAdmin') {
                let tenantDetailsForCustomer = this.props.tenantList.filter((item) => item.tenantId === this.props.selectedCustomer.tenantId)
                this.props.getUsersList(tenantDetailsForCustomer[0].gcipTenantId, this.props.selectedCustomer.id, 'CustomerAdmin')
            }
        }
    }

    handleRemoveAdmin = (userId) => {
            this.props.removeAdmin(userId)
    }

    handleInviteAdmin = () => {
        this.props.history.push({pathname: `/customers/${this.props.selectedCustomer.id}/settings/add-admin`}) 
    }

    render() {
        let { users } = this.props
        return (
            <div>
                <div className={'mt-4 w-100'}>
                    <div className={`${Style.titleStyle}`}>
                        Admin User Management
                    </div>
                    <div className={`${Style.shortInfoStyle} w-100 d-flex align-items-center mt-3 px-5`}>
                        <div className={`${Style.borderStyle} px-4 h-70`} style={{width: 'inherit'}}>
                            <div className={`${Style.fontBold}`}>
                                Current Admin Users
                            </div>
                            <div className={`${Style.currentAdminCount}`}>
                                {
                                    this.props.loading ?
                                        <LoaderWrapper loading={this.props.loading} isFlex={true} styles={{top: '15px', width: '100px'}} waitForData={false}></LoaderWrapper>
                                    :
                                        users.length
                                }
                            </div>
                        </div>
                        <div style={{width: '20%'}} className={'ml-4'}>
                            <div className={`${Style.fontBold}`}>
                            Invite Admin
                            </div>
                            <div className={'d-flex mt-3'}>
                                <button disabled={ this.props.tokenRoles === 'PixmAdmin' } onClick={this.handleInviteAdmin} className={`${Style.submitButton} focus-none px-4`}>Add and invite admin</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'mt-5'}>
                    <div className={Style.adminsStyle}>
                        Admins
                    </div>
                    <div className={'mt-3'}>
                        <div className={`d-flex px-5 mx-2`}>
                            <div className={`${Style.fontBold}`} style={{width:'18%'}}><b>Name</b></div>
                            <div className={`${Style.fontBold}`} style={{width:'60%'}}><b>Email</b></div>
                            <div className={`${Style.fontBold}`} style={{width:'22%'}}><b>Actions</b></div>
                        </div>
                        <div className={`${Style.tableContainerStyle} p-4 mt-3`}>
                            <div className={`${Style.tableStyle} ${Style.ScrollStyle}`}>
                                {
                                    this.props.loading ?
                                        <LoaderWrapper loading={this.props.loading} isFlex={true} styles={{top: '50%'}} waitForData={false}></LoaderWrapper>
                                    :
                                    users.map((item, index) => {
                                        return <div key={item.uid} className={`${ index%2 === 0 ? Style.rowBg : Style.customRowBg} px-4 py-2`}>
                                            <div className={`d-flex p-2`}>
                                                <div className={`${Style.fontBold}`} style={{width:'18%'}}>
                                                    {item.displayName}
                                                </div>
                                                <div style={{width:'60%'}}>
                                                    <div>
                                                        {item.email}
                                                    </div>
                                                    <div>
                                                        Created <i> {formatDetectionDatetime(item.metadata.creationTime)}</i>
                                                    </div>
                                                </div>
                                                <div onClick={() => this.handleRemoveAdmin(item.uid)} className={`${Style.cursorStyle} d-flex`} style={{width:'22%'}}>
                                                    <div>
                                                        <img style={{height:'15px'}} src={DashIcon} alt="remove-icon"/>
                                                    </div>
                                                    <div className={`${Style.removeAdmin} ml-1`}>
                                                        Remove Admin
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            
                            <div className={`${Style.inviteUserBox} d-flex p-4 mx-1 align-items-center`}>
                                <div>
                                    <img style={{height:'20px'}} src={PlusIcon} alt="plus-icon"/>
                                </div>
                                <div>
                                    <button disabled={ this.props.tokenRoles === 'PixmAdmin' } onClick={this.handleInviteAdmin} className={`${Style.submitButton} focus-none px-4 ml-3`}>Add and invite admin</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.props.statusCode && <ModalPopup statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    users: state.entities.customers.userList,
    selectedCustomer: state.entities.customers.selected,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode,
    userFlag: state.entities.customers.userListFlag,
    tokenRoles: state.entities.user.roles,
    gcipTenantId: state.entities.user.gcipTenantId,
    loading: state.entities.customers.loading,
    tenantList: state.entities.customers.tenantList
})

const mapDispatchToProps = (dispatch) => ({
    getUsersList: (gcipTenantId, customerId, roleType) => dispatch(getUsersList(gcipTenantId, customerId, roleType)),
    removeAdmin: (userId) => dispatch(removeAdmin(userId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminManagement))