import React, { Component } from 'react'
import { connect } from 'react-redux'
import { verifyEvents, removeIncident } from '../../../store/entities/events'
import ConfirmPopup from './confirmPopup'

class UpdateVerify extends Component {
  state = {
    shouldShow: false,
    showPopup: 0,
    verify: this.props.event.triagedEvent,
    messageVerify: {
      icon: '',
      type: 'warning',
      title: 'Are You Sure ?',
      description: 'Do you want to confirm this URL as threat or incident ?',
      buttons: 'Y,C',
    },
    messageUnverify: {
      icon: '',
      type: 'info',
      title: 'Are You Sure ?',
      description: 'Do you want to mark this URL  as unverified ?',
      buttons: 'Y,C',
    },
  }

  componentDidMount() {
    const { role, tokenRoles } = this.props
    const accessibleRoles = role.split(',')
    const shouldShow = accessibleRoles.filter((value) =>
      tokenRoles.includes(value),
    )
  }

  handleVerifyChange = () => {
    if (this.state.verify === false) {
      this.setState({ showPopup: 1 })
    } else {
      this.setState({ showPopup: 2 })
    }
  }

  handleVerify = () => {
    const { event, verifyEvents, selectedCustomer } = this.props
    const {
      id: eventId,
      url,
      detectionTime,
      email: user,
      userAction,
      brands,
    } = event
    verifyEvents(
      [{ eventId, url, detectionTime, user, userAction, brands }],
      selectedCustomer.id,
    )
    this.setState({ verify: true })
    this.redirectToIncidents()
  }

  handleRemove = () => {
    const { event, removeIncident, selectedCustomer } = this.props
    const { id: eventId } = event
    removeIncident({ eventId }, selectedCustomer.id)
    this.setState({ verify: false })
    this.redirectToIncidents()
  }

  redirectToIncidents = () => {
    if (this.props.from === 'incidentDetails') {
      this.props.history.push('/incidents')
    }
  }

  handleClose = () => {
    this.setState({ showPopup: 0 })
  }

  render() {
    return (
      <>
        <input
          type="checkbox"
          disabled={this.props.role !== "PixmAdmin" ? true : false}
          checked={this.state.verify}
          onChange={this.handleVerifyChange}
        />
        {this.state.showPopup > 0 && (
          <ConfirmPopup
            details={this.state.showPopup === 1 ? this.state.messageVerify : this.state.messageUnverify}
            onYesClick={this.state.showPopup === 1 ? this.handleVerify : this.handleRemove}
            onCancelClick={this.handleClose}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  tokenRoles: state.entities.user.roles,
  selectedCustomer: state.entities.customers.selected,
})

const mapDispatchToProps = (dispatch) => ({
  verifyEvents: (events, customerId) =>
    dispatch(verifyEvents(events, customerId)),
  removeIncident: (events, customerId) =>
    dispatch(removeIncident(events, customerId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateVerify)
