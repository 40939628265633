export const authInitialState = {
    isLoggedIn: false,
    id: null,
    roles: null,
    name: null,
    decodedToken: null,
    tenantId: null,
    email: null,
    gcipTenantId: null,
    sendEmail: null,
    domain : null,
  }