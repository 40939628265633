import React, { Component } from 'react'
import Style from './UpdateOrganization.module.css'
import { connect } from 'react-redux'
import { deleteCustomer, updateSelectedCustomer, setError } from '../store/entities/customers'
import { setNewToken } from '../store/entities/user'
import jwt_decode from "jwt-decode";
import ModalPopup from '../components/common/ModalPopup'
import Modal from 'react-bootstrap/Modal'
import BackIcon from '../assets/backButton.svg'
import {CookieUtils, setcookie} from '../utilities/cookies'


class UpdateOrganization extends Component {

    constructor () {
        super();
        this.state = {
            id: '',
            isActive: false,
            firstName: '',
            lastName: '',
            organizationName:'',
            showConfirmBox:false,
            orgCode : ''
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.customerFlag !== this.props.customerFlag) {
            if (this.props.customerFlag) {
                this.props.setNewToken()
            }
        }
    }

    handleModalClick = () => {
        this.props.history.push({ pathname: '/tenant-admin', state: { tab: 'Organization Management' } })
    }

    componentDidMount() {
        if(this.props.location.state.tenant) {
            this.setState({
                id: this.props.location.state.tenant.id,
                isActive: this.props.location.state.tenant.isActive,
                firstName: this.props.location.state.tenant.firstName,
                lastName: this.props.location.state.tenant.lastName,
                organizationName: this.props.location.state.tenant.organizationName,
                orgCode : this.props.location.state.tenant.orgCode
            })
        }
    }

    handleChange = (event) => {
        const value = event.target.value
        this.setState({    
            [event.target.name]: value
        })
    }

    handleUpdate = (customerId) => {
        if(this.validateFields()) {
            let body = {
                "firstName": this.state.firstName,
                "lastName": this.state.lastName,
                "organizationName": this.state.organizationName,
                "orgCode" : this.state.orgCode?.trim()
            }
            this.props.updateSelectedCustomer(customerId, body)
        }
        
    }

    handleDelete = (customerId) => {
        this.props.deleteCustomer(customerId)
        this.props.setNewToken()
    }

    handleClick = () => {
        this.props.history.push({ pathname: '/tenant-admin', state: { tab: 'Organization Management' } })
    }

    validateFields = () => {
        let isValid = true;
        if(this.state.firstName === '') {
            let error = "First name is required."
            isValid = false;
            this.props.setError(error)
        } 

        if(this.state.lastName === '') {
            let error = "Last name is required."
            isValid = false;
            this.props.setError(error)
        }

        if(this.state.organizationName === '') {
            let error = "Organization name is required."
            isValid = false;
            this.props.setError(error)
        }

        if(this.state.orgCode && this.state.orgCode.length > 50) {
            let error = "Organization Code cannot be more than 50 characters"
            isValid = false;
            this.props.setError(error)
        }

        if (this.state.orgCode && this.state.orgCode.length > 0 && /[\/'%\^$!?><&;]/.test(this.state.orgCode)) {
            let error = "Organization Code can not contain special Characters (/'%^$!?><&;)";
            isValid = false;
            this.props.setError(error);
        }

        return isValid;
    }

    render() {
        const {showConfirmBox} = this.state;

        return (
            <React.Fragment>
                <Modal
                    show={showConfirmBox}
                    onHide={() => { }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header >
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete the organization?
                    <br />
                    Admins will no longer have access to Threat feed
                    </Modal.Body>
                    <Modal.Footer>
                        <button className={`${Style.cancelButton} focus-none`} variant="secondary" onClick={() => { this.setState({ showConfirmBox: false }) }}>Close</button>
                        <button className={`${Style.submitButton} focus-none`} variant="primary" onClick={()=>this.handleDelete(this.state.id)}>Understood</button>
                    </Modal.Footer>
                </Modal>
            <div className={'px-5 mt-4'}>
                <div className={`${Style.headerBg} px-5 d-flex align-items-center justify-content-between`}>
                    <div onClick={this.handleClick} style={{cursor: 'pointer'}}>
                        <img style={{ height: '1.5vw' }} src={BackIcon} alt={'back-icon'} />
                        Return to Organization Management
                    </div>
                </div>
                <div className={`${Style.accountFont} mt-5`}>
                    Organization Info
                </div>
                <div className={`mt-4 d-flex w-100`}>
                    <div className={`${Style.containerStyle} px-4 w-50`}>
                        <div className={`${Style.textStyle} pt-4`}>
                        Organization Details
                        </div>
                        <div className={'mt-3 d-flex flex-column'}>
                            <input defaultValue={this.state.organizationName} value={this.state.organizationName} name='organizationName' placeholder={'Organization Name'} className={`${Style.inputStyle} focus-none`} onChange={this.handleChange} />

                            <input defaultValue={this.state.firstName} value={this.state.firstName} name='firstName' placeholder={'First Name'} className={`${Style.inputStyle} focus-none mt-2`} onChange={this.handleChange} />

                            <input defaultValue={this.state.lastName} value={this.state.lastName} name='lastName' placeholder={'Last Name'} className={`${Style.inputStyle} focus-none mt-2`} onChange={this.handleChange} />
                            <input defaultValue={this.state.orgCode} value={this.state.orgCode} name='orgCode' placeholder={'Organization Code'} className={`${Style.inputStyle} focus-none mt-2`} onChange={this.handleChange} />
                            <div class={`${Style.note}`}>Organisation Code cannot contain {"(/'%^$!?><&;)"}.</div>

                        </div>
                        <div className={`${Style.border} `} /> 
                        <div className={`py-4`}>
                            <button onClick={() => this.handleUpdate(this.state.id)} className={`${Style.submitButton} focus-none`}>Update Organization</button>
                            <button onClick={this.handleClick} className={`${Style.cancelButton} ml-3 focus-none`}>Cancel</button>
                        </div>
                    </div>
                    <div className={`${Style.containerStyle} px-4 ml-4 w-50`}>
                        <div className={`${Style.textStyle} pt-4`}>
                            Delete Organization
                        </div>
                        <div className={`mt-4`}>
                            <div className={`${Style.boldTextStyle}`}>
                                Are you sure you want to delete this organization?
                            </div>
                            <div className={`${Style.normalTextStyle} mt-1`}>
                                All admins will lose access to the Threat Feed.
                            </div>
                        </div>
                        <div className={`mt-5`}>
                            <div className={`${Style.normalTextStyle}`}>
                                For help contact us at 
                            </div>
                            <div className={`${Style.emailTextStyle} mt-1`}>
                                support@pixm.net
                            </div>
                        </div>
                        <div style={{marginTop:'122px'}} className={`${Style.border} `} /> 
                        <div className={`py-4`}>
                            <button disabled={ this.state.isActive ? false : true } onClick={() => { this.setState({ showConfirmBox: true })}} className={`${Style.cancelAccountButton} focus-none`}>Delete Organization</button>
                        </div>
                    </div>
                </div>
                {this.props.statusCode && <ModalPopup handleClick={this.handleModalClick} statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
            </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    customerFlag: state.entities.customers.createCustomerFlag,
    userToken: state.entities.user.token,
    refreshToken: state.entities.user.refreshToken,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode
})

const mapDispatchToProps = (dispatch) => ({
    deleteCustomer: (customerId) => dispatch(deleteCustomer(customerId)),
    updateSelectedCustomer: (customerId, body) => dispatch(updateSelectedCustomer(customerId, body)),
    setNewToken: () => dispatch(setNewToken()),
    setError: (error) => dispatch(setError(error))
  })
  
  export default connect(mapStateToProps, mapDispatchToProps)(UpdateOrganization)