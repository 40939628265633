import React, { useState, useEffect } from 'react'
import { Card, Col, ProgressBar, Row } from 'react-bootstrap'
import Flex from '../common/Flex/Flex'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircle } from '@fortawesome/free-solid-svg-icons'
import * as echarts from 'echarts/core'
import { PieChart } from 'echarts/charts'
import {
  GridComponent,
  TooltipComponent,
  TitleComponent,
} from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import { getColor } from '../../helpers/utils'
import BasicECharts from './BasicEChart'
import { FiRefreshCcw } from 'react-icons/fi'

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  PieChart,
  CanvasRenderer,
])

const DataWithRoundChart = (props) => {
  const [showLoader, setShowLoader] = useState(true)
  const [dataArray, setDataArray] = useState([])
  let id = 1
  const [total, setTotal] = useState(0)
  const colors = [
    'primary',
    'secondary',
    'success',
    'info',
    'warning',
    '1100',
    'danger',
    '800',
    'light',
    '600',
    'dark',
    '200',
  ]

  useEffect(() => {
    if (dataArray.length !== 0) {
      setDataArray([])
      id = 1
    }
    if (props.data) {
      let _dataArray = []
      props.data.legend.forEach((item, index) => {
        _dataArray.push({
          id: id,
          value: props.data.values.count[index],
          name: item,
          color: colors[id - 1],
        })
        id = id + 1
      })
      setDataArray([..._dataArray])
      setTotal(_dataArray.reduce((acc, val) => val.value + acc, 0))
    }
    setShowLoader(props.loading)
  }, [props])

  const getOptions = (data, radius) => ({
    color: [
      getColor('primary'),
      getColor('secondary'),
      getColor('success'),
      getColor('info'),
      getColor('warning'),
      getColor('danger'),
      getColor('light'),
      getColor('dark'),
    ],

    tooltip: {
      padding: [7, 10],
      transitionDuration: 0,
      backgroundColor: getColor('100'),
      borderColor: getColor('300'),
      textStyle: { color: getColor('dark') },
      borderWidth: 1,
      formatter: (params) =>
        `<strong>${params.data.name}:</strong> ${params.percent}%`,
    },
    series: [
      {
        name: '',
        type: 'pie',
        radius,
        avoidLabelOverlap: false,
        emphasis: {
          scale: false,
        },
        itemStyle: {
          borderWidth: 0.2,
          borderColor: getColor('card-bg'),
        },
        label: {
          show: true,
          position: 'center',
          formatter: '{a}',
          fontSize: 23,
          color: getColor('dark'),
        },
        data,
      },
    ],
  })

  return (
    <Card className="h-md-100">
      <Card.Body>
        <Row className="justify-content-between g-0">
          <div className="d-flex">
            <h6 className="flex-1">{props.heading}</h6>
            <FiRefreshCcw
              style={{ cursor: 'pointer' }}
              onClick={props.onRefresh}
            />
          </div>
          {props.errorNote && props.errorNote.length > 0 ? (
            <p>{props.errorNote}</p>
          ) : (
            <>
              {!showLoader ? (
                <>
                  <Col xs={5} sm={6} xxl="true" className="pe-2">
                    {dataArray.map((item, index) => (
                      <Flex
                        key={index}
                        alignItems="center"
                        justifyContent="between"
                        className={`fw-semi-bold fs--2 ${index == 0 && 'mt-3'}`}
                      >
                        <p className="mb-1">
                          <FontAwesomeIcon
                            icon={faCircle}
                            className={`me-2 text-${item.color || 'text-info'}`}
                          />
                          {item.name}
                        </p>
                        <div className="d-xxl-none">
                          {((item.value * 100) / total).toFixed(0)}%
                        </div>
                      </Flex>
                    ))}
                  </Col>
                  <Col xs="auto">
                    <div className="ps-0">
                      <BasicECharts
                        echarts={echarts}
                        options={getOptions(dataArray, 50)}
                        style={{ width: '6.625rem', height: '6.625rem' }}
                      />
                    </div>
                  </Col>
                </>
              ) : (
                <ProgressBar animated={true} now={100} />
              )}
            </>
          )}
        </Row>
      </Card.Body>
    </Card>
  )
}

export default DataWithRoundChart
