import React, { Component } from 'react'
import { connect } from 'react-redux'
import { produce } from 'immer'
import { loadChartEvents, loadEvents, loadLoginPagesScannedEvents, setThreatFiltersInStore, loadThreatsDetected, loadTopThreats, loadTopVulnerable, setChartFilterInStore } from '../../../store/entities/events'
import { isGlobalView } from '../../../utilities/config'

import DownArrow from '../../../assets/Arrow_down_outline.svg'
import UpArrow from '../../../assets/Arrow_up_ouline.svg'
import SolidUp from '../../../assets/Arrow_up_solid.svg'
import SolidDown from '../../../assets/Arrow_down_solid.svg'
import SolidBox from '../../../assets/Checkmark_solid.svg'
import UncheckBox from '../../../assets/uncheckMark.svg'
import SettingsIcon from '../../../assets/filter-settings.svg'

import './filterPopover.scss'
import { eventTypes } from '../../../utilities/eventTypes'
import { eventsInitialState } from '../../../store/initialState/eventsState'

class FilterPopover extends Component {
  state = {
    contentClass: ['filter-popover-content'],
    shouldShow: true,
    threatFilters: this.props.threatFilters,
    chartFilters: this.props.chartFilters
  }

  filterBoxPosition = 0

  constructor(props) {
    super(props)
    this.updateWrapperRef = React.createRef()
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedCustomer !== prevProps.selectedCustomer) {
      this.initailizeFilters()
      this.initiateChartFilters()
    }
    this.filterBoxPosition = document.querySelector('#filterBox').getBoundingClientRect().left
  }

  initailizeFilters = () => {
    this.removeFilter('removeRepeateUrl','threatFilters')
    this.removeFilter('removeThreatUrl','threatFilters')
    this.removeFilter('includeFalsePositive','threatFilters')
    let objFilter = {}
    objFilter['offset'] = 0
    objFilter['pageSize'] = 21
    objFilter['type'] = [eventTypes.incident , eventTypes.reported_phish, eventTypes.google_form_phish]
    this.state.threatFilters = eventsInitialState.threat.filters
    this.setFilters(objFilter,'threatFilters')
  }
  
  initiateChartFilters = () => {
    this.removeFilter('removeRepeateUrl','chartFilters')
    this.removeFilter('removeThreatUrl','chartFilters')
    this.removeFilter('includeFalsePositive','chartFilters')
    let objFilter = {}
    objFilter['offset'] = 0
    objFilter['type'] = [eventTypes.incident, eventTypes.google_form_phish]
    this.state.chartFilters = eventsInitialState.chart.filters
    this.setFilters(objFilter,'chartFilters')
  }

  removeFilter = (key, filterType) => {
    if (filterType === 'threatFilters') {
      this.state.threatFilters = produce(this.state.threatFilters, (draftState) => {
        delete draftState[key]
      })
      this.props.setThreatFiltersInStore(this.state.threatFilters)
    } else {
      this.state.chartFilters = produce(this.state.chartFilters, (draftState) => {
        delete draftState[key]
      })
      this.props.setChartFilterInStore(this.state.chartFilters)
    }
  }

  setFilters = (changedValues, filterType) => {
    if (filterType === 'threatFilters') {
      this.state.threatFilters = produce(this.state.threatFilters, (draftState) => {
        Object.keys(changedValues).map((property) => {
          draftState[property] = changedValues[property]
        })
      })
      this.props.setThreatFiltersInStore(this.state.threatFilters)
    } else {
      this.state.chartFilters = produce(this.state.chartFilters, (draftState) => {
        Object.keys(changedValues).map((property) => {
          draftState[property] = changedValues[property]
        })
      })
      this.props.setChartFilterInStore(this.state.chartFilters)
    }
  }

  handleClick = () => {
    const { contentClass } = this.state
    contentClass.indexOf('filter-popover-content-after') === -1
      ? contentClass.push('filter-popover-content-after')
      : contentClass.pop()
    this.setState({ contentClass })
  }

  loadLoginData = () => {
    let objFilter = {}
    objFilter['startTime'] =  this.state.chartFilters.startTime
    objFilter['endTime'] =  this.state.chartFilters.endTime
    objFilter['requireTotalRecordsCount'] = 1
    objFilter['numberOfBuckets'] = 9
    objFilter['r'] = isGlobalView(this.props.selectedCustomer.organizationName)
      ? 'M'
      : 'C'
    objFilter['cKey'] = this.props.selectedCustomer.key;
    this.setFilters(objFilter,'chartFilters')
    this.props.loadLoginPagesScannedEvents(
      objFilter,
      this.props.selectedCustomer.id,
      this.props.selectedCustomer.defaultCustomerId
    )
    this.removeFilter('r','chartFilters')
  }

  loadThreatDetected = (triagedEvent) => {
    let objFilter = {}
    this.removeFilter('removeThreatUrl','chartFilters')
    this.removeFilter('requireTotalRecordsCount','chartFilters')
    this.removeFilter('includeFalsePositive','chartFilters')
    objFilter['type'] = [eventTypes.incident, eventTypes.google_form_phish]
    if (triagedEvent !== null) {
      objFilter['triagedEvent'] = triagedEvent
    }
    objFilter['chartType'] = 'threatDetected';
    objFilter['numberOfBuckets'] = 9;
    objFilter['r'] = isGlobalView(this.props.selectedCustomer.organizationName)
    ? 'M'
    : 'C'
    objFilter['offset'] = 0;
    objFilter['cKey'] = this.props.selectedCustomer.key;
    this.setFilters(objFilter,'chartFilters')
    this.props.loadThreatsDetected(
      this.state.chartFilters,
      this.props.selectedCustomer.id,
      this.props.selectedCustomer.defaultCustomerId,
      isGlobalView(this.props.selectedCustomer.organizationName),
    )
    this.removeFilter('numberOfBuckets','chartFilters')
    this.removeFilter('chartType','chartFilters')
  }

  loadTopThreats = (triagedEvent) => {
    let objFilter = {}
    this.removeFilter('removeThreatUrl','chartFilters')
    this.removeFilter('requireTotalRecordsCount','chartFilters')
    this.removeFilter('includeFalsePositive','chartFilters')
    objFilter['type'] = [eventTypes.incident, eventTypes.google_form_phish]
    objFilter['chartType'] = 'topthreats';
    if (triagedEvent !== null) {
      objFilter['triagedEvent'] = triagedEvent
    }
    objFilter['r'] = isGlobalView(this.props.selectedCustomer.organizationName)
      ? 'M'
      : 'C'
    objFilter['offset'] = 0;
    objFilter['fetchOnlyRecords'] = 13;
    objFilter['cKey'] = this.props.selectedCustomer.key;
    this.setFilters(objFilter,'chartFilters')
    this.props.loadTopThreats(
      this.state.chartFilters,
      this.props.selectedCustomer.id,
      this.props.selectedCustomer.defaultCustomerId,
      isGlobalView(this.props.selectedCustomer.organizationName),
    )
    this.removeFilter('chartType','chartFilters')
  }

  loadTopVulnerable = (triagedEvent) => {
    let objFilter = {}
    this.removeFilter('removeThreatUrl','chartFilters')
    this.removeFilter('requireTotalRecordsCount','chartFilters')
    this.removeFilter('includeFalsePositive','chartFilters')
    objFilter['type'] = [eventTypes.incident, eventTypes.google_form_phish]
    objFilter['chartType'] = 'topvulnerable';
    if (triagedEvent !== null) {
      objFilter['triagedEvent'] = triagedEvent
    }
    objFilter['r'] = isGlobalView(this.props.selectedCustomer.organizationName)
      ? 'M'
      : 'C'
    objFilter['offset'] = 0;
    objFilter['fetchOnlyRecords'] = 13;
    objFilter['cKey'] = this.props.selectedCustomer.key;
    this.setFilters(objFilter,'chartFilters')
    this.props.loadTopVulnerable(
      this.state.chartFilters,
      this.props.selectedCustomer.id,
      isGlobalView(this.props.selectedCustomer.organizationName),
    )
    this.removeFilter('chartType','chartFilters')
  }
  areArraysEqual = (array1, array2)  => {
    if (array1 && array2) {
      if (array1.length !== array2.length) {
        return false; // If the lengths are different, the arrays are not equal
      }
      // Check if every element in array1 is present in array2
      return array1.every((value) => array2.includes(value));
    } else {
      this.initailizeFilters()
      this.initiateChartFilters()
      return false
    }
  }

  handleFilterClick = (filterType) => {
    let { selectedCustomer, viewType } = this.props;
    let threatFilters = this.state.threatFilters;
    let chartFilters = this.state.chartFilters;
    let objFilter = {};
    objFilter['offset'] = 0;
    objFilter['order'] = ['asc', 'desc'].includes(filterType) ? filterType : 'desc';
    
    if (viewType !== 'chart') {
      if(filterType === 'removeRepeateUrl') {
        threatFilters.hasOwnProperty('removeRepeateUrl') ? this.removeFilter('removeRepeateUrl','threatFilters') : (objFilter['removeRepeateUrl'] = true);
      }
      if(filterType === 'removeThreatUrl') {
        threatFilters.hasOwnProperty('removeThreatUrl') ? this.removeFilter('removeThreatUrl','threatFilters') : (objFilter['removeThreatUrl'] = true);
      }
      if (filterType === 'showUnknownUrl') {
        objFilter['type'] = this.areArraysEqual(threatFilters.type , [eventTypes.unknown]) ? [eventTypes.incident , eventTypes.reported_phish, eventTypes.google_form_phish] : [eventTypes.unknown]; 
      }
      if (filterType === 'includeFalsePositive') {
        threatFilters.hasOwnProperty('includeFalsePositive') ? this.removeFilter('includeFalsePositive','threatFilters') : (objFilter['includeFalsePositive'] = 1);
      }
      this.setFilters(objFilter, 'threatFilters');
      this.props.loadEvents(this.state.threatFilters, selectedCustomer.id, selectedCustomer.defaultCustomerId, isGlobalView(selectedCustomer.organizationName));
    } else {
      objFilter['requireTotalRecordsCount'] = 1;
      if(filterType === 'removeRepeateUrl') {
        chartFilters.hasOwnProperty('removeRepeateUrl') ? this.removeFilter('removeRepeateUrl','chartFilters') : (objFilter['removeRepeateUrl'] = true);
      }
      if(filterType === 'removeThreatUrl') {
        chartFilters.hasOwnProperty('removeThreatUrl') ? this.removeFilter('removeThreatUrl','chartFilters') : (objFilter['removeThreatUrl'] = true);
      }
      if (filterType === 'showUnknownUrl') {
        objFilter['type'] = this.areArraysEqual(chartFilters.type , [eventTypes.unknown]) ? [eventTypes.incident , eventTypes.reported_phish, eventTypes.google_form_phish] : [eventTypes.unknown]; 
      }
      if (filterType === 'includeFalsePositive') {
        chartFilters.hasOwnProperty('includeFalsePositive') ? this.removeFilter('includeFalsePositive','chartFilters') : (objFilter['includeFalsePositive'] = 1);
      }
      this.setFilters(chartFilters,'chartFilters')
      let triagedEvent = null
      if (this.state.chartFilters.hasOwnProperty('triagedEvent')) {
        triagedEvent = this.state.chartFilters['triagedEvent']
      }
      this.loadLoginData()
      this.loadThreatDetected(triagedEvent);
      this.loadTopThreats(triagedEvent);
      this.loadTopVulnerable(triagedEvent);
    }
  }

  handleClickOutside = (event) => {
    let { contentClass } = this.state
    let contentClassIndex = contentClass.indexOf('filter-popover-remove')
    if (contentClassIndex === -1) {
      contentClass.push('filter-popover-remove')
    } else {
      contentClass.splice(contentClassIndex, 1)
    }
    this.setState({ contentClass })
  }

  render() {
    const role = this.props.tokenRoles;
    const filters = this.props.viewType === 'chart' ? this.props.chartFilters : this.props.threatFilters
    return (
      this.state.shouldShow && (
        <div id='filterBox' className="filter-popover-wrapper parent">
          <div
            ref={this.updateWrapperRef}
            onClick={this.handleClick}
            tabIndex="0"
            onBlur={(e) => this.handleClickOutside(e)}
          >
            <img
              src={SettingsIcon}
              alt=""
            />
            <div style={{left: this.filterBoxPosition>50 ? '-223px' : '-14px'}} className={this.state.contentClass.join(' ')}>
            <div className="popover-arrow" style={{right: this.filterBoxPosition>50 ? '20px' : '227px'}}></div>
                <div onClick={() => this.handleFilterClick('asc')} className="d-flex align-items-center filter-popover-item">
                  <img style={{width: '29px', marginLeft: '3px' }} src={filters.order === 'asc' ? SolidDown : DownArrow} alt="" srcSet="" />
                  <div className={filters.order === 'asc' ? 'bold-text' : ''}>Ascending</div>
                </div>
              
                <div onClick={() => this.handleFilterClick('desc')} className="d-flex align-items-center filter-popover-item">
                  <img style={{width: '29px', marginLeft: '3px', marginTop: '-1px' }} src={filters.order === 'desc' ? SolidUp : UpArrow} alt="" srcSet="" />
                  <div className={filters.order === 'desc' ? 'bold-text' : ''}>Decending</div>
                </div>

                <div onClick={() => this.handleFilterClick('removeRepeateUrl')} className="d-flex align-items-center filter-popover-item">
                  <img style={{marginTop:'-2px'}} src={filters.hasOwnProperty('removeRepeateUrl') && filters.removeRepeateUrl ? SolidBox : UncheckBox } alt="" srcSet="" />
                  <div className={filters.hasOwnProperty('removeRepeateUrl') && filters.removeRepeateUrl ? 'bold-text' : ''}>Remove repeat URLs</div>
                </div>

                <div onClick={() => this.handleFilterClick('removeThreatUrl')} className="d-flex align-items-center filter-popover-item">
                  <img style={{marginTop:'-2px'}} src={filters.hasOwnProperty('removeThreatUrl') && filters.removeThreatUrl ? SolidBox : UncheckBox } alt="" srcSet="" />
                  <div className={filters.hasOwnProperty('removeThreatUrl') && filters.removeThreatUrl ? 'bold-text' : ''}>Remove threat URLs</div>
                </div>
                {/*role === 'PixmAdmin' ?
                  <div onClick={() => this.handleFilterClick('showUnknownUrl')} className="d-flex align-items-center filter-popover-item">
                    <img style={{marginTop:'-2px'}} src={this.areArraysEqual(filters.type , [eventTypes.unknown]) ? SolidBox : UncheckBox} alt="" srcSet="" />
                    <div className={filters.type === 2 ? 'bold-text' : ''}>Show 'Unknown' URLs</div>
                  </div>
                  : null*/} 
                <div onClick={() => this.handleFilterClick('includeFalsePositive')} className="d-flex align-items-center filter-popover-item">
                <img style={{marginTop:'-2px'}} src={filters.hasOwnProperty('includeFalsePositive') && filters.includeFalsePositive ? SolidBox : UncheckBox } alt="" srcSet="" />
                  <div className={filters.hasOwnProperty('includeFalsePositive') && filters.includeFalsePositive ? 'bold-text' : ''}>Include False Positive URLs</div>
                </div>
            </div>
          </div>
        </div>
      )
    )
  }
}

const mapStateToProps = (state) => ({
  tokenRoles: state.entities.user.roles,
  selectedCustomer: state.entities.customers.selected,
  threatFilters: state.entities.events.threat.filters,
  chartFilters: state.entities.events.chart.filters,
  orgList: state.entities.customers.list,
  viewType: state.entities.events.viewType,
})

const mapDispatchToProps = (dispatch) => ({
  setThreatFiltersInStore: (filters)=> dispatch(setThreatFiltersInStore(filters)),
  loadEvents: (filters, selectedId, defaultCustomerId, isGlobalView) => dispatch(loadEvents(filters, selectedId, defaultCustomerId, isGlobalView)),
  loadLoginPagesScannedEvents: (offset, selectedId, defaultCustomerId) =>
    dispatch(loadLoginPagesScannedEvents(offset, selectedId, defaultCustomerId)),
  loadThreatsDetected: (filters, selectedId, defaultCustomerId, isGlobalView) =>
    dispatch(loadThreatsDetected(filters, selectedId, defaultCustomerId, isGlobalView)),
  loadTopThreats : (filters ,selectedId, defaultCustomerId, isGlobalView) =>
    dispatch(loadTopThreats(filters, selectedId, defaultCustomerId, isGlobalView)),
  loadTopVulnerable : (filters, selectedId, defaultCustomerId, isGlobalView) => 
    dispatch(loadTopVulnerable(filters, selectedId, defaultCustomerId, isGlobalView)),
  setChartFilterInStore: (filters) => dispatch(setChartFilterInStore(filters)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterPopover)
