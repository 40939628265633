import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ModalPopup from '../components/common/ModalPopup';
import { fetchApiKeyDetailsByCustomerId, generateNewApiKeyForCustomer, fetchApiKeyDetailsByTenantId, generateNewApiKeyForTenant } from '../store/entities/customers';
import { globalViewText } from '../utilities/config'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper';
import Style from './SocIntegration.module.css';
import socApisCA from '../components/data/socApisCA.json'
import socApisTA from '../components/data/socApisTA.json'
import SocDetails from './SocDetails';

class SocIntegration extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showCustomerApiKey: false,
            showTenantApiKey: false,
            socApis: this.props.tokenRoles === "TenantAdmin"? socApisTA : socApisCA,
            selectedApi: this.props.tokenRoles === "TenantAdmin"? socApisTA[0].tabName : socApisCA[0].tabName
        };
    }

    componentDidMount = () => {
        if (this.props.selectedCustomer) {
            this.props.fetchApiKeyDetailsByCustomerId(this.props.selectedCustomer.id);
            if (this.props.tokenRoles === "TenantAdmin" || this.props.tokenRoles === "PixmAdmin")
                this.props.fetchApiKeyDetailsByTenantId(this.props.selectedCustomer.tenantId);
            this.setjson();
        } 
    }

    componentDidUpdate = (prevProps) => {
        if (this.props.selectedCustomer !== prevProps.selectedCustomer) {
            this.props.fetchApiKeyDetailsByCustomerId(this.props.selectedCustomer.id);
            if (this.props.tokenRoles === "TenantAdmin" || this.props.tokenRoles === "PixmAdmin")
                this.props.fetchApiKeyDetailsByTenantId(this.props.selectedCustomer.tenantId);
            this.setjson();
        }
    }

    generateNewCustomerApiKey = () => {
        if (this.props.selectedCustomer) {
            this.props.generateNewApiKeyForCustomer(this.props.selectedCustomer.id)
        }
    }

    generateNewTenantApiKey = () => {
        if (this.props.selectedCustomer) {
            this.props.generateNewApiKeyForTenant(this.props.selectedCustomer.tenantId)
        }
    }

    copyToClipboard = () => {
        const { priorDateString } = this.fetchDateDetails();
        let textToCopy = `curl -X GET -H 'apikey: <!--insert api key here-->' '${process.env.REACT_APP_API_BASE_URL}/enterprise-soc/fetch-incidents?order=asc&startdate=${priorDateString}&pagesize=20&removerepeaturl=true'`

        navigator.clipboard.writeText(textToCopy);
    }

    fetchExpiryDateDetails = () => {
        const { apiKeyExpiryDate, tenantApiKeyExpiryDate } = this.props;
        var expiryDate = apiKeyExpiryDate ? new Date(apiKeyExpiryDate) : null;
        var expiryDateString = expiryDate ? expiryDate.toISOString().split('T')[0] : null;
        var tenantApiExpiry = tenantApiKeyExpiryDate ? new Date(tenantApiKeyExpiryDate) : null;
        var tenantApiExpiryDateString = tenantApiExpiry ? tenantApiExpiry.toISOString().split('T')[0] : null;

        return { expiryDateString, tenantApiExpiryDateString };
    }

    handleClick = (tab) => {
        this.setState({
          selectedApi: tab,
        })
    }

    fetchCurrentDateDetails = () =>{
        var priorDate = new Date();
        priorDate.setDate(priorDate.getDate() - 30);
        var priorDateString = priorDate.toISOString().split('T')[0];
        var today = new Date();
        var todayString = today.toISOString().split('T')[0];
        return { priorDateString, todayString };
    }

    setjson = () =>{
        let jsonString = JSON.stringify(this.state.socApis);
        let { priorDateString, todayString } = this.fetchCurrentDateDetails();
        // console.log("priorDateString:", priorDateString)
        const baseUrl = process.env.REACT_APP_API_BASE_URL;

        // Replace certain values
        jsonString = jsonString.replaceAll('{priorDateString}', priorDateString); 
        jsonString = jsonString.replaceAll('{todayString}', todayString); 
        jsonString = jsonString.replaceAll('{baseUrl}', baseUrl); 


        // Convert back to JSON object
        const modifiedJSON = JSON.parse(jsonString);
        this.setState({
            socApis: modifiedJSON,
          })
    }


    render() {
        if (!this.props.selectedCustomer)
            return null;

        const { showCustomerApiKey, showTenantApiKey } = this.state;
        const { apiKey, tenantApiKey } = this.props
        const { expiryDateString, tenantApiExpiryDateString } = this.fetchExpiryDateDetails();
        const isTA = this.props.tokenRoles === "TenantAdmin";
        const isPA = this.props.tokenRoles === "PixmAdmin";
        const isGlobalView = this.props.selectedCustomer.organizationName === globalViewText

        return (
            <div  className={`Container`}
              style={{
                padding: '0 1.8% 0 2.4%',
                margin: '0 0 0 43px',
              }}>
                <div className={`mt-4`}>
                    <div className={`${Style.titleStyle}`}>
                        SOC Integration
                    </div>
                    {!isGlobalView ?
                        <LoaderWrapper loading={this.props.loading} waitForData={false} styles={{minHeight: '100px'}} isFlex={true}>
                            <>
                            <div className={`${Style.headContainer} mt-4 px-5 d-flex align-items-center flex-grow-1`} style={{ justifyContent: 'space-between' }}>
                                {apiKey ?
                                    <>
                                        <div className={`px-4`} style={{ whiteSpace: 'nowrap' }}>
                                            <div className={`${Style.fontBold}`}>
                                                Current API key.
                                            </div>
                                            <div
                                                className={`${Style.fontBold} ${Style.cursorPointer}`}
                                                onClick={() => { this.setState({ showCustomerApiKey: true }) }}
                                            >
                                                {showCustomerApiKey ? apiKey : 'Click to show API key.'}
                                            </div>
                                            <div className={`${Style.textStyle}`}>The API key is valid till <b>{expiryDateString}</b></div>
                                        </div>
                                    </>
                                    :
                                    <>
                                        <div className={`px-4`}>
                                            <div className={`${Style.fontBold}`}>
                                                Click on the 'Generate' button to create and assign new API Key.
                                            </div>
                                        </div>
                                    </>
                                }
                                <div>
                                    <div className={'d-flex'}>
                                        <button className={`${Style.submitButton} focus-none px-4`} onClick={() => this.generateNewCustomerApiKey()}>Generate new API Key</button>
                                    </div>
                                </div>
                            </div>
                            </>
                        </LoaderWrapper>
                    : null}
                    {isTA ?
                        <LoaderWrapper loading={this.props.loading} waitForData={false} styles={{minHeight: '100px'}} isFlex={true}>
                            <>
                                <div className={`${Style.headContainer} mt-4 px-5 d-flex align-items-center flex-grow-1`} style={{ justifyContent: 'space-between' }}>
                                    {tenantApiKey ?
                                        <>
                                            <div className={`px-4`} style={{ whiteSpace: 'nowrap' }}>
                                                <div className={`${Style.fontBold}`}>
                                                    Current Global API key.
                                                </div>
                                                <div>
                                                    <div
                                                        className={`${Style.fontBold} ${Style.cursorPointer}`}
                                                        onClick={() => { this.setState({ showTenantApiKey: true }) }}

                                                    >
                                                        {showTenantApiKey ? tenantApiKey : 'Click to show Global API key.'}
                                                        <div>
                                                        </div>
                                                    </div>
                                                    <div className={`${Style.textStyle}`}>The API key is valid till <b>{tenantApiExpiryDateString}</b></div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className={`px-4`}>
                                                <div className={`${Style.fontBold}`}>
                                                    Click on the 'Generate' button to create and assign new Global API Key.
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className={'d-flex'}>
                                        <button className={`${Style.submitButton} focus-none px-4`} onClick={() => this.generateNewTenantApiKey()}>Generate new Global API Key</button>
                                    </div>
                                </div>
                            </>
                        </LoaderWrapper>
                    : null}
                    {isPA && isGlobalView ?
                        <LoaderWrapper loading={this.props.loading} waitForData={false} styles={{minHeight: '100px'}} isFlex={true}>
                            <>
                                <div className={`${Style.headContainer} mt-4 px-5 d-flex align-items-center flex-grow-1`} style={{ justifyContent: 'space-between' }}>
                                    {tenantApiKey ?
                                        <>
                                            <div className={`px-4`} style={{ whiteSpace: 'nowrap' }}>
                                                <div className={`${Style.fontBold}`}>
                                                    Current Global API key.
                                                </div>
                                                <div>
                                                    <div
                                                        className={`${Style.fontBold} ${Style.cursorPointer}`}
                                                        onClick={() => { this.setState({ showTenantApiKey: true }) }}

                                                    >
                                                        {showTenantApiKey ? tenantApiKey : 'Click to show Global API key.'}
                                                        <div>
                                                        </div>
                                                    </div>
                                                    <div className={`${Style.textStyle}`}>The API key is valid till <b>{tenantApiExpiryDateString}</b></div>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className={`px-4`}>
                                                <div className={`${Style.fontBold}`}>
                                                    Click on the 'Generate' button to create and assign new Global API Key.
                                                </div>
                                            </div>
                                        </>
                                    }
                                    <div className={'d-flex'}>
                                        <button className={`${Style.submitButton} focus-none px-4`} onClick={() => this.generateNewTenantApiKey()}>Generate new Global API Key</button>
                                    </div>
                                </div>
                            </>
                        </LoaderWrapper>
                    : null}
                </div>

                <div className={`${Style.subInfoContainerStyle} ${Style.textStyle} p-4 mt-4 w-100 mr-3`}>
                    The above API key is your organization's private API key and should not be shared with anyone outside your organization. 
                    <br/>Your API key allows you to fetch your incident data in JSON format using the SOC API.
                    <br/>{isTA ? ' A global API key fetches the incident data of all customers.' : ''} The response can be filtered using optional query string parameters. The format and the default values of the parameters are listed below.
                </div>

                <div>
                    <div className={`${Style.navbar} ${Style.boxShadow} pl-4 d-flex mt-4 mb-2`}>
                        {this.state.socApis.map((socApi, index)=>
                        <div 
                            key={socApi.tabId}
                            onClick={() => this.handleClick(socApi.tabName)}
                            className={`${
                            this.state.selectedApi === socApi.tabName
                                ? `${Style.navbarSelectedItemStyle} d-flex align-items-center`
                                : `${Style.navbarFontStyle} d-flex align-items-center`
                            }`}
                            style={{ marginRight: '20px', fontSize: '14px' }}
                        >
                            {socApi.tabName}
                        </div>)
                        }
                    </div>
                </div>
                
                <SocDetails apiData={this.state.socApis.filter((socApi)=>socApi.tabName === this.state.selectedApi)[0]}/>

                {this.props.statusCode && <ModalPopup statusCode={this.props.statusCode} msg={this.props.userMsg} />}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    loading: state.entities.customers.loading,
    apiKey: state.entities.customers.apiKey,
    apiKeyExpiryDate: state.entities.customers.apiKeyExpiryDate,
    tenantApiKey: state.entities.customers.tenantApiKey,
    tenantApiKeyExpiryDate: state.entities.customers.tenantApiKeyExpiryDate,
    selectedCustomer: state.entities.customers.selected,
    tokenRoles: state.entities.user.roles
})

const mapDispatchToProps = (dispatch) => ({
    fetchApiKeyDetailsByCustomerId: (customerId) => dispatch(fetchApiKeyDetailsByCustomerId(customerId)),
    generateNewApiKeyForCustomer: (customerId) => dispatch(generateNewApiKeyForCustomer(customerId)),
    fetchApiKeyDetailsByTenantId: (tenantId) => dispatch(fetchApiKeyDetailsByTenantId(tenantId)),
    generateNewApiKeyForTenant: (tenantId) => dispatch(generateNewApiKeyForTenant(tenantId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SocIntegration))