import React, { Component } from 'react'
import pixmLogo from '../assets/pixm-blue-on-white-logo-02@3x.png'
import Step1 from '../assets/step1.png'
import Step2 from '../assets/step2.png'
import Step3 from '../assets/step3.png'
import Step4 from '../assets/step4.png'
import Step5 from '../assets/step5.png'
import Step6 from '../assets/step6.png'
import Step7 from '../assets/step7.png'
import Step8 from '../assets/step8.png'
import Step9 from '../assets/step9.png'
import Step10 from '../assets/step10.png'
import Step11 from '../assets/step11.png'
import { Button, Card, Col, Row, Table } from 'react-bootstrap'
import Style from './deployment.module.css'
import NavStyle from './navbarStyles.module.css'

class DeploymentManual extends Component {
  copyToClipboard = () => {
    let textToCopy = `https://pixm.net/instruction.pdf`
    navigator.clipboard.writeText(textToCopy)
  }
  render() {
    return (
      <>
        <>
          <div>
            <div className={`mt-4`}>
          <div className={`${Style.titleStyle}`}>Manual Deployment</div>
          </div>
          <p className={`${Style.textStyle} w-50 w-sm-100 mb-0`}>
            End users can manually install and register Pixm on any browser they wish from the Pixm website using a valid organization email addresses.
            <br/> You can share instructions here: <a target="_blank" href='https://pixm.net/manual-registration'> https://pixm.net/manual-registration</a>.        
          </p>
          <div className={`mt-4`}>
            {/* <div className={`${Style.titleStyle}`}>
              PIXM Setup Instructions
            </div> */}
          </div>
            <div className="d-flex justify-content-between align-items-start w-100">
              <Card className={`shadow rounded-lg ${Style.instructionsCard}`}>
                <Card.Body>
                  <Row className="mb-3">
                    <Col>
                      <p
                        className={`${Style.subTitleStyle} d-flex justify-content-start align-items-center my-3`}
                      >
                        Set up PIXM in less than 60 seconds
                      </p>
                    </Col>
                    <Col className="d-flex justify-content-end">
                      <img width="120px" height="40px" src={pixmLogo} />
                    </Col>
                  </Row>
                  <Table responsive>
                    <tbody>
                      <tr>
                        <td>Go to https://pixm.net</td>
                        <td>
                          <img src={Step1} />
                        </td>
                      </tr>
                      <tr>
                        <td>Click the blue “Protect”-button</td>
                        <td>
                          <img src={Step2} />
                        </td>
                      </tr>
                      <tr>
                        <td>Click the blue “Add”-button in the web store</td>
                        <td>
                          <img src={Step3} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Check to see the blue PIXM icon to the right of the
                          search bar
                        </td>
                        <td>
                          <img src={Step4} />
                        </td>
                      </tr>
                      <tr>
                        <td>
                          If the blue icon does not appear, click the puzzle
                          icon
                        </td>
                        <td>
                          <img src={Step5} />
                        </td>
                      </tr>
                      <tr>
                        <td>Navigate to “PIXM Phishing Protection”</td>
                        <td>
                          <img src={Step6} />
                        </td>
                      </tr>
                      <tr>
                        <td>Click the pin icon to the right</td>
                        <td>
                          <img src={Step7} />
                        </td>
                      </tr>
                      <tr>
                        <td>Click the blue PIXM icon</td>
                        <td>
                          <img src={Step8} />
                        </td>
                      </tr>
                      <tr>
                        <td>Click "Register with my organization"</td>
                        <td>
                          <img src={Step9} />
                        </td>
                      </tr>
                      <tr>
                        <td>Enter organization email address</td>
                        <td>
                          <img src={Step10} />
                        </td>
                      </tr>
                      <tr>
                        <td>Verify email address</td>
                        <td>
                          <img width="370px" src={Step11} />
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </Card.Body>
              </Card>
              {/* <Card className={`shadow rounded-lg ${Style.downloadManualCard}`}>
                <div className={`${NavStyle.subNavbar} d-flex`}>
                  <div
                    // onClick={() => this.handleSelectNavItem('PDF')}
                    className={`${NavStyle.navbarFontStyle} ${NavStyle.navbarSelectedItemStyle} d-flex align-items-center ml-0`}
                  >
                    {'PDF'}
                  </div>

                  <div
                    // onClick={(e) => this.handleSelectNavItem('HTML')}
                    className={`${NavStyle.navbarFontStyle} d-flex align-items-center ml-5`}
                  >
                    {'HTML'}
                  </div>

                  <div
                    // onClick={(e) => this.handleSelectNavItem('JPEG')}
                    className={`${NavStyle.navbarFontStyle} d-flex align-items-center ml-5`}
                  >
                    {'JPEG'}
                  </div>
                </div>
                <div
                  style={{
                    backgroundColor: '#edebe4',
                    cursor: 'pointer',
                  }}
                  className={`py-3 px-3 mb-3 rounded-lg`}
                >
                  <pre className={`${Style.textStyle}`}>
                    <a
                      style={{ textDecoration: 'none', color: 'black' }}
                      target="_blank"
                      href="https://pixm.net/manual-registration"
                    >
                      https://pixm.net/manual-registration
                    </a>
                  </pre>
                </div>
                <a
                  // href={msiUrl}
                  onClick={() => this.copyToClipboard()}
                  class={`btn btn-primary py-3 px-4 mx-auto d-flex align-center justify-content-center w-100 ${Style.downloadButton} ${Style.downloadButtonActive}`}
                >
                  Copy Share Link
                </a>
                <hr />
                <p className={`${Style.textStyle}`}>
                  If you don’t want to share the set up instructions manually
                  you can automatically share them amongt your users with PIXM.
                  Your users will then receive an <b>email version </b> of those
                  instructions.
                </p>
                <Button
                  variant="outline-secondary"
                  className={`${Style.downloadButton} w-100`}
                  disabled
                >
                  Send to all Users
                </Button>
              </Card> */}
            </div>
          </div>
        </>
      </>
    )
  }
}

export default DeploymentManual
