import React, { useEffect, useState } from 'react'
import classNames from 'classnames';
import './LoaderWrapper.scss'
import Loading from './loading'

function LoaderWrapper({ loading, styles, data, waitForData, children, placeholderText, isFlex, isChart }) {
  const [ hasInitialData, setHasInitialData ] = useState(false)
  const [ waitFor, setWaitForData ] = useState(waitForData)
  placeholderText = placeholderText || " No records found"

  useEffect(() => {
    setHasInitialData(hasData(data) && data)
  }, [data])

  const hasData = (data) => {
    if(Array.isArray(data)) {
      setWaitForData(true)
      return data && data.length > 0
    }

    if(typeof data === 'object') {
      setWaitForData(true)
      return data && Object.keys(data).length > 0
    }

    return !!data;
  }

  return (
    <div 
      className={classNames(
        'loader-wrapper',
        { 'isFlex': isFlex },
        { 'isChart': isChart },
        { 'hasData': hasInitialData }
      )}
      style={styles}>
      { 
        !loading ? 
          (!waitFor ? 
             <> {children} </>
            : (!hasInitialData ? 
                (<div className="loader-wrapper-placeholder">
                  <p>{placeholderText}</p>
                </div>)
                : <> {children} </>
              ) 
          ) 
        : <Loading loading={loading} styles={styles}  />
      }
   </div>
  )

}

export default LoaderWrapper
