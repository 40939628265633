import React, { Component } from 'react'
import Style from './navbarStyles.module.css'
import Profile from './Profile'
import Account from './Account'
import AdminManagement from './AdminManagement'
import AlertConfig from './AlertConfig'
import { connect } from 'react-redux'
import { setCurrentCustomer } from '../store/entities/customers'
import WhitelistDomain from './WhitelistDomain'
import BlacklistManagement from './BlacklistManagement'

class Settings extends Component {
  state = {
    selectedNavItem:
      this.props.userRole !== 'TenantAdmin' ? 'Profile' : 'Account',
    setCustomerOnce: true,
  }

  componentDidMount() {
    if (
      this.props.location.hasOwnProperty('state') &&
      this.props.location.state !== undefined
    ) {
      this.setState({
        selectedNavItem: this.props.location.state.tab,
      })
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props && this.state.setCustomerOnce) {
      this.setState({
        setCustomerOnce: false,
      })

      let filtredElement = this.props.orgList.filter(
        (ele) => ele.id === this.props.match.params.id,
      )

      if (
        this.props.selectedCustomer &&
        filtredElement[0] &&
        filtredElement[0].id !== this.props.selectedCustomer.id
      ) {
        this.props.setCurrentCustomer(filtredElement[0])
      }
    }
    if (prevProps.selectedCustomer !== this.props.selectedCustomer) {
      this.setState({
        selectedNavItem:
          this.props.userRole !== 'TenantAdmin' ? 'Profile' : 'Account',
      })
    }
  }

  handleSelectNavItem = (item) => {
    this.setState({
      selectedNavItem: item,
    })
  }

  renderTab = () => {
    let { selectedNavItem } = this.state
    switch (selectedNavItem) {
      case 'Profile':
        return <Profile />
      case 'Account':
        return <Account />
      case 'Admin Management':
        return <AdminManagement />
      case 'Alert Config':
        return <AlertConfig {...this.props} />
      case 'Whitelist Management':
        return <WhitelistDomain />
      case 'Blacklist Management':
        return <BlacklistManagement />
      // case 'Create Customer':
      //   return <CreateCustomer />

      default:
    }
  }

  render() {
    let { selectedNavItem } = this.state

    return (
      <div>
        <div className={`${Style.navbar} pl-4 d-flex`}>
          {this.props.userRole !== 'TenantAdmin' && (
            <div
              onClick={() => this.handleSelectNavItem('Profile')}
              className={
                `${Style.navbarFontStyle} ` +
                `${
                  selectedNavItem === 'Profile'
                    ? Style.navbarSelectedItemStyle
                    : ''
                } d-flex align-items-center ml-5`
              }
            >
              Profile
            </div>
          )}
          <div
            onClick={() => this.handleSelectNavItem('Account')}
            className={
              `${Style.navbarFontStyle} ` +
              `${
                selectedNavItem === 'Account'
                  ? Style.navbarSelectedItemStyle
                  : ''
              } d-flex align-items-center ml-5`
            }
          >
            Account
          </div>
          <div
            onClick={() => this.handleSelectNavItem('Admin Management')}
            className={
              `${Style.navbarFontStyle} ` +
              `${
                selectedNavItem === 'Admin Management'
                  ? Style.navbarSelectedItemStyle
                  : ''
              } d-flex align-items-center ml-5`
            }
          >
            Admin Management
          </div>
          <div
            onClick={() => this.handleSelectNavItem('Alert Config')}
            className={
              `${Style.navbarFontStyle} ` +
              `${
                selectedNavItem === 'Alert Config'
                  ? Style.navbarSelectedItemStyle
                  : ''
              } d-flex align-items-center ml-5`
            }
          >
            Config
          </div>
          <div
            onClick={() => this.handleSelectNavItem('Whitelist Management')}
            className={
              `${Style.navbarFontStyle} ` +
              `${
                selectedNavItem === 'Whitelist Management'
                  ? Style.navbarSelectedItemStyle
                  : ''
              } d-flex align-items-center ml-5`
            }
          >
            Allowed Domains
          </div>
          <div
            onClick={() => this.handleSelectNavItem('Blacklist Management')}
            className={
              `${Style.navbarFontStyle} ` +
              `${
                selectedNavItem === 'Blacklist Management'
                  ? Style.navbarSelectedItemStyle
                  : ''
              } d-flex align-items-center ml-5`
            }
          >
            Blocked URLs
          </div>
          {/* {this.props.userRole === 'PixmAdmin' && (
            <div
              onClick={() => this.handleSelectNavItem('Create Customer')}
              className={
                `${Style.navbarFontStyle} ` +
                `${
                  selectedNavItem === 'Create Customer'
                    ? Style.navbarSelectedItemStyle
                    : ''
                } d-flex align-items-center ml-5`
              }
            >
              Create Customer
            </div>
          )} */}
        </div>
        <div className="pageContainer">{this.renderTab()}</div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  userRole: state.entities.user.roles,
  orgList: state.entities.customers.list,
  selectedCustomer: state.entities.customers.selected,
})

const mapDispatchToProps = (dispatch) => ({
  setCurrentCustomer: (customerId) => dispatch(setCurrentCustomer(customerId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
