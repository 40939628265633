import React, { Component } from 'react'
import Style from './AdminManagement.module.css'
import { connect } from 'react-redux'
import { formatDetectionDatetime } from '../utilities/datetime'
import { withRouter } from 'react-router-dom';
import ModalPopup from '../components/common/ModalPopup'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper';
import DashIcon from '../assets/minus.png' 
import PlusIcon from '../assets/pluss.png'
import { getBlacklistUrls, removeBlacklistUrls } from '../store/entities/customers'

class BlacklistManagement extends Component {

    componentDidMount() {
        if(this.props.selectedCustomer) {
            this.props.getBlacklistUrls(this.props.selectedCustomer.id)
        }    
    }

    componentDidUpdate(prevProps) {
        if(prevProps.blacklistUrlFlag !== this.props.blacklistUrlFlag) {
            this.props.getBlacklistUrls(this.props.selectedCustomer.id)
        }
    }

    handleRemoveDomain = (blacklistUrlId, customerId) => {      
            this.props.removeBlacklistUrls( customerId, blacklistUrlId)
    }

    handleAddDomain = () => {
        this.props.history.push({pathname: `/customers/${this.props.selectedCustomer.id}/settings/add-blacklist-url`}) 
    }

    render() {
        let { blacklistUrls } = this.props
        return (
            <div>
                <div className={'mt-4 w-100'}>
                    <div className={`${Style.titleStyle}`}>
                        Blocked URLs
                    </div>
                    <div className={`${Style.shortInfoStyle} w-100 d-flex align-items-center mt-3 px-5`}>
                        <div style={{width: '80%'}} className={`${Style.borderStyle} px-4`}>
                            <div className={`${Style.fontBold}`}>
                                Current Blocked Urls
                            </div>
                            <div className={`${Style.currentAdminCount}`}>
                            {blacklistUrls.length}
                            </div>
                        </div>
                        <div style={{width: '20%'}} className={'ml-4'}>
                            <div className={`${Style.fontBold}`}>
                            Add Blocked Url
                            </div>
                            <div className={'d-flex mt-3'}>
                                <button className={`${Style.submitButton} focus-none px-4`} onClick={this.handleAddDomain}>Add Blocked Url</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'mt-5'}>
                    <div className={Style.adminsStyle}>
                        Blocked Urls
                    </div>
                    <div className={'mt-3'}>
                        <div className={`d-flex px-5 mx-2`}>
                            <div className={`${Style.fontBold}`} style={{width:'18%'}}><b>Name</b></div>
                            <div className={`${Style.fontBold}`} style={{width:'60%'}}><b>Url</b></div>
                            <div className={`${Style.fontBold}`} style={{width:'22%'}}><b>Action</b></div>
                        </div>
                        <div className={`${Style.tableContainerStyle} p-4 mt-3`}>
                            <div className={`${Style.tableStyle} ${Style.ScrollStyle}`}>
                                {
                                    this.props.loading ?
                                        <LoaderWrapper loading={this.props.loading} isFlex={true} styles={{top: '50px'}} waitForData={false}></LoaderWrapper>
                                    :
                                    blacklistUrls.map((item, index) => {
                                        return <div key={item.uid} className={`${ index%2 === 0 ? Style.rowBg : Style.customRowBg} px-4 py-2`}>
                                            <div className={`d-flex p-2`}>
                                                <div className={`${Style.fontBold}`} style={{width:'18%'}}>
                                                    {item.displayName}
                                                </div>
                                                <div style={{width:'60%'}}>
                                                    <div>
                                                        {item.url}
                                                    </div>
                                                    <div>
                                                        Created <i> {formatDetectionDatetime(item.created)}</i>
                                                    </div>
                                                </div>
                                                <div onClick={() => this.handleRemoveDomain(item.id, item.customerId)} className={`${Style.cursorStyle} d-flex`} style={{width:'22%'}}>
                                                    <div>
                                                        <img style={{height:'15px'}} src={DashIcon} alt="remove-icon"/>
                                                    </div>
                                                    <div className={`${Style.removeAdmin} ml-1`}>
                                                        Remove Url
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    })
                                }
                            </div>
                            
                        </div>
                    </div>
                </div> 
                {this.props.statusCode && <ModalPopup statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userId: state.entities.user.id,
    tenantId: state.entities.user.tenantId,
    needRefreshToken: state.entities.customers.needRefreshToken,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode,
    refreshToken: state.entities.user.refreshToken,
    loading: state.entities.customers.loading,
    blacklistUrls: state.entities.customers.blacklistUrls,
    selectedCustomer: state.entities.customers.selected,
    blacklistUrlFlag: state.entities.customers.blacklistUrlFlag
})

const mapDispatchToProps = (dispatch) => ({
    getBlacklistUrls: (customerId) => dispatch(getBlacklistUrls(customerId)),
    removeBlacklistUrls: (customerId, whitelistDomainId) => dispatch(removeBlacklistUrls(customerId, whitelistDomainId))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(BlacklistManagement))