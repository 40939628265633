import React, {Component} from 'react'
import Style from './Profile.module.css'
import { connect } from 'react-redux'
import { updateUserInfo, setError } from '../store/entities/customers'
import { setNewToken, getUserInfo } from '../store/entities/user'

import ModalPopup from '../components/common/ModalPopup'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper'

import { validateAlphaNumerics } from '../utilities/vaildations' 


class Profile extends Component {

    constructor () {
        super();
        this.state = {
            firstname: '',
            lastname: '',
            email:'',
            sendEmail:false
        };
      }

    componentDidMount() {
        this.props.getUserInfo(this.props.userId);
        if( this.props.email || this.props.fullName ) {
            let firstName = this.props.fullName ? this.props.fullName.split(' ').slice(0, 1).join(' ') : ''
            let lastName = this.props.fullName ? this.props.fullName.split(' ').slice(1).join(' ') : ''
            
            this.setState({
                firstname: firstName,
                lastname: lastName,
                email: this.props.email ? this.props.email : '',
                sendEmail: this.props.sendEmail
            })
        }
    }

    componentDidUpdate(prevProps) {
        if(prevProps.sendEmail !== this.props.sendEmail) {
            this.setState({    
                sendEmail: this.props.sendEmail
            })
        }
        if(prevProps.needRefreshToken !== this.props.needRefreshToken) {
            if(this.props.needRefreshToken) {
                this.props.setNewToken();
            }
        }
    }


    handleChange = (event) => {
        const value = event.target.value
        this.setState({    
            [event.target.name]: value
        })
    }

    handleCheckChange = (event) => {
        const { name, checked } = event.target;
        this.setState({    
            sendEmail: checked
        })
    }

    handleUpdateInfo = () => {

        let error = ''

        if(this.state.lastname === '') {
            error = "Last name is required"
            this.props.setError(error)
        } else if (!validateAlphaNumerics(this.state.lastname) || this.state.firstname.length > 50) {
            error = "Last name should only be alphanumeric and not more than 50 characters"
            this.props.setError(error)
        }

        if(this.state.firstname === '') {
            error = "First name is required"
            this.props.setError(error)
        } else if (!validateAlphaNumerics(this.state.firstname) || this.state.firstname.length > 50) {
            error = "First name should only be alphanumeric and not more than 50 characters"
            this.props.setError(error)
        }

        if(error === '') {
            let body = {
                "firstName": this.state.firstname,
                "lastName": this.state.lastname,
                "sendEmail": this.state.sendEmail
            }
            this.props.updateUserInfo(this.props.userId, body)
        }
 
    }

    render() {
        return (
            <div className={`mt-4`}>
                <LoaderWrapper loading={this.state.loading} waitForData={false}>
                    <>
                        <div className={`${Style.profileFontStyle}`}>
                            Profile
                        </div>
                        <div className={`${Style.containerStyle} pb-5 pl-4 mt-2`}>
                            <div className={`${Style.textStyle} pt-4`}>
                                Account Details
                            </div>
                            <div className={'mt-3'}>
                                <input value={this.state.firstname} name='firstname' className={`${Style.inputStyle} focus-none`} onChange={this.handleChange} />

                                <input value={this.state.lastname} name='lastname' className={`${Style.inputStyle} mt-3 focus-none`} onChange={this.handleChange} />

                                <input disabled value={this.state.email} name='email' className={`${Style.inputStyle} mt-3 focus-none`} onChange={this.handleChange} />

                                <div className={`pt-4`}><input type="checkbox" checked={this.state.sendEmail} name='sendEmail' onChange={this.handleCheckChange}/><span>&nbsp;Receive email alert</span></div>
                            </div>
                            <div className={`${Style.border} mt-3`} />
                            <div className={`mt-5`}>
                                <button onClick={this.handleUpdateInfo} className={`${Style.submitButton} focus-none`}>Update Info</button>
                                <button className={`${Style.cancelButton} ml-3 focus-none`}>Cancel</button>
                            </div>
                        </div> 
                    </>
                </LoaderWrapper>
                {this.props.statusCode && <ModalPopup statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
                
            </div> 

        )
    }
}

const mapStateToProps = (state) => ({
    email: state.entities.user.email,
    fullName: state.entities.user.name,
    userId: state.entities.user.id,
    tenantId: state.entities.user.tenantId,
    needRefreshToken: state.entities.customers.needRefreshToken,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode,
    refreshToken: state.entities.user.refreshToken,
    loading: state.entities.customers.loading,
    sendEmail: state.entities.user.sendEmail
})

const mapDispatchToProps = (dispatch) => ({
    updateUserInfo: (userId, body) => dispatch(updateUserInfo(userId, body)),
    setNewToken: () => dispatch(setNewToken()),
    getUserInfo: (userId) => dispatch(getUserInfo(userId)),
    setError: (error) => dispatch(setError(error))
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)