import moment from 'moment'
import React, { useState, useEffect } from 'react'
import ChartLine from './ChartLine'
import { removeBlacklistUrls } from '../../store/entities/customers'

const ChartLineBuild = (props) => {
  const [xaxis, setXaxis] = useState([])
  const [data1, setData1] = useState([])
  const [data2, setData2] = useState([])

  const tabs = {
    month: {
      name: "month",
      timespan: 5
    },
    year: {
      name: "year",
      timespan: 5
    },
    day: {
      name: "day",
      timespan: 30
    },
  } 

  useEffect(() => {
    setXaxis([])
    setData1([])
    setData2([])
    if (
      props.deployedLicensesGrowth &&
      props.deployedLicensesGrowth !== null &&
      Object.keys(props.deployedLicensesGrowth).length > 0
    ) {
      switch (props.selectedChartTab) {
        case tabs.month.name: 
          buildMonthChart()
          break

        case tabs.year.name: 
          buildYearChart()
          break

        case tabs.day.name: 
          buildDayChart()
          break
    
        default:
          break;
      }
    }
  }, [props])

  const buildMonthChart = () => {
    let _xaxis = []
    let interval = 1
    let _interval = interval
    const d = new Date()

    _xaxis.push(moment(d).format('MMM'))

    for (let i = 0; i < props.timespan - 1; i++) {
      const newDate = new Date()
      newDate.setDate(1)
      newDate.setMonth(d.getMonth() - _interval)
      _xaxis.push(
        moment(newDate).format('MMM'),
      )
      _interval += interval
    }
    setXaxis(_xaxis)

    setData1(_xaxis.map((item) => {
      let i = new Date(`${item} 1`).getMonth() + 1
      let newArr = props.deployedLicensesGrowth.filter((item) => item['mNTH'] === i)
      return newArr.length && newArr[0]['cNT'] || 0
    }))

    if (props.activeUsers !== null) {
      setData2(_xaxis.map((item) => {
        let i = new Date(`${item} 1`).getMonth() + 1
        let newArr = props.activeUsers.filter((item) => item['mNTH'] === i)
        return newArr.length && newArr[0]['cNT'] || 0
      }))
    }
  }

  const buildYearChart = () => {
    let _xaxis = []
    let interval = 1
    let _interval = interval
    const d = new Date()

    _xaxis.push(moment(d).format('YYYY'))
    for (let i = 0; i < props.timespan - 1; i++) {
      _xaxis.push(
        moment(new Date().setFullYear(d.getFullYear() - _interval)).format(
          'YYYY',
        ),
      )
      _interval += interval
    }
    setXaxis(_xaxis)

    setData1(_xaxis.map((item) => {
      let newArr = props.deployedLicensesGrowth.filter((ele) => ele['yR'] == item)
      return newArr.length && newArr[0]['cNT'] || 0
    }))
  
    if (props.activeUsers !== null) {
      setData2(_xaxis.map((item) => {
        let newArr = props.activeUsers.filter((ele) => ele['yR'] == item)
        return newArr.length && newArr[0]['cNT'] || 0
      }))
    }
  }

  const buildDayChart = () => {
    let _xaxis = []
    let interval = 1
    let _interval = interval
    const d = new Date()

    _xaxis.push(moment(d).format('DD  MMM'))
    for (let i = 0; i < props.timespan - 1; i++) {
      _xaxis.push(
        moment(new Date().setDate(d.getDate() - _interval)).format('DD  MMM')
      )
      _interval += interval
    }
    setXaxis(_xaxis)

    const deployedLicenses = [...props.deployedLicensesGrowth]

    setData1(_xaxis.map((day) => {
      let indexToRemove;
      let dayCount = deployedLicenses.find((item, ind) => {
        if(!item || item['dY'] !== Number(day.replace(/[^0-9]/g, ''))) {
          return false;
        }

        indexToRemove = ind
        return true
      })

      if(dayCount) {
        delete deployedLicenses[indexToRemove]
      }

      return dayCount && dayCount["cNT"] || 0
    }))

    if (props.activeUsers !== null) {
      const activeUsers = [ ...props.activeUsers ]
   
      setData2(_xaxis.map((day) => {
        let indexToRemove;
        let dayCount = activeUsers.find((item, ind) => {
          if(!item || item['dY'] !== Number(day.replace(/[^0-9]/g, ''))) {
            return false;
          }
  
          indexToRemove = ind
          return true
        })
  
        if(dayCount) {
          delete activeUsers[indexToRemove]
        }
  
        return dayCount && dayCount["cNT"] || 0
      }))
    }
  }

  return (
    <ChartLine
      xaxis={xaxis.length > 0 ? xaxis : null}
      data1={data1.length > 0 ? data1 : null}
      data2={data2.length > 0 ? data2 : null}
      name1={props.name1}
      name2={props.name2}
      width={650}
    />
  )
}

export default ChartLineBuild
