import React, { useEffect, useState } from 'react'
import { Card, ProgressBar } from 'react-bootstrap'
import { Pie } from 'react-chartjs-2'
import { FiRefreshCcw } from 'react-icons/fi'
import { chartJsDefaultTooltip } from '../../helpers/chartjs-utils'
import { getColor, rgbaColor } from '../../helpers/utils'

const PieChart = (props) => {
  const [showLoader, setShowLoader] = useState(true)
  const [legend, setLegend] = useState([])
  const [values, setValues] = useState([])

  useEffect(() => {
    if (legend && values && (legend.length > 0 || values.length > 0)) {
      setLegend([])
      setValues([])
    }
    if (props.data) {
      setLegend(props.data.legend)
      setValues(props.data.values)
      setShowLoader(props.loading)
    }
  }, [props])

  const data = {
    labels: legend,
    datasets: [
      {
        data: values && values.count,
        backgroundColor: [
          rgbaColor(getColor('primary'), 0.6),
          rgbaColor(getColor('secondary'), 0.6),
          rgbaColor(getColor('success'), 0.6),
          rgbaColor(getColor('info'), 0.6),
          rgbaColor(getColor('warning'), 0.6),
          rgbaColor(getColor('1100'), 0.6),
          rgbaColor(getColor('danger'), 0.6),
          rgbaColor(getColor('800'), 0.6),
          rgbaColor(getColor('light'), 0.6),
          rgbaColor(getColor('600'), 0.6),
          rgbaColor(getColor('dark'), 0.6),
          rgbaColor(getColor('200'), 0.6),
        ],
        borderWidth: 1,
        borderColor: getColor('gray-100'),
      },
    ],
  }

  const options = {
    plugins: {
      tooltip: chartJsDefaultTooltip(),
      legend: {
        display: true,
        position: 'left',
        itemStyle: {
          width: 90, // or whatever, auto-wrap
        },
      },
    },
    maintainAspectRatio: false,
  }

  return (
    <Card>
      <Card.Header>
        <div className="d-flex">
          <h6 className="flex-1">{props.heading}</h6>
          <FiRefreshCcw
            style={{ cursor: 'pointer' }}
            onClick={props.onRefresh}
          />
        </div>
      </Card.Header>
      <Card.Body className="position-relative">
        {props.errorNote && props.errorNote.length > 0 ? (
          <p>{props.errorNote}</p>
        ) : (
          <>
            {showLoader ? (
              <ProgressBar animated={true} now={100} />
            ) : (
              <Pie data={data} options={options} height={350} width={350} />
            )}{' '}
          </>
        )}
      </Card.Body>
    </Card>
  )
}

export default PieChart
