const formattingOptions = {
  hour12: true,
  hour: 'numeric',
  minute: 'numeric',
  month: 'long',
  day: '2-digit',
}

const getOrdinal = (n) => {
  n = +n
  var s = ['th', 'st', 'nd', 'rd'],
    v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export const formatDetectionDatetime = (utc) => {
  if (!utc) return ''
  //const str = new Date(utc).toLocaleString('en-US', formattingOptions)
  const str = new Intl.DateTimeFormat('en-US', { dateStyle: 'long', timeStyle: 'medium' }).format(new Date(utc));
  return str
  // const [date, time] = str.split(', ')
  // const [month, dd] = date.split(' ')
  // return [`${time.toLocaleUpperCase()},`, month, `${getOrdinal(dd)}`].join(' ')
}

export const getUTCDate = (span) => {
  let date = new Date()
  date.setDate(date.getDate() - span)
  return date.toISOString().slice(0, -1).replace('T', ' ')
}
