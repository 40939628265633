import React, { Component } from 'react'
import Style from './OrganizationManagement.module.css'
import { withRouter } from 'react-router-dom';
import { getCustomerList, setError } from '../store/entities/customers'
import { connect } from 'react-redux'
import {axiosInstance} from '../axiosInterceptor'
import ModalPopup from '../components/common/ModalPopup'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper';

import updateSvg from '../assets/update.svg'
import PlusIcon from '../assets/pluss.png'
import TAPopup from './TAPopup'
import { globalViewText } from '../utilities/config';
import Tooltip from './common/popover/tooltip'

class OrganizationManagement extends Component {

    state = {
        // loading: true,
        showTAPopup: false
    }

    componentDidMount() {
        if(this.props.loading == false)
        this.fetchCustomers()
    }

    fetchCustomers = async () => {
       

        try {
            // const customers = await axiosInstance.get(`${process.env.REACT_APP_API_BASE_URL}/portal/customers`);
            // this.setState({ loading: false });
            // this.props.loadCustomers(customers.data, true, this.props.tokenRoles);
            this.props.loadCustomers();

            // if (this.props.orgList.length < 1 && this.props.tokenRoles === 'TenantAdmin') {
            //     this.setState({ showTAPopup: true })
            // }

        } catch {
            // this.setState({ loading: false })
            this.props.setError("could not load customer details!!")
        }

    }

    handleClick = () => {
        this.props.history.push('/tenant-admin/add-organization')
    }

    handleUpdateInfo = (selectedTenant) => {
        this.props.history.push({ pathname: '/tenant-admin/update-organization', state: { tenant: selectedTenant } })
    }

    handleShowHidePopup = (value) => {
        this.setState({ showTAPopup: value });
    }

    closePopup = () => {
        if (this.state.showTAPopup) {
            this.setState({ showTAPopup: false });
        }
    }

    render() {
        return (
            <div>
                {(this.state.showTAPopup && (this.props.orgList.length < 1 && this.props.tokenRoles === 'TenantAdmin') && this.props.loading == false)  && <div className={`${Style.box}`}><TAPopup showOrHidePopup={this.handleShowHidePopup} /></div>}
                <div onClick={this.closePopup}>
                    <LoaderWrapper loading={this.props.loading} waitForData={false}>
                        <>
                            <div className={'mt-4 w-100'}>
                                <div className={`${Style.titleStyle}`}>
                                    Organization Management
                                </div>
                                <div className={`${Style.shortInfoStyle} w-100 d-flex align-items-center mt-3 px-5`}>
                                    <div style={{ width: '80%' }} className={`${Style.borderStyle} px-4`}>
                                        <div className={`${Style.fontBold}`}>
                                            Current Organizations
                                        </div>
                                        <div className={`${Style.currentAdminCount}`}>
                                            {this.props.orgList.filter((item) => item.isActive && item.organizationName != globalViewText).length}
                                        </div>
                                    </div>
                                    <div style={{ width: '20%' }} className={'ml-4'}>
                                        <div className={`${Style.fontBold}`}>
                                            Add Organization
                                        </div>
                                        <div className={'d-flex mt-3'}>
                                            <button onClick={this.handleClick} className={`${Style.submitButton} px-4 focus-none`}>Add Organization</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'mt-5'}>
                                <div className={Style.adminsStyle}>
                                    Organizations
                                </div>
                                <div className={'mt-3'}>
                                    <div className={`d-flex px-5`}>
                                        <div className={`${Style.fontBold}`} style={{ width: '25%' }}>Organization Name</div>
                                        <div className={`${Style.fontBold}`} style={{ width: '25%' }}>Primary Admin</div>
                                        <div className={`${Style.fontBold}`} style={{ width: '20%' }}>Organization Code</div>
                                        <div className={`${Style.fontBold}`} style={{ width: '15%' }}>Status</div>
                                        <div className={`${Style.fontBold}`} style={{ width: '15%' }}>Actions</div>
                                    </div>
                                    <div className={`${Style.tableContainerStyle} p-3 mt-3`}>
                                        <div className={`${Style.tableStyle} ${Style.ScrollStyle}`}>
                                            {
                                                this.props.orgList.length == 0 ?
                                                    <div className={'mt-5 d-flex justify-content-center'} style={{ fontWeight: 'bold', fontFamily: 'Roboto', fontSize: '20px' }}>
                                                        No Organization found
                                                    </div> :
                                                    this.props.orgList.map((item, index) => {

                                                        return item.organizationName === globalViewText || (!item.isActive) ? null : <div key={item.id} className={`${index % 2 === 0 ? Style.rowBg : Style.customRowBg} px-4 py-3`}>
                                                            <div className={`d-flex p-2`}>
                                                                <div className={`${Style.fontBold}`} style={{ width: '25%' }}>
                                                                    {item.organizationName}
                                                                </div>
                                                                <div className={'ml-1'} style={{ width: '25%' }}>
                                                                    {item.firstName + " " + item.lastName}
                                                                </div>
                                                                <div className={'ml-1 text-truncate'} style={{ width: '20%' }}>
                                                                    <div
                                                                        className={`${Style.nameWrap}`}
                                                                        data-tip
                                                                        data-for={item.orgCode}
                                                                    >
                                                                    <b>{item.orgCode ? item.orgCode : "NA"}</b>
                                                                </div>
                                                                {item.orgCode && <Tooltip id={item.orgCode} data={item.orgCode ? item.orgCode : "NA"} />}
                                                                </div>
                                                                
                                                                <div className={`${item.isActive ? Style.activeStyle : Style.inactiveStyle} ml-1`} style={{ width: '15%' }}>
                                                                    {item.isActive && item.isProvisioned ? 'Active' : ''}
                                                                </div>
                                                              
                                                                <div onClick={() => this.handleUpdateInfo(item)} className={`${Style.updateInfo} d-flex align-items-center`} style={{ width: '15%' }}>
                                                                    <img
                                                                        src={updateSvg}
                                                                        style={{ height: '1.5vw' }}
                                                                        alt="update-icon"
                                                                    />
                                                                    Update Info
                                                                </div>
                                                            </div>
                                                        </div>
                                                    })
                                            }
                                        </div>
                                        <div className={`${Style.inviteUserBox} d-flex p-4 mx-2 align-items-center mt-3`}>
                                            <div>
                                                <img style={{ height: '20px' }} src={PlusIcon} alt="plus-icon" />
                                            </div>
                                            <div>
                                                <button onClick={this.handleClick} className={`${Style.submitButton} px-4 ml-3 focus-none`}>Add Organization</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    </LoaderWrapper>

                    {this.props.statusCode && <ModalPopup statusCode={this.props.statusCode} msg={this.props.userMsg} />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    orgList: state.entities.customers.list,
    loading: state.entities.customers.loading,
    userToken: state.entities.user.token,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode,
    tokenRoles: state.entities.user.roles
})

const mapDispatchToProps = (dispatch) => ({
    loadCustomers: () => dispatch(getCustomerList()),
    setError: (error) => dispatch(setError(error))
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrganizationManagement))
