import { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Card, Form } from 'react-bootstrap';
import Style from './AdvancedSettings.module.css';
import React from 'react';
import { getMultimodalSettings, updateMultimodalSettings } from '../store/entities/customers';
import LoaderWrapper from "./common/LoaderWrapper/LoaderWrapper";
const PP1_ENDPOINT = process.env.REACT_APP_PP1_ENDPOINT;
const OPENAI1_ENDPOINT = process.env.REACT_APP_OPENAI1_ENPOINT;

const modelCodes = [
    {
        id : 1,
        value : 'PP1',
        name : 'PP1',
        endpoint : PP1_ENDPOINT


    },
    {
        id : 2,
        value : 'OPENAI1',
        name : 'OPENAI1',
        endpoint : OPENAI1_ENDPOINT
    }
]

const MultiModalSettings = (props) => {

  const [mmEnabled, setMmEnabled] = useState(props.mmEnabled);
  const [modelCode, setmodelCode] = useState(props.modelCode);

  useEffect(() => {
    props.getMultimodalSettings(props.selectedCustomer?.id);
  }, [props.selectedCustomer?.id])

  useEffect(() => {
    setMmEnabled(props.mmEnabled);
    setmodelCode(props.modelCode);
  }, [props.mmEnabled, props.modelCode])

  const handleOnmmEnabledChange = (e) => {
    setMmEnabled(e.target.checked);
    if(e.target.checked){
      setmodelCode(modelCodes.filter(model => model.value === 'OPENAI1')[0].value);
    }
    else{
      setmodelCode(modelCodes.filter(model => model.value === 'PP1')[0].value);
    }
    
    let requestBody = {
        mmEnabled: e.target.checked,
        modelCode: e.target.checked ? 'OPENAI1' : 'PP1'
    }

    props.updateMultimodalSettings(props.selectedCustomer?.id, requestBody);
  }

  const handlemodelCodeChange = (e) => {
    setmodelCode(e.target.value);
  }

  const { loading } = props;
  return (
   
    <div className={`mt-3`}>
      <>
        <div className={`${Style.profileFontStyle}`}>
               Multimodal Settings
            </div>
        <Card className="" style={{width: "641px", minHeight: "500px"}}>
        {
      props.settingsLoading ?
      <LoaderWrapper loading={props.settingsLoading} waitForData={false} isFlex={true} styles={{top: '100px'}}></LoaderWrapper>
      :
            <Card.Body>
              <Form>
                <Form.Group className={`mb-3 ${Style.setting}`} controlId="formBasicEmail">
                <Form.Label style={{ fontSize: '20px' }}>Multimodal</Form.Label>
                    
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label=""
                    checked={mmEnabled}
                    onChange={(e) => handleOnmmEnabledChange(e)}
                    
                  />
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control as="select"name="modelCode"onChange={(e) => handlemodelCodeChange(e)} value={modelCode} disabled={true}>
                        {
                            modelCodes.map((model) => (
                                <option key={model.id} value={model.value}>{model.endpoint}</option>
                            ))
                        }
                        </Form.Control>
                </Form.Group>
              </Form>
            </Card.Body>
        }
        </Card>
        </>  
    </div>
  );
};

const mapStateToProps = (state) => ({
    loading: state.entities.customers.loading,
    selectedCustomer: state.entities.customers.selected,
    settingsLoading : state.entities.customers.multimodalSettingsLoading,
    mmEnabled : state.entities.customers.multimodalSettings.mmEnabled,
    modelCode : state.entities.customers.multimodalSettings.modelCode
});

const mapDispatchToProps = (dispatch) => ({
    getMultimodalSettings: (customerId) => dispatch(getMultimodalSettings(customerId)),
    updateMultimodalSettings: (customerId, body) => dispatch(updateMultimodalSettings(customerId, body))
})  

export default connect(mapStateToProps, mapDispatchToProps)(MultiModalSettings);
