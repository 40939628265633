const OSTypes = new Map([
    ["mac","Mac OS"],
    ["linux", "Linux"],
    ["win", "Windows"],
    ["cros","Chrome OS"],
    ["ios", "iOS"],
    ["android", "Android"],
    ["openbsd", "Open/FreeBSD"],
    ["fuchsia", "Fuscia"]
]);

export const getOSType = (os) => {
    return OSTypes.get(os);
}