import React, { useState, useEffect } from 'react'
import { getColor, tooltipFormatter } from '../../helpers/utils'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { LegendComponent, ToolboxComponent } from 'echarts/components'
import { LineChart } from 'echarts/charts'
import Loading from '../common/loading'

echarts.use([ToolboxComponent, LegendComponent, LineChart])

const ChartLine = (props) => {
  const [xaxis, setXaxis] = useState([])
  const [data1, setData1] = useState([])
  const [data2, setData2] = useState([])
  const [loaderStyles, setLoaderStyles] = useState({
    marginTop: '140px',
  })

  const width = props.width || 416

  useEffect(() => {
    setXaxis(props.xaxis)
    setData1(props.data1)
    setData2(props.data2)
  }, [props])

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: getColor('dark'),
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none'
      }
    },
    xAxis: {
      type: 'category',
      data: xaxis,
      inverse: true,
      boundaryGap: false,
      axisLine: {
        lineStyle: {
          color: getColor('gray-300')
        }
      },
      axisTick: { show: false },
      axisLabel: {
        color: getColor('gray-400'),
        formatter: value => value.substring(0, 4),
        margin: 15
      },
      splitLine: {
        show: false
      }
    },
    yAxis: {
      type: 'value',
      position: 'right',
      splitLine: {
        lineStyle: {
          type: 'dashed',
          color: getColor('gray-200')
        }
      },
      boundaryGap: false,
      axisLabel: {
        show: true,
        color: getColor('gray-400'),
        margin: 15,
        formatter: function (value, index) {
          return value > 999999
            ? value / 1000000 + 'M'
            : value > 999
            ? value / 1000 + 'K'
            : value
        },
      },
      axisTick: { show: false },
      axisLine: { show: false },
      min: 0
    },
    series: [
      {
        type: 'line',
        name: props.name1,
        data: data1,
        itemStyle: {
          color: getColor('white'),
          borderColor: '#2d5fa7',
          borderWidth: 2
        },
        lineStyle: {
          color: '#2d5fa7'
        },
        showSymbol: true,
        symbol: 'circle',
        symbolSize: 7,
        smooth: false,
        emphasis: {
          scale: true
        }
      },
      // {
      //   type: 'line',
      //   name: props.name2,
      //   data: data2,
      //   itemStyle: {
      //     color: getColor('white'),
      //     borderColor: getColor('success'),
      //     borderWidth: 2
      //   },
      //   lineStyle: {
      //     color: getColor('success')
      //   },
      //   showSymbol: true,
      //   symbol: 'circle',
      //   symbolSize: 7,
      //   smooth: false,
      //   emphasis: {
      //     scale: true
      //   }
      // }
    ],
    grid: { right: '11%', left: '9%', bottom: '9%', top: '10%' },
  });

  return (
    <>
      {props.xaxis !== null && props.data1 !== null ? (
        <ReactEChartsCore
          echarts={echarts}
          option={getOption()}
          style={{ height: '320px', width: `${width}px` }}
        />
      ) : (
        <div style={{ height: '302x', width: `${width}px`, position: 'relative' }}>
          <Loading loading={true} styles={loaderStyles} />
        </div>
      )}
    </>
  )
}

export default ChartLine
