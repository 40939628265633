import React from 'react'
import './loading.scss'

function Loading({ loading, styles }) {
  return (
    loading && (
      <div className="loading-container" style={styles}>
        <div className='loading-wrap'>
          <div className='loading'></div>
        </div>
      </div>
    )
  )
}

export default Loading
