import React, {Component} from 'react'
import Style from './UserLicenseDetails.module.css'
import { connect } from 'react-redux'
import { formatDetectionDatetime } from '../utilities/datetime'
import { resetExtensionUser, getExtensionUserById, setCurrentCustomer } from '../store/entities/customers'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper'
import { getOSType } from '../utilities/OSTypes'
import BackIcon from '../assets/backButton.svg'

class UserLicenseDetails extends Component {

    state = {
        setCustomerOnce: true
      }

    componentDidMount = () => {
        let { selectedCustomer } = this.props
        const { param1, param2 , param3 } = this.props.match.params;  
        this.props.getExtensionUserById(param2 || param1, param3)//, isGlobalView(selectedCustomer.organizationName));
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this.state.setCustomerOnce && this.props.orgList.length > 0) {
          this.setState({
            setCustomerOnce: false,
          })

          let filtredElement = this.props.orgList.filter((ele) => ele.id === this.props.match.params.param1)
    
          if (filtredElement[0].id !== this.props.selectedCustomer.id) {
            this.props.setCurrentCustomer(filtredElement[0]);
          }
        }
      }

    handleClick = () => {
        this.props.history.push({ pathname: `/customers/${this.props.selectedCustomer.id}/license-management`, state: { tab: 'License Management' } })
        this.props.resetExtensionUser()
    }

    render() {
        let { userDetails } = this.props
        
        
        return (
            <div className={'mt-4'} style={{paddingLeft:'75px', marginRight:'33px'}}>
                <div className={`${Style.headerBg} d-flex align-items-center justify-content-between`} style={{paddingLeft:'29px'}}>
                    <div onClick={this.handleClick} style={{cursor: 'pointer'}}>
                        <img style={{ height: '19px' }} src={BackIcon} alt={'back-icon'} />
                        Return to License Management
                    </div>
                    {/* <div>
                        <LicenseFilterPopover />
                    </div> */}
                </div>
                <div className={`mt-4 `} style={{minHeight: '300px'}}>
                        {
                            this.props.loading ?
                                <LoaderWrapper loading={this.props.loading} styles={{top: '50%'}} data={userDetails}></LoaderWrapper>
                            :
                            userDetails &&
                            <>
                            <div style={{paddingLeft:'34px'}}>
                                <b>Details</b>
                            </div>
                            <div className={`${Style.detailsContainer} mt-2 d-flex scrollX`}>
                                <div className={'p-4'} style={{minWidth:'300px'}}>
                                    <table className={Style.leftTable}>
                                        <tbody>
                                            <tr>
                                                <td className={`py-3 px-2`}><b>User</b></td>
                                                <td className={`py-3 px-2`}><b>{userDetails.osUserId || userDetails.osComputerName || userDetails.osUUID || userDetails.email}</b></td>
                                            </tr>
                                            <tr>
                                                <td className={`py-3 px-2`}><b>Status</b></td>
                                                <td className={`py-3 px-2`}>{userDetails.isActive ? 'Active' : 'Inactive'}</td>
                                            </tr>
                                            <tr>
                                                <td className={`py-3 px-2`}><b>Created</b></td>
                                                <td className={`py-3 px-2`}>{formatDetectionDatetime(userDetails.created)}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={Style.divider}></div>
                                <div className={`p-4`} style={{minWidth:'850px'}}>
                                    <table className={Style.rightTable}>
                                        <thead>
                                            <tr>
                                                <td className={`py-3 px-2`} style={{width: '33.33%'}}><b>System</b></td>
                                                <td className={`py-3 px-2`} style={{width: '33.33%'}}><b>Browser</b></td>
                                                <td className={`py-3 px-2`} style={{width: '33.33%'}}><b>Date</b></td>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {userDetails.browserDetails.map((item, index) => {
                                                return(
                                                    <tr style={{boxShadow: 'none', background: '#fff'}}>
                                                        <td className={`py-3 px-2`}>{getOSType(item.os) || 'NA'}</td>
                                                        <td className={`py-3 px-2`}>{item.browser}</td>
                                                        <td className={`py-3 px-2`}>{item.created ? formatDetectionDatetime(item.created) : '-'}</td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            </>
                        }
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
  selectedCustomer: state.entities.customers.selected,
  userDetails: state.entities.customers.userDetails,
  loading: state.entities.customers.loading,
  orgList: state.entities.customers.list,
})

const mapDispatchToProps = (dispatch) => ({
    resetExtensionUser: () => dispatch(resetExtensionUser()),
    getExtensionUserById: (customerId, userId, isGlobalView) => dispatch(getExtensionUserById(customerId, userId, isGlobalView)),
    setCurrentCustomer: (customerId) => dispatch(setCurrentCustomer(customerId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserLicenseDetails)