import React from 'react'
import './loading.scss'

function Loading({ loading, styles }) {
  return (
    loading && (
      <div id='spin-wrap' style={styles}>
        <div id='spin'></div>
      </div>
    )
  )
}

export default Loading
