import React, { Component } from 'react'
import Style from './Account.module.css'
import { connect } from 'react-redux'

import { 
    cancelAccount, getCustomerDetails, getTenantDetails, updateSelectedCustomer, getTenantWhitelabelInfo,
    deleteTenantInfoWhitelabel,  updateTenantInfo, updateTenantInfoIsCustomized,
    updateTenantInfoWhitelabel, createTenantInfoWhitelabel, setError, deleteCustomer 
} from '../store/entities/customers'
import ModalPopup from '../components/common/ModalPopup'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper'
import UploadFile from './common/UploadFile/UploadFile'

import Modal from 'react-bootstrap/Modal'
import { validateAlphaNumerics, validatePhoneNumber, validateEmail } from '../utilities/vaildations'
import { isGlobalView } from '../utilities/config'; 

class Account extends Component {

    constructor() {
        super();
        this.state = {
            account: {
                phone: '',
                customerName:'',
                showConfirmBox:false,
                orgName: '',
                subDomain: '',
                isActive: ''
            },
            whitelabel: {
                supportEmail: '',
                brandName: '',
                brandLogo: '',
                logoFileName: '',
                isCustomized: false
            }
        };
    }

    componentDidMount() {
        if (this.props.selectedCustomer) {
            !isGlobalView(this.props.selectedCustomer.organizationName) && this.props.getCustomerDetails(this.props.selectedCustomer.id)
            this.getTenantWhitelabelInfo(this.props.selectedCustomer.tenantId)
            this.isTA() && this.props.selectedCustomer.tenantId && this.props.getTenantDetails(this.props.selectedCustomer.tenantId)
        }

        if(this.isTA()) {
            if(this.props.tenantDetails) {
                this.setState({
                    account: {
                        phone: this.props.tenantDetails.phone,
                        customerName: this.props.tenantDetails.tenantName,
                        subDomain: this.props.tenantDetails.subDomain,
                        isActive: this.props.tenantDetails.isActive
                    }
                })
                this.props.tenantDetails.isCustomized && this.getTenantWhitelabelInfo(this.props.tenantDetails.tenantId)
            } else {
                this.setState({
                    account: {
                        phone: 'XXXXXXXXXXX',
                        customerName: 'XXXXXXXXXXXX',
                        subdomain: 'XXXXXXXXXX',
                        isActive: false
                    }
                })
            }
        }
            
            
    }

    componentDidUpdate(prevProps) {
        if(prevProps.customerDetails !== this.props.customerDetails && this.props.customerDetails.id) {
            this.setState({
                ...this.state,
                account: {
                    phone: this.props.customerDetails.phone ? this.props.customerDetails.phone : '',
                    customerName: this.props.customerDetails.organizationName ? this.props.customerDetails.organizationName : '',
                    subDomain: this.props.customerDetails.subDomain ? this.props.customerDetails.subDomain : '',
                    isActive: this.props.customerDetails.isActive ? this.props.customerDetails.isActive : ''
                }
            })
            if(this.isTA() && this.props.customerDetails.isCustomized && !this.props.tenantWhitelabelInfo.isCustomized) {
                this.getTenantWhitelabelInfo(this.props.customerDetails.tenantId)
            }
        }
        if(prevProps.tenantWhitelabelInfo !== this.props.tenantWhitelabelInfo) {
            this.setState({
                ...this.state,
                whitelabel: {
                    supportEmail: this.props.tenantWhitelabelInfo.supportEmail ? this.props.tenantWhitelabelInfo.supportEmail : '',
                    brandName: this.props.tenantWhitelabelInfo.brandName ? this.props.tenantWhitelabelInfo.brandName : '',
                    logoFileName: this.props.tenantWhitelabelInfo.logoMSizeFileName ? this.props.tenantWhitelabelInfo.logoMSizeFileName : '',
                    brandLogo: this.props.tenantWhitelabelInfo.brandLogo ? this.props.tenantWhitelabelInfo.brandLogo : '',
                    isCustomized: !!this.props.tenantWhitelabelInfo.isCustomized
                }
            })       
        }
        if(!prevProps.isCustomized && this.props.isCustomized) {
            this.updateWhitelabelInfo()
        }

        if(prevProps.selectedCustomer !== this.props.selectedCustomer) {
            this.props.getCustomerDetails(this.props.selectedCustomer.id)
        }
    }

    handleChange = (event, scope) => {
        const value = event.target.value
        this.setState({    
            ...this.state,
            [scope]: {
                ...this.state[scope],
                [event.target.name]: value
            }
        })
    }
    
    getTenantWhitelabelInfo = async (tenantId) => {
        if(!(this.isPA() || this.isTA())) return
        this.props.getTenantWhitelabelInfo(tenantId)
    }

    handleCheckboxChange = (event, scope) => {
        const value = event.target.checked
        this.setState({    
            ...this.state,
            [scope]: {
                ...this.state[scope],
                [event.target.name]: value
            }
        })
    }

    handleFileChange = (fileInfo) => {
        if(fileInfo) {
            this.setState({
                ...this.state,
                whitelabel: {
                    ...this.state.whitelabel,
                    logoFileName: fileInfo.name,
                    brandLogo: fileInfo.base64,
                }
            })
        }
    }

    handleUpdateInfoAccount = () => {
        let error = ''

        if(this.state.account.phone === '') {
            error = "Phone number is required"
            this.props.setError(error)
        } else if (!validatePhoneNumber(this.state.account.phone)) {
            error = "Phone number is invalid. Phone number should be in this format ( 1234567890 )"
            this.props.setError(error)
        }

        if(this.state.account.customerName === '') {
            error = "Customer name is required"
            this.props.setError(error)
        } else if (!validateAlphaNumerics(this.state.account.customerName) || this.state.account.customerName.length > 50) {
            error = "Customer name should not be more than 50 characters"
            this.props.setError(error)
        }

        const hasCustomerId = this.props.customerDetails && this.props.customerDetails.id && !isGlobalView(this.props.selectedCustomer.organizationName)? true : false
        
        if (error === '') {
            const body = this.isTA() && !hasCustomerId ? 
            {
                tenantName: this.state.account.customerName,
                phone: this.state.account.phone
            }
            :
            {
                phone: this.state.account.phone,
                organizationName: this.state.account.customerName,
                isCustomized: this.state.account.isCustomized
            }
    
            if(this.isTA()) {
                if(!hasCustomerId) {
                    this.props.updateTenantInfo(this.props.tenantDetails.tenantId, body)
                    return
                }
                
            }

            if(this.isPA()) {
                this.props.updateTenantInfo(this.props.selectedCustomer.tenantId, {isCustomized: this.state.whitelabel.isCustomized})
                if(!this.state.whitelabel.isCustomized) {
                    this.clearWhitelabelSettings()
                }   
            }

            if(hasCustomerId){
                console.log("UpdateSelectedCustomer is Called: ", body)
                this.props.updateSelectedCustomer(this.props.selectedCustomer.id, body)
            }
        }
    }

    handleUpdateInfoWhitelabel = async () => {
        let error = ''

        if(this.state.whitelabel.supportEmail !== '' && (!validateEmail(this.state.whitelabel.supportEmail) || this.state.whitelabel.supportEmail > 200)) {
            error = 'Please enter a proper email id'
            this.props.setError(error)
        } 

        if (error === '') {
            this.props.updateTenantInfoIsCustomized(this.props.selectedCustomer.tenantId, {isCustomized: true})
        }
    }

    updateWhitelabelInfo = async () => {
        let body = {
            organizationName: this.state.account.customerName,
            subDomain: this.state.account.subDomain,
            supportEmail: this.state.whitelabel.supportEmail,
            brandName: this.state.whitelabel.brandName,
            logoFileName: this.state.whitelabel.logoFileName,
            brandLogo : this.state.whitelabel.brandLogo
        }
        this.props.updateTenantInfoWhitelabel(this.props.selectedCustomer.tenantId, body)
    }

    clearWhitelabelSettings = async () => {
        try {
            await this.props.deleteTenantInfoWhitelabel(this.props.selectedCustomer.tenantId, {subDomain: this.state.account.subDomain})
            await this.props.updateTenantInfo(this.props.selectedCustomer.tenantId, {isCustomized: false})
            this.setState({
                ...this.state,
                whitelabel: {
                    isCustomized: false
                }
            })
        } catch(err) {

        }
    }

    canShowWhitelabelArea = () => {
        return (this.isPA() || this.isTA()) && this.state.whitelabel.isCustomized && this.props.customerDetails && !this.props.customerDetails.tenantAsCustomer
    }

    isPA = () => this.props.tokenRoles == 'PixmAdmin'
    isTA = () => this.props.tokenRoles == 'TenantAdmin'

    handleCancelAccount = () => {
        if(this.isTA()) {
            this.props.cancelAccount(this.props.tenantDetails.tenantId)
            return
        }
        
        this.setState({ account: { showConfirmBox: false} })
        this.props.deleteCustomer(this.props.selectedCustomer.id)
    }

    handleModalClick = () => {
        const tenantId = this.props.tenantDetails?.tenantId || this.props.customerDetails?.tenantId
        this.props.getTenantDetails(tenantId)
    }

    render() {
        let { customerDetails } = this.props
        const {showConfirmBox} = this.state.account;
        const isPA = this.isPA()

        return (
            <React.Fragment>
                <Modal
                    show={showConfirmBox}
                    onHide={() => { }}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header >
                        <Modal.Title>Confirmation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to cancel your account?
                    <br />
                    Admins will no longer have access to Threat feed
                    </Modal.Body>
                    <Modal.Footer>
                        <button className={`${Style.cancelButton} focus-none`} variant="secondary" onClick={() => { this.setState({ account: { showConfirmBox: false} }) }}>Close</button>
                        <button className={`${Style.submitButton} focus-none`} variant="primary" onClick={this.handleCancelAccount}>Understood</button>
                    </Modal.Footer>
                </Modal>
            <div className={`mt-4`}>
                    {(customerDetails|| this.isTA()) && <>
                        <div className={`${Style.accountFont}`}>
                            Account Info
                        </div>
                        <div className={`mt-2 d-flex`}>
                            <div className={`${Style.containerStyle} w-50 px-4 py-4`}>
                                <div className={`${Style.textStyle}`}>
                                    Account Details
                                </div>
                                {
                                    this.props.loading ?
                                    <LoaderWrapper loading={this.props.loading} isFlex={true} styles={{top: '50px'}}> 
                                    </LoaderWrapper>
                                    :
                                    <>
                                        <div className={'mt-3 d-flex flex-column'}>
                                            <input value={this.state.account.customerName} disabled={isPA} onChange={(event) => this.handleChange(event, "account")} placeholder = {'Organization Name*'} className={`${Style.inputStyle} focus-none`} name={'customerName'} required/>

                                            <div className={`d-flex mt-2`}>
                                                <input value={this.state.account.subDomain} disabled className={`${Style.shortInputStyle}`} />
                                                <div className={`${Style.extendedInput} d-flex align-items-center justify-content-center`}> .pixm.net </div>
                                            </div>

                                            <div className={`d-flex mt-2`}>
                                                <input value={this.state.account.phone} onChange={(event) => this.handleChange(event, "account")} name={'phone'} placeholder={'Phone Number*'} className={`${Style.inputStyle} focus-none`} />
                                            </div>
                                        {isPA && !this.props.customerDetails.tenantAsCustomer && (
                                            <div className={`${Style.checkBoxArea} d-flex mt-4`}>
                                                <input checked={this.state.whitelabel.isCustomized} onChange={(event) => this.handleCheckboxChange(event, "whitelabel")} name={'isCustomized'} type="checkbox" />
                                                <span>&nbsp;Whitelabel</span>
                                            </div>
                                        )}
                                        </div>
                                        <div className={`${Style.border} mt-3`} />
                                        <div className={`mt-5`}>
                                            <button onClick={this.handleUpdateInfoAccount} className={`${Style.submitButton} focus-none`}>Update Info</button>
                                            <button className={`${Style.cancelButton} ml-3 focus-none`}>Cancel</button>
                                        </div>
                                    </>
                                }
                            </div>
                            <div className={`${Style.containerStyle} w-50 px-4 ml-4`}>
                                <div className={`${Style.textStyle} pt-4`}>
                                    Account Cancellation
                                </div>
                                <div className={`mt-4`}>
                                    <div className={`${Style.boldTextStyle}`}>
                                        Are you sure you want to cancel your account?
                                    </div>
                                    <div className={`${Style.normalTextStyle} mt-1`}>
                                        Admins will not longer have access the SaaS console.
                                    </div>
                                </div>
                                <div className={`mt-5`}>
                                    <div className={`${Style.normalTextStyle}`}>
                                        Let us know if we can help you before going that final road!
                                    </div>
                                    <div className={`${Style.emailTextStyle} mt-1`}>
                                        support@pixm.net
                                    </div>
                                </div>
                                <div style={{marginTop: '108px'}} className={`${Style.border}`} />
                                <div className={`mt-5`}>
                                    <button disabled={!this.state.account.isActive} onClick={() => { this.setState({ account: { showConfirmBox: false}}) }} className={`${Style.cancelAccountButton} focus-none`}>Cancel Account</button>
                                </div>
                            </div>
                        </div>
                        {this.canShowWhitelabelArea() && (
                            <div className={`mt-4 d-flex`}>
                           
                            <div className={`${Style.containerStyle} w-50 px-4 py-4`}>
                                <div className={`${Style.textStyle}`}>
                                    Whitelabel Details
                                </div>
                                <div className='d-flex flex-column'>
                                    <div className={'d-flex flex-row'}>
                                        <div className={`mt-2 d-flex w-50 flex-column pr-2 `}>
                                            <label className={`${Style.label}`}>Brand name</label>
                                            <input onChange={(event) => this.handleChange(event, "whitelabel")} name={'brandName'}
                                                value={this.state.whitelabel.brandName} className={`${Style.inputStyle} ${Style.whiteLabelInputStyle}`}
                                            />
                                        </div>
                                        <div className={`mt-2 d-flex w-50 flex-column pl-2`}>
                                            <label className={`${Style.label}`}>Support email</label>
                                            <input 
                                                value={this.state.whitelabel.supportEmail} 
                                                disabled={!isPA} 
                                                onChange={(event) => this.handleChange(event, "whitelabel")} className={`${Style.inputStyle} ${Style.whiteLabelInputStyle}`} name={'supportEmail'} 
                                            />
                                        </div>
                                        
                                    </div>
                                    <div className={'d-flex flex-row align-items-center mt-4'}>
                                        <div className={'d-flex align-items-center justify-content-center w-50 pr-2'}>
                                            {this.state.whitelabel.brandLogo && 
                                                <img className={`${Style.whitelabelLogoStyle}`} src={this.state.whitelabel.brandLogo}></img>
                                            }
                                        </div>

                                        <div className={`${Style.whiteLabelFileInputStyle} d-flex flex-row align-items-center justify-content-end w-50 pl-2 `}>
                                            <UploadFile
                                                inputId={'logoFile'}
                                                onDone={ this.handleFileChange } />
                                        </div>
                                    </div>
                                </div>
                                <div className={`${Style.border} mt-5`} />
                                <div className={`mt-5`}>
                                    <button onClick={() => this.handleUpdateInfoWhitelabel()} className={`${Style.submitButton} focus-none`}>Update Info</button>
                                    <button className={`${Style.cancelButton} ml-3 focus-none`}>Cancel</button>
                                </div>
                            </div>
                            <div className={`w-50 ml-4`}></div>
                        </div>
                        )}
                    </>}
                {this.props.statusCode && <ModalPopup handleClick={this.isTA() && this.handleModalClick} statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
            </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedCustomer: state.entities.customers.selected,
    customerDetails: state.entities.customers.customerDetails,
    tenantDetails: state.entities.customers.tenantDetails,
    tenantWhitelabelInfo: state.entities.customers.tenantWhitelabelInfo,
    isCustomized: state.entities.customers.isCustomized,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode,
    loading: state.entities.customers.loading,
    tokenRoles: state.entities.user.roles,
})

const mapDispatchToProps = (dispatch) => ({
    cancelAccount: (tenantId) => dispatch(cancelAccount(tenantId)),
    getCustomerDetails: (customerId) => dispatch(getCustomerDetails(customerId)),
    updateSelectedCustomer: (customerId, body) => dispatch(updateSelectedCustomer(customerId, body)),
    getTenantWhitelabelInfo: (tenantId) => dispatch(getTenantWhitelabelInfo(tenantId)),
    createTenantInfoWhitelabel: (tenantId, body) => dispatch(createTenantInfoWhitelabel(tenantId, body)),
    updateTenantInfoWhitelabel: (tenantId, body) => dispatch(updateTenantInfoWhitelabel(tenantId, body)),
    deleteTenantInfoWhitelabel: (tenantId, body) => dispatch(deleteTenantInfoWhitelabel(tenantId, body)),
    updateTenantInfo: (tenantId, body) => dispatch(updateTenantInfo(tenantId, body)),
    updateTenantInfoIsCustomized: (tenantId, body) => dispatch(updateTenantInfoIsCustomized(tenantId, body)),
    getTenantDetails: (tenantId) => dispatch(getTenantDetails(tenantId)),
    setError: (error) => dispatch(setError(error)),
    deleteCustomer: (customerId) => dispatch(deleteCustomer(customerId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Account)