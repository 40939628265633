import React, { Component } from 'react'
import './confirmPopup.scss'
import { ImWarning } from 'react-icons/im'
import { FiInfo } from 'react-icons/fi'
import { BsCheck2Circle } from 'react-icons/bs'
import { BiErrorAlt } from 'react-icons/bi'

export class ConfirmPopup extends Component {
  render() {
    const { icon, type, title, description, buttons } = this.props.details
    const { onYesClick, onNoClick, onCancelClick, onCloseClick } = this.props
    return (
      <div style={{ position: 'relative', zIndex: '1' }}>
        <div className="_modal">
          <div className="overlay"></div>
          <div className="_modal-body">
            <div className={`_modal-header ${type}`}>
              <div className={`_modal-icon ${type}`}>
                {icon && icon.length > 0 ? (
                  <img src={icon} alt="Action Image" className="_modal-image" />
                ) : type === 'info' ? (
                  <FiInfo className="_modal-icons" />
                ) : type === 'warning' ? (
                  <ImWarning className="_modal-icons" />
                ) : type === 'success' ? (
                  <BsCheck2Circle className="_modal-icons" />
                ) : (
                  <BiErrorAlt className="_modal-icons" />
                )}
              </div>

              <div className="_modal-title">
                <span>{title}</span>
              </div>
            </div>
            <div className="_modal-content">
              <div>
                <span style={{ whiteSpace: "pre-line" }}>{description}</span>
              </div>
              <div className="_modal-buttons">
                {buttons.includes('Y') && (
                  <button className="_modal-button" onClick={onYesClick}>
                    Yes
                  </button>
                )}
                {buttons.includes('N') && (
                  <button className="_modal-button" onClick={onNoClick}>
                    No
                  </button>
                )}
                {buttons.includes('C') && (
                  <button className="_modal-button" onClick={onCancelClick}>
                    Cancel
                  </button>
                )}
                {buttons.includes('O') && (
                  <button className="_modal-button" onClick={onCloseClick}>
                    Close
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ConfirmPopup
