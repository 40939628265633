import { createSlice } from '@reduxjs/toolkit'
import RSA from 'react-simple-auth'
import { authInitialState as initialState } from '../../store/initialState/userState'
import { apiCallBegan } from '../api'
import { toast } from 'react-toastify'


import { CookieUtils } from '../../utilities/cookies'

import { decodeToken } from '../../utilities/decodeToken'


const slice = createSlice({ 
  name: 'user',
  initialState,
  reducers: {
    userRequested: () => {
      
    },

    userLoggedIn: (user, action) => {
      if (action.payload) {
        user.isLoggedIn = true
        user.id = action.payload.user_id
        user.roles = action.payload.role
        user.name = action.payload.name
        user.tenantId = action.payload.tenantId
        user.email = action.payload.email
        user.domain = action.payload.domain
        user.gcipTenantId = typeof action.payload.firebase.tenant != "undefined" ? action.payload.firebase.tenant : action.payload.tenantId; 
      }
    },
    userLoggedOut: (user, action) => {
      user.isLoggedIn = initialState.isLoggedIn;
      user.id = initialState.id;
      user.roles = initialState.roles;
      user.name = initialState.name;
      user.token = initialState.token;
      user.decodedToken = initialState.decodedToken;
      user.refreshToken = initialState.refreshToken;
      user.tenantId = initialState.tenantId;
      user.email = initialState.email;
      user.gcipTenantId = initialState.gcipTenantId;
      let BASE_URL = process.env.REACT_APP_BASE_WHITELIST_URL.replace("<DOMAIN>",CookieUtils.getCustomerDomain());
      CookieUtils.remove("token");
      CookieUtils.remove("refresh_token");
      window.location.replace(`${BASE_URL}/product/login.html?u=${process.env.REACT_APP_API_BASE_URL}&b=${process.env.REACT_APP_BASE_WHITELIST_URL}&s=${process.env.REACT_APP_REGISTER_NEW_URL}`);
    },

    getUserInfoSuccess: (user, action) => {
      user.sendEmail = action.payload.response.customClaims.sendEmail ? action.payload.response.customClaims.sendEmail : false
    },

    getUserInfoFailed: (user, action) => {
      user.sendEmail = null;
    },

  },
})

export const {  userLoggedIn, 
                userRequested,
                userLoggedOut, 
                getUserInfoSuccess,
                getUserInfoFailed,
                 } = slice.actions
export default slice.reducer;

export const login = (session) => {
  return userLoggedIn(session);}

export const logout = () => {
  // RSA.invalidateSession()
  // window.location.assign(microsoftProvider.getSignOutUrl(window.location.origin))
  return userLoggedOut();
}

export const loginIfExisting = () => {
  // const session = RSA.restoreSession(microsoftProvider)
  let token = CookieUtils.get("token")

  let decodedToken = decodeToken(token);
  return userLoggedIn(decodedToken);
}

export const setNewToken = () => {
  console.log("Entered set new token ...");
  let token = CookieUtils.get("token")

  let decodedToken = decodeToken(token);
  return userLoggedIn(decodedToken);
}

export const getUserInfo = (userId) => {
  return apiCallBegan({
    urlPath: `/users/${userId}`,
    method: 'GET',
    onSuccess: getUserInfoSuccess.type,
    onError: getUserInfoFailed.type,
  })
}
