import React, { Component } from 'react'
import { connect } from 'react-redux'

import {
  verifyEvents,
  markFalsePositive,
  clearIncident,
  removeIncident,
} from '../../../store/entities/events'

import FPTypes from '../../../utilities/falsePositiveTypes'

import updateSvg from '../../../assets/update.svg'
import checkMark from '../../../assets/checkMark.svg'
import protect from '../../../assets/protect.svg'
import trash from '../../../assets/trash.svg'
import './updatePopover.scss'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import ConfirmPopup from './confirmPopup'
import {
  confirmVerify,
  confirmUnverify,
  confirmFalsePositive,
  confirmClear,
} from '../../../utilities/config'
import { eventTypes } from '../../../utilities/eventTypes'

class UpdatePopover extends Component {
  state = {
    showConfirm_Verify: false,
    showConfirm_Unverify: false,
    showConfirm_FP: false,
    showConfirm_Clear: false,
    verify: this.props.event.triagedEvent,
  }

  redirectToIncidents = () => {
    if (this.props.from === 'incidentDetails') {
      this.props.history.push('/incidents')
    }
  }

  handleVerify = () => {
    this.setState({
      showConfirm_Verify: true,
    })
  }

  handleUnverify = () => {
    this.setState({
      showConfirm_Unverify: true,
    })
  }

  handleFalsePositive = () => {
    this.setState({
      showConfirm_FP: true,
    })
  }

  handleClear = () => {
    this.setState({
      showConfirm_Clear: true,
    })
  }

  markVerify = () => {
    const { event, verifyEvents } = this.props
    const {
      id: eventId,
      url,
      detectionTime,
      email: user,
      userAction,
      brands,
      customerId
    } = event
    verifyEvents(
      [{ eventId, url, detectionTime, user, userAction, brands }],
      customerId,
    )
    this.setState({ verify: true })
    this.redirectToIncidents()
  }

  markUnverify = () => {
    const { event, removeIncident } = this.props
    const { id: eventId , customerId } = event
    removeIncident({ eventId }, customerId)
    this.setState({ verify: false })
    this.redirectToIncidents()
  }

  markFalsePositive = () => {
    const { event, markFalsePositive } = this.props
    const { id: eventId, url , customerId } = event
    markFalsePositive(
      [{ eventId, url, falsePositiveType: FPTypes.DEFAULT_FP }],
     customerId,
    )
    this.redirectToIncidents()
  }

  markClear = () => {
    const { event, clearIncident, selectedCustomer } = this.props
    const { id: eventId , customerId } = event
    clearIncident({ eventId }, customerId)
    this.redirectToIncidents()
  }

  handleClose = () => {
    this.setState({
      showConfirm_Verify: false,
      showConfirm_FP: false,
      showConfirm_Clear: false,
      showConfirm_Unverify: false,
    })
  }

  render() {
    return (
      <>
        <Dropdown>
          {this.props.tokenRoles === 'PixmAdmin' || !(this.props.event.type === eventTypes.reported_phish || this.props.event.type === eventTypes.google_form_phish)  && !this.state.verify ? (<Dropdown.Toggle
            bsPrefix="toggle"
            as={Link}
            to="#!"
            className="stausText"
          >
            <img src={updateSvg} alt="update-icon" style={{ height: '19px' }} />
            <span style={{fontSize: '14px'}}>Update Status</span>
          </Dropdown.Toggle>): null}
          {this.state.verify  || this.props.event.type === eventTypes.reported_phish || this.props.event.type === eventTypes.google_form_phish? (
            <Dropdown.Menu className="actionContainer short">
              <Dropdown.Item className="menuItem" onClick={this.handleClear}>
                <img src={trash} className="actionImg" />
                Clear
              </Dropdown.Item>
            </Dropdown.Menu>
          ) : (
            <>
              {this.props.tokenRoles === 'PixmAdmin' ? (
                <Dropdown.Menu className="actionContainer long">
                  <Dropdown.Item
                    className="menuItem"
                    onClick={this.handleVerify}
                  >
                    <img src={checkMark} className="actionImg" />
                    Verify
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="menuItem"
                    onClick={this.handleFalsePositive}
                  >
                    <img src={protect} className="actionImg" />
                    Mark False Positive
                  </Dropdown.Item>
                  <Dropdown.Item
                    className="menuItem"
                    onClick={this.handleClear}
                  >
                    <img src={trash} className="actionImg" />
                    Clear
                  </Dropdown.Item>
                </Dropdown.Menu>
              ) : (
                <Dropdown.Menu className="actionContainer short">
                  <Dropdown.Item
                    className="menuItem"
                    onClick={this.handleFalsePositive}
                  >
                    <img src={protect} className="actionImg" />
                    Mark False Positive
                  </Dropdown.Item>
                </Dropdown.Menu>
              )}
            </>
          )}

          {/* <Dropdown.Item className="menuItem" onClick={this.handleUnverify}>
                <img src={checkMark} className="actionImg" />
                Unverify
              </Dropdown.Item> */}
        </Dropdown>
        {this.state.showConfirm_Verify && (
          <ConfirmPopup
            details={confirmVerify}
            onYesClick={this.markVerify}
            onCancelClick={this.handleClose}
          />
        )}
        {this.state.showConfirm_Verify && (
          <ConfirmPopup
            details={confirmUnverify}
            onYesClick={this.markUnverify}
            onCancelClick={this.handleClose}
          />
        )}
        {this.state.showConfirm_FP && (
          <ConfirmPopup
            details={confirmFalsePositive}
            onYesClick={this.markFalsePositive}
            onCancelClick={this.handleClose}
          />
        )}
        {this.state.showConfirm_Clear && (
          <ConfirmPopup
            details={confirmClear}
            onYesClick={this.markClear}
            onCancelClick={this.handleClose}
          />
        )}
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  tokenRoles: state.entities.user.roles,
  selectedCustomer: state.entities.customers.selected,
})

const mapDispatchToProps = (dispatch) => ({
  verifyEvents: (events, customerId) =>
    dispatch(verifyEvents(events, customerId)),
  markFalsePositive: (events, customerId) =>
    dispatch(markFalsePositive(events, customerId)),
  clearIncident: (events, customerId) =>
    dispatch(clearIncident(events, customerId)),
  removeIncident: (events, customerId) =>
    dispatch(removeIncident(events, customerId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePopover)
