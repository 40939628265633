import React, { Component } from 'react';
import { connect } from 'react-redux';
import NextSvg from '../../../assets/chevronRight.svg';
import PrevSvg from '../../../assets/chevronLeft.svg';
import { v4 as uuidv4 } from 'uuid';
import './paginator.scss';

class Pagination extends Component {
  state={
    currentPageNo: 1,
    isLastPage: typeof this.props.isLastPage === "boolean" ? this.props.isLastPage : false,
    isFirstPage: true,
    pageSize: (typeof this.props.pageSize === "number" && this.props.pageSize > 0) ? this.props.pageSize : 10,
    resetOnChange: this.props.resetOnChange || `id-${uuidv4()}`
  }

  componentDidUpdate = (prevProps) => {
    if(prevProps.isLastPage !== this.props.isLastPage) {
      this.setState({isLastPage :this.props.isLastPage});
    }
    if(prevProps.resetOnChange !== this.props.resetOnChange) {
      this.setState({resetOnChange: this.props.resetOnChange, currentPageNo: 1, isFirstPage: true});
    }
    if(prevProps.pageSize !== this.props.pageSize) {
      this.setState({pageSize: (typeof this.props.pageSize === "number" && this.props.pageSize > 0) ? this.props.pageSize : 10});
    }
  }

  previousPage = () => {
    if(this.state.currentPageNo > 1) {
      let tmpPageNo = this.state.currentPageNo - 1;
      this.setState({currentPageNo: tmpPageNo, isLastPage: false, isFirstPage: (tmpPageNo <= 1) ? true : false })
      if(typeof this.props.getPrevPageData === 'function')
        this.props.getPrevPageData(tmpPageNo, this.state.pageSize);
    } else {
      this.setState({isFirstPage: true})
    }
  }

  nextPage = () => {
    if(!this.state.isLastPage) {
      let tmpPageNo = this.state.currentPageNo + 1;
      this.setState({currentPageNo: tmpPageNo, isFirstPage: false})
      if(typeof this.props.getNextPageData === 'function')
        this.props.getNextPageData(tmpPageNo, this.state.pageSize);
    }
  }

  render() {
    return (
      <div className="d-flex justify-content-center">
        <button className="page-navigation mx-2 page-nav-active" data-toggle="tooltip" data-placement="top" title="Previous Page" onClick={this.previousPage}>
          <img src={PrevSvg} width="24px" alt="" />
        </button>
        <div className="mx-2 ">{this.state.isFirstPage ? "": "..."}</div>

        <div className="mx-2 page-no"> {this.state.currentPageNo <= 1 ? "" : this.state.currentPageNo - 1} </div>
        
        <div className="mx-2 current-page-no">{this.state.currentPageNo}</div>

        <div className="mx-2 page-no"> {this.state.isLastPage ? "" : this.state.currentPageNo + 1} </div>

        <div className="mx-2 ">{this.state.isLastPage ? "": "..."}</div>

        <button className="page-navigation mx-2 page-nav-active" data-toggle="tooltip" data-placement="top" title="Next Page" onClick={this.nextPage}>
          <img src={NextSvg} width="24px" alt="" />
        </button>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Pagination);
