import React, { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { loadNextEvents, loadPrevEvents, setSwitchToChartEvents } from '../store/entities/events'
import Paginator from './common/paginator/paginator'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper'
//import { Card, Table } from 'react-bootstrap'
//import { FaDesktop, FaMobileAlt } from 'react-icons/fa'
import { CiSquareChevRight } from 'react-icons/ci'
import Tooltip from './common/popover/tooltip'
import { formatDetectionDatetime } from '../utilities/datetime'
import UpdateVerify from './common/popover/updateVerify'
import UpdatePopover from './common/popover/updatePopover'
import UpdateClear from './common/popover/updateClear'
import EnterIcon from '../assets/Enter_Arrow_Right.svg'
import AppContext from '../context/Context'
import './incidentList.scss'
import DetailsPopover from './common/popover/detailsPopover'
import { getOSType } from '../utilities/OSTypes'

const IncidentList = (props) => {
  const {
    events,
    loading,
    threatFilters,
    totalSize,
    loadNextEvents,
    loadPrevEvents,
    handleViewSelect,
    switchToChart,
    tokenRoles,
  } = props
  const { offset, pageSize } = threatFilters
  const DeviceType = new Map([
    ['D', 'Desktop'],
    ['M', 'Mobile'],
  ])

  const [resultsArr, setResultsArr] = useState([offset + 20])

  useEffect(() => {
    if (events.length > 0) {
      setResultsArr([...resultsArr, offset + 20])
    }
    else if(switchToChart && tokenRoles == "CustomerAdmin"){ //We want 'switchToChart' feature only for CA
      handleViewSelect('chart')
      props.setSwitchToChartEvents()
    }
  }, [events])

  useEffect(() => {
    setResultsArr([offset + 20])
  }, [
    props.selectedCustomer,
    threatFilters['endTime'],
    threatFilters['startTime'],
    threatFilters['type'],
    threatFilters['cleared'],
    threatFilters['order'],
    threatFilters['extensionUserId'],
    threatFilters['brand'],
    threatFilters['triagedEvent'],
    threatFilters['removeRepeateUrl'],
    threatFilters['removeThreatUrl'],
    threatFilters['includeFalsePositive'],
  ])

  return (
    <>
      <div className="pageContainer">
        <LoaderWrapper 
          loading={loading} data={events.slice(offset, offset + 20)} 
          styles={{ minHeight: '620px' }} placeholderText={"No Threats Detected"}
        >
          <>
          <div>
            <table className="listTable mb-5">
              <thead className="tableHead">
                <tr>
                  <th style={{ minWidth: '290px' }}>
                    <b>
                      Results (
                      {totalSize % 21 !== 0
                        ? events.length
                        : `More than ${Math.max(...resultsArr)}`}
                      )
                    </b>
                  </th>
                  <th style={{ minWidth: '400px' }}>URL</th>
                  <th style={{ minWidth: '100px' }}>Action</th>
                  <th style={{ minWidth: '150px' }}>Status</th>
                  <th style={{ minWidth: '50px' }}></th>
                </tr>
              </thead>
              <tbody>
                {events.slice(offset, offset + 20).map((item, index) => (
                  <tr key={index}>
                    <td style={{ minWidth: '290px' }}>
                      <DetailsPopover
                        header={
                          item?.osUserId ||
                          item?.osComputerName ||
                          item?.osuuid ||
                          item?.email
                        }
                        data={{
                          User:
                            item?.osUserId ||
                            item?.osComputerName ||
                            item?.osuuid ||
                            item?.email,
                          'User ID': item.osUserId ? item.osUserId : 'NA',
                          Platform: `${getOSType(item.os)}, ${
                            item.browserName
                          }`,
                          'Machine ID': item.osuuid ? item.osuuid : 'NA',
                          'Computer Name': item.osComputerName
                            ? item.osComputerName
                            : 'NA',
                          Device: item.deviceType
                            ? DeviceType.get(item.deviceType)
                            : 'NA',
                        }}
                      />
                      <span style={{ color: '#2d5fa7' }}>
                        <b>{item.brands}</b>
                      </span>
                    </td>
                    <td style={{ minWidth: '400px' }}>
                      <div
                        className="textWrap urlWrap"
                        data-tip
                        data-for={item.url}
                      >
                        {item.url}
                      </div>
                      <Tooltip id={item.url} data={item.url} />
                      <div className="textWrap urlWrap">
                        <span>
                          <i>
                            Opened at{' '}
                            {formatDetectionDatetime(item.detectionTime)}
                          </i>
                        </span>
                      </div>
                    </td>
                    <td style={{ minWidth: '100px' }}>{item.userAction}</td>
                    <td style={{ minWidth: '150px' }}>
                      {/* {item.triagedEvent === true ? (
                        <span className="verifyText">
                          <b>Verified Phish</b>
                        </span>
                      ) : item.type == 3 ? (
                        <span className="reportedPhishText">
                          <b>Reported Phish</b>
                        </span>
                      ) : (
                        <span className="unverifyText">
                          <b>Suspicious</b>
                        </span>
                      )} */}
                      {<span className={item.status === "Verified Phish" ? 
                          "verifyText" : item.status === "Reported Phish"?
                          "reportedPhishText" : "unverifyText"}>
                           <b>{item.status}</b> 
                      </span>}
                      <UpdatePopover
                        {...props}
                        role={props.tokenRoles}
                        event={item}
                      />
                    </td>
                    <td style={{ minWidth: '50px' }}>
                      <img
                        className="rowDetail"
                        onClick={() => props.onDetails(item)}
                        src={EnterIcon}
                        alt="next-icon"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            </div>
            <div className={`d-flex justify-content-center align-items-end`} >
            <Paginator
              onNext={loadNextEvents}
              onPrev={loadPrevEvents}
              filters={threatFilters}
              totalSize={
                totalSize % 21 !== 0
                  ? events.length
                  : events.length + 1
              }
              cacheSize={events.length}
              from={'incidentList'}
            />
         </div>
         </>
          </LoaderWrapper>

      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  events: state.entities.events.list,
  loading: state.entities.events.loading,
  threatFilters: state.entities.events.threat.filters,
  totalSize: state.entities.events.totalSize,
  tokenRoles: state.entities.user.roles,
  selectedCustomer: state.entities.customers.selected,
})

const mapDispatchToProps = (dispatch) => ({
  loadNextEvents: (offset, selectedId, defaultCustomerId, isGlobalView) =>
    dispatch(loadNextEvents(offset, selectedId, defaultCustomerId, isGlobalView)),
  loadPrevEvents: (offset, selectedId, defaultCustomerId, isGlobalView) =>
    dispatch(loadPrevEvents(offset, selectedId, defaultCustomerId, isGlobalView)),
  setSwitchToChartEvents : () => dispatch(setSwitchToChartEvents())
})

export default connect(mapStateToProps, mapDispatchToProps)(IncidentList)
