import React, { useState, useEffect } from 'react'
import { getColor, tooltipFormatter } from '../../helpers/utils'
import ReactEChartsCore from 'echarts-for-react/lib/core'
import * as echarts from 'echarts/core'
import { LegendComponent, ToolboxComponent } from 'echarts/components'
import { BarChart } from 'echarts/charts'
import Loading from '../common/loading'

echarts.use([ToolboxComponent, LegendComponent, BarChart])

const ChartHorizontalBar = (props) => {
  const [yaxis, setYaxis] = useState([])
  const [data, setData] = useState([])
  const [loaderStyles, setLoaderStyles] = useState({
    marginTop: '0px',
  })

  useEffect(() => {
    setYaxis(props.yaxis)
    setData(props.data)
  }, [props])

  const getOption = () => ({
    tooltip: {
      trigger: 'axis',
      padding: [7, 13],
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('dark'), fontWeight: 'bold' },
      borderWidth: 1,
      formatter: tooltipFormatter,
      transitionDuration: 0,
      axisPointer: {
        type: 'none',
      },
      position: function (point, params, dom, rect, size) {
        return [point[0], point[1]]
      },
    },
    xAxis: {
      type: 'value',
      boundaryGap: '0%',
      axisLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-300'),
        },
      },
      axisTick: { show: false },
      axisLine: { show: false },
      axisLabel: {
        color: 'black',
      },
      splitLine: {
        show: true,
        lineStyle: {
          color: getColor('gray-300'),
        },
      },
      min: 0,
    },
    yAxis: {
      type: 'category',
      data: yaxis,
      inverse: true,
      boundaryGap: '0%',
      axisLabel: {
        formatter: (value) =>
          value
            .replace('https', '')
            .replace('http', '')
            .replace('://', '')
            .replace('www', '')
            .replace('.', '')
            .replace(' ', ''),
        show: true,
        color: 'black',
        align: 'left',
        margin: 53,
        fontSize: '11px',
        overflow: 'truncate',
        width: 49
      },
      splitLine: {
        show: false,
      },
      axisTick: { show: false },
      axisLine: { show: false },
    },
    series: [
      {
        type: 'bar',
        name: 'Total',
        data,
        lineStyle: { color: getColor('primary') },
        itemStyle: {
          color: '#85ceae',
          borderRadius: [4, 4, 4, 4],
        },
        showSymbol: false,
        symbol: 'circle',
        smooth: false,
        emphasis: {
          scale: true,
        },
        barWidth: '13px',
      },
    ],
    grid: { right: '3%', left: '13%', bottom: '10%', top: '5%' },
  })
  return (
    <>
      {props.yaxis !== null && props.data !== null ? (
        <ReactEChartsCore
          echarts={echarts}
          option={getOption()}
          style={{ height: '315px', width: '425px', paddingLeft: '17px' }}
        />
      ) : (
        <div style={{ height: '315px', width: '425px', position: 'relative' }}>
          <Loading loading={true} styles={loaderStyles} />
        </div>
      )}
    </>
  )
}

export default ChartHorizontalBar
