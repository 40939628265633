import React, { Component } from 'react'
import Style from './AddOrganization.module.css'
import { setError, addBlacklistUrl } from '../store/entities/customers'
import { connect } from 'react-redux'
import ModalPopup from '../components/common/ModalPopup'

import BackIcon from '../assets/backButton.svg'

class AddBlacklistUrl extends Component {

    constructor() {
        super();
        this.state = {
            url: '',
            checkKeywords: ['http', 'https', 'www'],
            escapeCharacter: '/',
            statusCode: ''
        };
    }

    handleModalClick = () => {
        this.props.history.push({ pathname: `/customers/${this.props.selectedCustomer.id}/settings`, state: { tab: 'Blacklist Management' } })  
    }

    handleChange = (event) => {
        const value = event.target.value
        this.setState({
            url: value
        })
    }

    handleClick = () => {
            this.props.history.push({ pathname: `/customers/${this.props.selectedCustomer.id}/settings`, state: { tab: 'Blacklist Management' } })  
    }

    validateURL = (url) => {
        //let pattern = new RegExp(/(http(s)?:\/\/.)(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/); 
        let pattern = new RegExp(/[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/); 
        let isValidPattern = pattern.test(url)
        let keywordCheck = 0
        let validProtocol = true
        this.state.checkKeywords.forEach(ele => {
            if(url.includes(ele)) {
                keywordCheck = 1
                if(ele !== 'www') {
                    let index = url.indexOf(ele[ele.length-1])
                    if(url[index + 2] !== '/' && url[index + 3] !== '/')
                    {
                        validProtocol = false
                    }
                }
            }            
        })
        if (!validProtocol) {
            return false
        }
        let urlParts = url.split('.')
        let charNumPattern = new RegExp(/^[a-zA-Z0-9]*$/)
        for(let i = 0; i < urlParts.length; i++){
            if (!charNumPattern.test(urlParts[i][0])) {
                return false
            }
            if(!charNumPattern.test(urlParts[i][urlParts[i].length-1]) && i !== urlParts.length-1) {
                return false
            }
        }
        let emailPattern = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)
        if(emailPattern.test(url)){
            return false
        }
        if (keywordCheck === 0 && url.includes(this.state.escapeCharacter)) {
            let urlChar1Index = url.indexOf(".")
            let urlChar2Index = url.indexOf("/")
            if (isValidPattern && urlChar1Index > 1 && urlChar2Index > urlChar1Index + 2){
                return true
            } else {
                return false
            }
        } else if(isValidPattern) {
            return true
        } else {
            return false
        }
    }

    handleSubmit = () => {
        this.setState({statusCode: ''})
        let isValid = this.validateURL(this.state.url)
        if(this.state.url === '') {
            let error = "URL is required."
            this.props.setError(error)
        } else {
            if (isValid) {
                let body = {
                    "url": this.state.url
                }
                this.props.addBlacklistUrl(this.props.selectedCustomer.id, body)
            } else {
                this.setState({statusCode: '201'})
                let error = "Please enter a valid url."
                this.props.setError(error)
            }
        }
    }

    render() {
        return (
            <div className={'px-5 mt-4'}>
                <div className={`${Style.headerBg} px-5 d-flex align-items-center justify-content-between`}>
                    <div onClick={this.handleClick} style={{ cursor: 'pointer' }}>
                        <img style={{ height: '1.5vw' }} src={BackIcon} alt={'back-icon'} />
                        Return to Blocked URLs
                    </div>
                </div>
                <div className={`${Style.detailsContainer} w-50 mt-4 py-5 px-5`}>
                    <div className={`d-flex flex-column`}>
                        <input name='Url' placeholder={'Url'} className={`${Style.inputStyle} focus-none mt-3`} onChange={this.handleChange} />
                    </div>
                    <div className={'mt-4'}>
                        <button onClick={this.handleSubmit} className={`${Style.submitButton} px-4 focus-none`}>Add URL</button>
                        <button onClick={this.handleClick} className={`${Style.cancelButton} px-4 ml-2 focus-none`}>Cancel</button>
                    </div>
                </div>
                {this.props.statusCode && <ModalPopup handleClick={this.handleModalClick} statusCode={this.state.statusCode.length === 0 ? this.props.statusCode : this.state.statusCode} msg={this.props.userMsg}/> }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    selectedCustomer: state.entities.customers.selected,
    userRole: state.entities.user.roles,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode,
})

const mapDispatchToProps = (dispatch) => ({
    addBlacklistUrl: (customerId, body) => dispatch(addBlacklistUrl(customerId, body)),
    setError: (error) => dispatch(setError(error)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddBlacklistUrl)