import { combineReducers } from '@reduxjs/toolkit'
import eventsReducer from './events'
import devicesReducer from './devices'
import customersReducer from './customers'
import userReducer from './user'
import lookupReducer from './lookup'

export default combineReducers({
  events: eventsReducer,
  devices: devicesReducer,
  customers: customersReducer,
  user: userReducer,
  lookup: lookupReducer
})
