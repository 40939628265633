import React, {Component} from 'react'
import Style from './MspAdminManagement.module.css'
import { getUsersList, removeAdmin } from '../store/entities/customers'
import { connect } from 'react-redux'
import { formatDetectionDatetime } from '../utilities/datetime'
import { withRouter } from 'react-router-dom';
import ModalPopup from '../components/common/ModalPopup'
import LoaderWrapper from './common/LoaderWrapper/LoaderWrapper'

import DashIcon from '../assets/minus.png' 
import PlusIcon from '../assets/pluss.png'

class AdminManagement extends Component {

    componentDidMount() {
        this.props.getUsersList(null, null, 'TenantAdmin')
    }

    componentDidUpdate(prevProps) {
        if(prevProps.userFlag !== this.props.userFlag) {
            if(this.props.userFlag) {
                this.props.getUsersList(null, null, 'TenantAdmin')
            }
        }
    }

    handleRemoveAdmin = (userId) => {
        if(this.props.users.length > 1) {
            this.props.removeAdmin(userId)
        }
    }

    handleInviteAdmin = () => {
        this.props.history.push('/tenant-admin/add-admin') 
    }

    handleChange = (e) => {
        let value = e.target.value
        this.setState({
            email: value
        })
    }

    render() {
        return (
            <div>
                <LoaderWrapper loading={this.props.loading} waitForData={false}>
                    <>
                        <div className={'mt-4 w-100'}>
                            <div className={`${Style.titleStyle}`}>
                                MSP Admins
                            </div>
                            <div className={`${Style.shortInfoStyle} w-100 d-flex align-items-center mt-3 px-5`}>
                                <div style={{width: '80%'}} className={`${Style.borderStyle} px-4`}>
                                    <div className={`${Style.fontBold}`}>
                                        Current MSP Admins
                                    </div>
                                    <div className={`${Style.currentAdminCount}`}>
                                        {this.props.users.length}
                                    </div>
                                </div>
                                <div style={{width: '20%'}} className={'ml-4'}>
                                    <div className={`${Style.fontBold}`}>
                                    Invite Admin
                                    </div>
                                    <div className={'d-flex mt-3'}>
                                        <div>
                                            <button onClick={this.handleInviteAdmin} className={`${Style.submitButton} px-4 focus-none`}>Add and invite admin</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'mt-5'}>
                            <div className={Style.adminsStyle}>
                                Admins
                            </div>
                            <div className={'mt-3'}>
                                <div className={`d-flex px-5 mx-2`}>
                                    <div className={`${Style.fontBold}`} style={{width:'18%'}}><b>Name</b></div>
                                    <div className={`${Style.fontBold}`} style={{width:'60%'}}><b>Email</b></div>
                                    <div className={`${Style.fontBold}`} style={{width:'22%'}}><b>Actions</b></div>
                                </div>
                                <div className={`${Style.tableContainerStyle} p-4 mt-3`}>
                                    <div className={`${Style.tableStyle} ${Style.ScrollStyle}`}>
                                        {
                                            this.props.users.map((item, index) => {
                                                return <div key={item.uid} className={`${ index%2 === 0 ? Style.rowBg : Style.customRowBg} px-4 py-2`}>
                                                    <div className={`d-flex p-2`}>
                                                        <div className={`${Style.fontBold}`} style={{width:'18%'}}>
                                                            {item.displayName}
                                                        </div>
                                                        <div style={{width:'60%'}}>
                                                            <div>
                                                                {item.email}
                                                            </div>
                                                            <div>
                                                                Created <i> {formatDetectionDatetime(item.metadata.creationTime)}</i>
                                                            </div>
                                                        </div>
                                                        <div onClick={() => this.handleRemoveAdmin(item.uid)} className={`${Style.cursorStyle} d-flex`} style={{width:'22%'}}>
                                                            <div>
                                                                <img style={{height:'15px'}} src={DashIcon} alt="remove-icon"/>
                                                            </div>
                                                            <div className={`${Style.removeAdmin} ml-1`}>
                                                                Remove Admin
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className={`${Style.inviteUserBox} d-flex p-4 mx-1 align-items-center mt-3`}>
                                        <div>
                                            <img style={{height:'20px'}} src={PlusIcon} alt="plus-icon"/>
                                        </div>
                                        <div>
                                            <button onClick={this.handleInviteAdmin} className={`${Style.submitButton} px-4 ml-3 focus-none`}>Add and invite admin</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                </LoaderWrapper>
                {this.props.statusCode && <ModalPopup statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    users: state.entities.customers.userList,
    userFlag: state.entities.customers.userListFlag,
    userRole: state.entities.user.roles,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode,
    loading: state.entities.customers.loading
})

const mapDispatchToProps = (dispatch) => ({
    getUsersList: (gcipTenantId, customerId, roleType) => dispatch(getUsersList(gcipTenantId, customerId, roleType)),
    removeAdmin: (userId) => dispatch(removeAdmin(userId)),
})
  
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminManagement))