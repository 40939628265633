import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'

import ModalPopup from './common/ModalPopup'
import IncidentFilter from './incidentFilter'
import IncidentList from './incidentList'
import IncidentGrid from './incidentGrid'
import NoLicensePopup from './NoLicensePopup'
import Style from './incidents.module.css'
import { getBrands, getExtensionUsersCount } from '../store/entities/customers'
import { loadEvents, eventReceived, setViewType } from '../store/entities/events'
import { isGlobalView } from '../utilities/config'
import './incidents.scss'
import IncidentCharts from './incidentCharts'

class Incidents extends Component {
  state = {
    viewType: this.props.viewType,
    showPopup : false,
  }

  componentDidMount() {
    const { selectedCustomer } = this.props
    this.setState({ viewType: this.props.viewType })
    
    if (selectedCustomer && selectedCustomer.id === this.props.match.params.id) {
      this.props.getBrands(selectedCustomer.id, selectedCustomer?.defaultCustomerId, isGlobalView(selectedCustomer.organizationName));
      this.props.getExtensionUsersCount(selectedCustomer.id, selectedCustomer?.defaultCustomerId, isGlobalView(selectedCustomer.organizationName));
      // if (this.props.isEventsLoadOnceIncidents) {
        this.props.loadEvents(this.props.threatFilters, selectedCustomer.id, selectedCustomer.defaultCustomerId, isGlobalView(this.props.selectedCustomer.organizationName));
        // this.props.callEventsOnceIncidents()
      // }
    }
  }

  componentDidUpdate(prevProps) {
    const { selectedCustomer } = this.props
    this.props.setViewType(this.state.viewType);
    if(prevProps !== this.props && this.props.usersCount < 1 && this.props.showWelcomePopup){
      this.setState({showPopup: true});
    }
    
    if(prevProps.selectedCustomer !== selectedCustomer && selectedCustomer.id === this.props.match.params.id) {     
      this.props.getBrands(this.props.match.params.id, selectedCustomer?.defaultCustomerId, isGlobalView(this.props.selectedCustomer.organizationName));
      this.props.loadEvents(this.props.threatFilters, this.props.match.params.id, selectedCustomer.defaultCustomerId, isGlobalView(this.props.selectedCustomer.organizationName));
    }
  }

  handleViewSelect = (viewType) => {
    this.setState({ viewType: viewType })
  }

  handleShowHidePopup = (value) => {
    this.setState({showPopup: value});
  }

  closePopup = () => {
    if(this.state.showPopup){
      this.setState({showPopup: false});
      }
  }

  handleDetails = (record) => {
    const { selectedCustomer } = this.props;
    this.props.eventReceived({ response: record })
    if (!isGlobalView(selectedCustomer.organizationName)) {
      this.props.history.push(`/customers/${selectedCustomer.id}/incidents/${record.id}`)
      return
    }
    this.props.history.push(`/tenants/${selectedCustomer.id}/${record.customerId}/incidents/${record.id}`)
  }

  render() {
    const { viewType } = this.state
    const { selectedCustomer } = this.props
    return (
      <div>
        {(this.state.showPopup && this.props.showWelcomePopup && this.props.tokenRoles !== 'PixmAdmin') && <div className={`${Style.box}`}><NoLicensePopup showOrHidePopup={this.handleShowHidePopup} /></div>}
        <div onClick={this.closePopup}>
          <IncidentFilter
            viewType={viewType}
            urlId={this.props.match.params.id}
            onViewSelect={this.handleViewSelect}
          />
          <br />
          {viewType === 'chart' && <IncidentCharts />}
          {viewType === 'grid' && <IncidentGrid onDetails={this.handleDetails} />}
          {selectedCustomer ? viewType === 'list' && <IncidentList onDetails={this.handleDetails} handleViewSelect={this.handleViewSelect} switchToChart={this.props.switchToChart} tokenRoles={this.props.tokenRoles}/> : null}
          {this.props.statusCode || this.props.eventStatusCode ? <ModalPopup statusCode={this.props.statusCode ? this.props.statusCode : this.props.eventStatusCode} msg={this.props.userMsg ? this.props.userMsg : this.props.eventUserMsg} /> : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  threatFilters: state.entities.events.threat.filters,
  eventUserMsg: state.entities.events.userMsg,
  eventStatusCode: state.entities.events.statusCode,
  orgList: state.entities.customers.list,
  selectedCustomer: state.entities.customers.selected,
  userMsg: state.entities.customers.userMsg,
  statusCode: state.entities.customers.statusCode,
  viewType: state.entities.events.threatView.currentView,
  // isEventsLoadOnceIncidents: state.entities.events.isEventsLoadOnceIncidents,
  usersCount: state.entities.customers.usersCount,
  showWelcomePopup: state.entities.customers.showWelcomePopup,
  tokenRoles: state.entities.user.roles,
  switchToChart : state.entities.events.switchToChart
})

const mapDispatchToProps = (dispatch) => ({
  eventReceived: (data) => dispatch(eventReceived(data)),
  loadEvents: (offset, selectedId, defaultCustomerId, isGlobalView) => dispatch(loadEvents(offset, selectedId, defaultCustomerId, isGlobalView)),
  getBrands:(selectedId, defaultCustomerId, isGlobalView) => dispatch(getBrands(selectedId, defaultCustomerId, isGlobalView)),
  getExtensionUsersCount: (customerId, defaultCustomerId, isGlobalView) => dispatch(getExtensionUsersCount(customerId, defaultCustomerId, isGlobalView)),
  setViewType: (viewType) => dispatch(setViewType(viewType)),
  // callEventsOnceIncidents: () => dispatch(callEventsOnceIncidents()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Incidents)
