import { createSlice } from '@reduxjs/toolkit'

let lastId = 0

const slice = createSlice({
  name: 'devices',
  initialState: [],
  reducers: {
    deviceAdded: (devices, action) => {
      devices.push({ id: ++lastId, name: action.payload.name })
    },
  },
})

export const { deviceAdded } = slice.actions
export default slice.reducer
