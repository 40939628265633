import React, { useEffect, useState } from 'react'
import ChartHorizontalBar from './ChartHorizontalBar'

const ChartHorizontalBarBuild = (props) => {
  const [_urls, setUrls] = useState([])
  const [_urlsCount, setUrlsCount] = useState([])
  const [_users, setUsers] = useState([])
  const [_usersCount, setUsersCount] = useState([])

  useEffect(() => {
    if (props.records !== null) {
      buildChart(props.eventType)
    }
  }, [props])

  const buildChart = (param) => {
    let urls = []
    let urlsCount = []
    let users = []
    let usersCount = []
    if (param === 'url') {
      setUrls([])
      setUrlsCount([])
    } else {
      setUsers([])
      setUsersCount([])
    }

    if (param === 'url') {
      props.records.forEach((item) => {
          urls.push(item['url']);
          urlsCount.push(item['count']);
      })
      setUrls(urls)
      setUrlsCount(urlsCount)
    } else {
      props.records.forEach((item) => {
        users.push(item['email']);
        usersCount.push(item['count']);
      })
      setUsers(users)
      setUsersCount(usersCount)
    }
  }

  return (
    <>
      {props.eventType === 'url' ? (
        <ChartHorizontalBar
          yaxis={_urls.length > 0 ? _urls : null}
          data={_urlsCount.length > 0 ? _urlsCount : null}
        />
      ) : (
        <ChartHorizontalBar
          yaxis={_users.length > 0 ? _users : null}
          data={_usersCount.length > 0 ? _usersCount : null}
        />
      )}
    </>
  )
}

export default ChartHorizontalBarBuild
