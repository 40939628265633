import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit'
import reducer from './reducer'
import logger from './middleware/logger'
import toastify from './middleware/toastify'
import api from './middleware/api'
import cache from './middleware/cache';
import filters from './middleware/filters';

export default function () {

  const store = configureStore({
    reducer,
    middleware: [
      ...getDefaultMiddleware(),
      logger({ destination: 'console' }),
      toastify,
      api,
      cache,
      filters,
    ],
  })
  return store
}
