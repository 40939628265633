import React, { Component } from 'react'
import { connect } from 'react-redux'
import { clearIncident } from '../../../store/entities/events'
import ConfirmPopup from './confirmPopup'
import './updatePopover.scss'
import { RiDeleteBin5Line } from 'react-icons/ri'

class UpdateClear extends Component {
  state = {
    shouldShow: false,
    messageDetails: {
      icon: '',
      type: 'error',
      title: 'Are You Sure ?',
      description: 'Do you want to delete this URL ?',
      buttons: 'Y,C',
    },
  }

  componentDidMount() {
    const { role, tokenRoles } = this.props
    const accessibleRoles = role.split(',')
    const shouldShow = accessibleRoles.filter((value) =>
      tokenRoles.includes(value),
    )
  }

  redirectToIncidents = () => {
    if (this.props.from === 'incidentDetails') {
      this.props.history.push('/incidents')
    }
  }

  handleClose = () => {
    this.setState({
      shouldShow: false,
    })
  }

  handleOpenPopup = () => {
    this.setState({
      shouldShow: true,
    })
  }

  handleDelete = () => {
    const { event, clearIncident, selectedCustomer } = this.props
    const { id: eventId , customerId } = event
    clearIncident({ eventId }, customerId)
    this.redirectToIncidents()
  }

  render() {
    const { event } = this.props
    return (
      <div>
        <div onClick={this.handleOpenPopup}>
          <RiDeleteBin5Line className="_deleteBtn" />
        </div>
        {this.state.shouldShow && (
          <ConfirmPopup
            details={this.state.messageDetails}
            onYesClick={this.handleDelete}
            onCancelClick={this.handleClose}
          />
        )}
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  tokenRoles: state.entities.user.roles,
  selectedCustomer: state.entities.customers.selected,
})

const mapDispatchToProps = (dispatch) => ({
  clearIncident: (events, customerId) =>
    dispatch(clearIncident(events, customerId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UpdateClear)
