import React, { Component } from 'react'
import { Modal, Button } from 'react-bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css';
import { resetModal } from '../../store/entities/customers'
import { resetEventsModal } from '../../store/entities/events'
import { connect } from 'react-redux'



class ModalPopup extends Component {

    state = {
        showModal: false
    }

    componentDidMount() {
        this.setState({
            showModal: true
        })
        // console.log(this.props.msg)
    }

    handleOkayClick = () => {
        if(this.props.handleClick && this.props.statusCode === 200)
            this.props.handleClick()
        this.setState({
            showModal: false
        })
        this.props.resetModal()
        this.props.resetEventsModal()
        
    }

    render() {

        return (
            <React.Fragment>
                <Modal
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    show={this.state.showModal}
                >
                    <Modal.Header>
                        <Modal.Title id="contained-modal-title-vcenter">
                            { this.props.statusCode === 200 ? 'Success' : this.props.statusCode === 203 ? 'Info' : this.props.statusCode === '201' ? 'Validation' : 'Error' }
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>
                            {
                            this.props.msg && this.props.msg !== null ?
                            this.props.msg.cleared ? 'Incident Deleted' : 
                            this.props.msg.IsRemovedThreat ? 'Marked As Unverified' : 
                            this.props.msg.includes("VerifiedThreat") ? 'Marked As Verified' :
                            this.props.msg.includes("FalsePositiveTriage") ? 'Marked As False Positive' :
                            this.props.msg : null
                            }
                        </p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.handleOkayClick}>Okay</Button>
                    </Modal.Footer>
                </Modal>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = (dispatch) => ({
    resetModal: () => dispatch(resetModal()),
    resetEventsModal: () => dispatch(resetEventsModal())
})

export default connect(null, mapDispatchToProps)(ModalPopup)

