import React, { Component } from 'react'
import Style from './TenantManagement.module.css'
import OrganizationManagement from './OrganizationManagement'
import MspAdminManagement from './MspAdminManagement'
import Account from './Account'
import Profile from './Profile'
import { getTenantDetails } from '../store/entities/customers'
import { connect } from 'react-redux'

class TenantManagement extends Component {

  state = {
    selectedNavItem: 'Organization Management'
  }

  componentDidMount() {
    if (this.props.location.hasOwnProperty('state') && this.props.location.state !== undefined) {
      this.setState({
        selectedNavItem: this.props.location.state.tab
      })
    }

    this.props.getTenantDetails(this.props.tenantId)
  }

  handleSelectNavItem = (item) => {
    this.setState({
      selectedNavItem: item
    })
  }

  renderTab = () => {
    let { selectedNavItem } = this.state
    switch (selectedNavItem) {
      case 'Organization Management':
        return (
          <OrganizationManagement />
        )
      case 'Account':
        return (
          <Account />
        )
      case 'Admin Management':
        return (
          <MspAdminManagement />
        )

      case 'Profile':
        return (
          <Profile />
        )

      default:
    }
  }

  render() {
    let { selectedNavItem } = this.state
    return (
      <div>
        <div className={`${Style.navbar} pl-4 d-flex`}>
          <div
            onClick={() => this.handleSelectNavItem('Organization Management')}
            className={`${selectedNavItem === 'Organization Management' ? Style.navbarSelectedItemStyle : Style.navbarFontStyle} d-flex align-items-center ml-2`}>
            Organization Management
                    </div>

          <div
            onClick={() => this.handleSelectNavItem('Admin Management')}
            className={`${selectedNavItem === 'Admin Management' ? Style.navbarSelectedItemStyle : Style.navbarFontStyle} d-flex align-items-center ml-5`}>
            Admin Management
                    </div>
          <div
            onClick={() => this.handleSelectNavItem('Profile')}
            className={`${selectedNavItem === 'Profile' ? Style.navbarSelectedItemStyle : Style.navbarFontStyle} d-flex align-items-center ml-5`}>
            Profile
          </div>
          <div
            onClick={() => this.handleSelectNavItem('Account')}
            className={`${selectedNavItem === 'Account' ? Style.navbarSelectedItemStyle : Style.navbarFontStyle} d-flex align-items-center ml-5`}>
            Account
                    </div>
        </div>
        <div className='pageContainer'>
          {this.renderTab()}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  tenantId: state.entities.user.tenantId,
})

const mapDispatchToProps = (dispatch) => ({
  getTenantDetails: (tenantId) => dispatch(getTenantDetails(tenantId))
})

export default connect(mapStateToProps, mapDispatchToProps)(TenantManagement)