import { cacheCallBegan, cacheMiss } from '../cache'
import { apiCallBegan } from '../api'

const cache = ({ getState, dispatch }) => (next) => async (action) => {
  if (action.type !== cacheCallBegan.type) return next(action)

  const {
    urlPath,
    method,
    onSuccess,
    onError,
    onStart,
    onHit,
    filters,
  } = action.payload
  if (onStart) dispatch({ type: onStart })
  //   next(action);

  const state = getState()
  const { list: events } = state.entities.events
  const licenseUsers  = state.entities.customers.licenseUsers 

  if (filters.offset < events.length && onHit === 'events/cacheHit') {
    const response = await Promise.resolve({
      data: { message: 'cache hit', offset: filters.offset },
    })
    if (onHit) dispatch({ type: onHit, payload: response })
  } else if (filters.offset < licenseUsers.length && onHit === 'customers/cacheHit') {
    const response = await Promise.resolve({
      data: { message: 'cache hit', offset: filters.offset },
    })
    if (onHit) dispatch({ type: onHit, payload: response })
  } else {
    // make api call
    const response = {
      data: { message: 'cache miss' },
    }
    dispatch(cacheMiss(response))
    dispatch(
      apiCallBegan({ urlPath, method, filters, onSuccess, onError, onStart }),
    )
  }
}

export default cache
