import { axiosInstance } from '../../axiosInterceptor'
import queryString from 'query-string'
import { CookieUtils } from '../../utilities/cookies'
import * as actions from '../api'
import { setNewToken } from '../entities/user'

const api = ({ getState, dispatch }) => (next) => async (action) => {
  if (action.type !== actions.apiCallBegan.type) return next(action)

  const {
    urlPath,
    baseUrl,
    method,
    data,
    reducerData,
    onSuccess,
    onError,
    onStart
  } = action.payload
  let { filters } = action.payload

  if (onStart) dispatch({ type: onStart, reducerData })
  next(action)
  try {
    const state = getState()

    // if (roles.includes('CustomerAdmin') && !roles.includes('PixmAdmin')) {
    //   filters = { ...filters, triagedEvent: 1 }
    // }

    const response = await axiosInstance.request({
      method,
      headers: { 
        "x-functions-key" : process.env.REACT_APP_PIXMDSHBRD_KEY
      },
      data,
      baseURL: baseUrl || process.env.REACT_APP_API_BASE_URL,
      // url: `${urlPath}?${filters ? queryString.stringify(filters) : null}`,
      url: filters && Object.keys(filters).length > 0 ? `${urlPath}?${queryString.stringify(filters, { arrayFormat : 'null' })}` : `${urlPath}`,
    })

    //general
    dispatch(actions.apiCallSuccess(response.data))
    //specific
    if (onSuccess)
      dispatch({
        type: onSuccess,
        reducerData,
        payload: { response: response.data, request: data },
      })
  } catch (error) {
    //general
    dispatch(
      actions.apiCallFailed(
        (error.response &&
          typeof error.response.data === 'string' &&
          error.response.data) ||
        error.message,
      ),
    )
    //specific
    if (onError) {
      if( error && error.response && error.response.data.errors){
        dispatch({ type: onError, payload: error.response.data, reducerData })
      }
      else if (error && error.response && error.response.data && error.response.data.message)
        dispatch({ type: onError, payload: error.response.data.message, reducerData })
      else
        dispatch({ type: onError, payload: error.message, reducerData })
    }
  }
}

export default api
