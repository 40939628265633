import React, { Component } from 'react'
import Style from './AddOrganization.module.css'
import { inviteAdmin, setError, setCurrentCustomer } from '../store/entities/customers'
import { connect } from 'react-redux'
import ModalPopup from '../components/common/ModalPopup'

import BackIcon from '../assets/backButton.svg'
import { validateAlphaNumerics, validateEmail } from '../utilities/vaildations' 

class AddMspAdmin extends Component {

    constructor() {
        super();
        this.state = {
            firstName: '',
            lastName: '',
            email: '',
            setCustomerOnce: true
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this.state.setCustomerOnce && this.props.match.path.includes('settings')) {
          this.setState({
            setCustomerOnce: false
          })
          
          let filtredElement = this.props.orgList.filter((ele) => ele.id === this.props.match.params.id)
    
          if (filtredElement[0].id !== this.props.selectedCustomer.id) {
            this.props.setCurrentCustomer(filtredElement[0]);
          }
        }
      }

    handleModalClick = () => {
        if(this.props.match.path === '/tenant-admin/add-admin') {
            this.props.history.push({ pathname: '/tenant-admin', state: { tab: 'Admin Management' } })
        }
        else {
            this.props.history.push({ pathname: `/customers/${this.props.selectedCustomer.id}/settings`, state: { tab: 'Admin Management' } })
        }
    }

    handleChange = (event) => {
        const value = event.target.value
        this.setState({
            [event.target.name]: value
        })
    }

    handleClick = () => {
        if(this.props.match.path === '/tenant-admin/add-admin') {
            this.props.history.push({ pathname: '/tenant-admin', state: { tab: 'Admin Management' } })
        }
        else {
            this.props.history.push({ pathname: `/customers/${this.props.selectedCustomer.id}/settings`, state: { tab: 'Admin Management' } })
        }
    }

    handleSubmit = () => {

        let error = ''

        if(this.state.email === '') {
            let error = "Email is required"
            this.props.setError(error)
        } else if (!validateEmail(this.state.email) || this.state.email.length > 200) {
            error = "Please enter a proper email id"
            this.props.setError(error)
        }

        if(this.state.lastName === '') {
            error = "Last name is required"
            this.props.setError(error)
        } else if (!validateAlphaNumerics(this.state.lastName) || this.state.firstName.length > 50) {
            error = "Last name should only be alphanumeric and not more than 50 characters"
            this.props.setError(error)
        }

        if(this.state.firstName === '') {
            error = "First name is required"
            this.props.setError(error)
        } else if (!validateAlphaNumerics(this.state.firstName) || this.state.firstName.length > 50) {
            error = "First name should only be alphanumeric and not more than 50 characters"
            this.props.setError(error)
        }

        if(error === '') {
            let body = {
                "firstName": this.state.firstName,
                "lastName": this.state.lastName,
                "email": this.state.email,
                "role": this.props.match.path === '/tenant-admin/add-admin' ? 'TenantAdmin' : 'CustomerAdmin'
            }
            if (body.role === "CustomerAdmin")
                body.customerId = this.props.selectedCustomer.id;

            this.props.inviteAdmin(body)
        }
        
    }

    render() {
        return (
            <div className={'px-5 mt-4'}>
                <div className={`${Style.headerBg} px-5 d-flex align-items-center justify-content-between`}>
                    <div onClick={this.handleClick} style={{ cursor: 'pointer' }}>
                        <img style={{ height: '1.5vw' }} src={BackIcon} alt={'back-icon'} />
                        Return to Admin Management
                    </div>
                </div>
                <div className={`${Style.detailsContainer} w-50 mt-4 py-5 px-5`}>
                    <div className={`d-flex flex-column`}>
                        <input name='firstName' placeholder={'First Name'} className={`${Style.inputStyle} focus-none mt-3`} onChange={this.handleChange} />

                        <input name='lastName' placeholder={'Last Name'} className={`${Style.inputStyle} focus-none mt-3`} onChange={this.handleChange} />

                        <input name='email' placeholder={'Email'} className={`${Style.inputStyle} focus-none mt-3`} onChange={this.handleChange} />
                    </div>
                    <div className={'mt-4'}>
                        <button onClick={this.handleSubmit} className={`${Style.submitButton} px-4 focus-none`}>Invite Admin</button>
                        <button onClick={this.handleClick} className={`${Style.cancelButton} px-4 ml-2 focus-none`}>Cancel</button>
                    </div>
                </div>
                {this.props.statusCode && <ModalPopup handleClick={this.handleModalClick} statusCode={this.props.statusCode} msg={this.props.userMsg}/> }
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userFlag: state.entities.customers.userListFlag,
    userRole: state.entities.user.roles,
    userMsg: state.entities.customers.userMsg,
    statusCode: state.entities.customers.statusCode,
    orgList: state.entities.customers.list,
    selectedCustomer: state.entities.customers.selected
})

const mapDispatchToProps = (dispatch) => ({
    inviteAdmin: (body) => dispatch(inviteAdmin(body)),
    setError: (error) => dispatch(setError(error)),
    setCurrentCustomer: (customerId) => dispatch(setCurrentCustomer(customerId)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddMspAdmin)