import React, { Component } from 'react'
import { produce } from 'immer'
import { connect } from 'react-redux'
import { isGlobalView } from '../../../utilities/config'

import NextSvg from '../../../assets/chevronRight.svg'
import PrevSvg from '../../../assets/chevronLeft.svg'

import './paginator.scss'
import { setThreatFiltersInStore } from '../../../store/entities/events'
import { setCustomersFilters } from '../../../store/entities/customers'

class Paginator extends Component {
  state={
    filters: this.props.filters,
  }

  setThreatFilters = (changedValues) => {
    this.state.filters = produce(this.state.filters, (draftState) => {
      Object.keys(changedValues).map((property) => {
        draftState[property] = changedValues[property]
      })
    })
    this.props.setThreatFiltersInStore(this.state.filters)
  }

  setCustomersFilters = (changedValues) => {
    this.state.filters = produce(this.state.filters, (draftState) => {
      Object.keys(changedValues).map((property) => {
        draftState[property] = changedValues[property]
      })
    })
    this.props.setCustomersFilters(this.state.filters)
  }

  handlePrev = () => {
    const { selectedCustomer, from } = this.props
    const { filters } = this.state
    const { offset, pageSize } = filters
    let objFilter = {}
    if (from === "licenseManagement"){
      objFilter['offset'] = offset - pageSize
      this.setCustomersFilters(objFilter)
    } else {
      objFilter['offset'] = offset - pageSize + 1
      this.setThreatFilters(objFilter) 
    }    
    this.props.onPrev(this.state.filters, selectedCustomer.id, selectedCustomer.defaultCustomerId, isGlobalView(selectedCustomer.organizationName));
  }

  handleNext = () => {
    const { cacheSize, selectedCustomer, from } = this.props
    const { filters } = this.props
    const { offset, pageSize } = filters
    let objFilter = {}    
    objFilter['pageSize'] = pageSize    
    if (from === "licenseManagement"){
      objFilter['offset'] = offset + pageSize
      this.setCustomersFilters(objFilter)
    } else { 
      objFilter['offset'] = offset + pageSize - 1
      this.setThreatFilters(objFilter)  
      this.state.filters = produce(this.state.filters, (draftState) => {
        delete draftState['requireTotalRecordsCount']
      })
      this.props.setThreatFiltersInStore(this.state.filters)
    }
    this.props.onNext(this.state.filters, selectedCustomer.id, selectedCustomer.defaultCustomerId,isGlobalView(selectedCustomer.organizationName));
  }

  render() {
    const { totalSize, from } = this.props
    const { pageSize, offset } = this.props.filters
    return (
      <> 
        {from === 'licenseManagement' ? (
          <div className="d-flex justify-content-center">
            <button
              className="page-navigation mx-2 page-nav-active"
              onClick={this.handlePrev}
              disabled={offset < pageSize}
            >
              <img src={PrevSvg} width="24px" alt="" />
            </button>
    
            {offset - 2 * pageSize >= 0 && <div className="mx-2 ">...</div>}
    
            {offset - pageSize >= 0 && (
              <div className="mx-2 page-no">
                {(offset - pageSize) / pageSize + 1}
              </div>
            )}
    
            <div className="mx-2 page-no">
              <div>{offset / pageSize + 1}</div>
            </div>
    
            {offset + pageSize < totalSize && (
              <div className="mx-2 page-no">
                {(offset + pageSize) / pageSize + 1}
              </div>
            )}
    
            {offset + 2 * pageSize < totalSize && <div className="mx-2 ">...</div>}
    
            <button
              className="page-navigation mx-2 page-nav-active"
              onClick={this.handleNext}
              disabled={offset + pageSize >= totalSize}
            >
              <img src={NextSvg} width="24px" alt="" />
            </button>
          </div>
        ) : (
          //for threat feed
          <div className="d-flex justify-content-center">
            <button
              className="page-navigation mx-2 page-nav-active"
              onClick={this.handlePrev}
              disabled={offset < pageSize - 1}
            >
              <img src={PrevSvg} width="24px" alt="" />
            </button>
    
            {offset - 2 * (pageSize - 1) >= 0 && <div className="mx-2 ">...</div>}
    
            {offset - (pageSize - 1) >= 0 && (
              <div className="mx-2 page-no">
                {(offset - (pageSize - 1)) / (pageSize - 1) + 1}
              </div>
            )}
    
            <div className="mx-2 page-no">
              <div>{offset / (pageSize - 1) + 1}</div>
            </div>
    
            {offset + (pageSize - 1) < totalSize && (
              <div className="mx-2 page-no">
                {(offset + (pageSize - 1)) / (pageSize - 1) + 1}
              </div>
            )}
    
            {offset + 2 * (pageSize - 1) < totalSize && <div className="mx-2 ">...</div>}
    
            <button
              className="page-navigation mx-2 page-nav-active"
              onClick={this.handleNext}
              disabled={offset + pageSize - 1 >= totalSize}
            >
              <img src={NextSvg} width="24px" alt="" />
            </button>
          </div>
        )}      
      </>
    )
  }
}

const mapStateToProps = (state) => ({
  selectedCustomer: state.entities.customers.selected
})

const mapDispatchToProps = (dispatch) => ({
  setThreatFiltersInStore: (filters) => dispatch(setThreatFiltersInStore(filters)),
  setCustomersFilters: (filters) => dispatch(setCustomersFilters(filters)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Paginator);
