import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';
import { v4 as uuidv4 } from 'uuid';
import './selectBox.scss';

import { ReactComponent as ChevronDown } from '../../../assets/chevronDown.svg';
import searchSvg from "../../../assets/magnifier.svg";
import closeSvg from "../../../assets/close.svg";


let defaultDef = {
  "codeField": "code",
  "descField": "desc",
}

class DropDown extends Component {
  defaultName = "dropDown" + uuidv4();

  state = {
    name: typeof this.props.name === "string" ? this.props.name : this.defaultName,
    id: typeof this.props.id === "string" ? this.props.id : this.defaultName,
    definition: typeof this.props.definition === "object" ? this.props.definition : defaultDef, 
    data: typeof this.props.data === "object" ? this.props.data : [],
    width: typeof this.props.width === "string" ? this.props.width : "300px",
    searchText: "",
    selectedOption: "",
    searchBoxPlaceholder: "Type here to search",
    showSelectionText: typeof this.props.showSelectionText === "boolean" ? this.props.showSelectionText : true,
    selectionText: typeof this.props.selectionText === "string" ? this.props.selectionText : "-- Select --",
    dropdownClasses: ['options-container', 'd-flex', 'flex-column'],
    chevClasses: ['ml-auto']
  }

  componentDidMount() {
  }

  componentDidUpdate(prevProps) {
    if(prevProps.definition !== this.props.definition){
      this.setState({ definition: this.props.definition });
    }
    if(prevProps.data !== this.props.data){
      this.setOptionsData(this.props.data);
    }
    if(prevProps.showSelectionText !== this.props.showSelectionText){
      this.setState({ showSelectionText: this.props.showSelectionText });
    }
    if(prevProps.selectionText !== this.props.selectionText){
      this.setState({ selectionText: this.props.selectionText });
    }
    if(prevProps.name !== this.props.name){
      this.setState({ name: this.props.name });
    }
    if(prevProps.id !== this.props.id){
      this.setState({ id: this.props.id });
    }
  }

  componentWillUnmount() {
  }

  setOptionsData = (data) => {
    this.setState({ data: data });
  }

  handleSearch = (e) => {
    let filteredOptions = this.props.data.filter((option) => {
      return option[this.state.definition.descField].toLowerCase().includes(e.target.value.toLowerCase())
    });
    this.setState({ searchText: e.target.value });
    this.setOptionsData(filteredOptions);
  }

  handleSearchClose = (e) => {
    this.setState({ data: this.props.data, searchText: "" });
  }

  onOptionClick = (e, option) => {
    const { dropdownClasses, chevClasses } = this.state;
    dropdownClasses.pop();
    chevClasses.pop();
    this.setState({ dropdownClasses, chevClasses, searchText: "", selectedOption: option[this.state.definition.descField] });
    this.setOptionsData(this.props.data);
    if (typeof this.props.onSelected === "function"){
      this.props.onSelected(option);
    }
  }

  handleDropdownToggle = (e) => {
    // console.log("Toggled", e);
    const { dropdownClasses, chevClasses } = this.state
    if (dropdownClasses.indexOf('active') === -1) {
      dropdownClasses.push('active')
      chevClasses.push('toggle-chev')
    } else {
      dropdownClasses.pop()
      chevClasses.pop()
    }
    this.setState({ dropdownClasses, chevClasses })

  }

  render() {
    return (
        <div className="select-box" style={{width: this.state.width, minWidth: this.state.width}} onBlur={this.handleDropdownToggle}>
          <div style={{width: "362px", display: "flex", flexDirection: "column" }} className={this.state.dropdownClasses.join(' ')}>
            <div className="d-flex flex-grow-1 align-items-center search-box-outline position-sticky bg-white" style={{ top: 0, zIndex: 1 }}>
              <img className="search-box-magnifier" src={searchSvg} alt="" srcSet="" />
              <DebounceInput className="search-box" minLength={2} debounceTimeout={300} value={this.state.searchText} onChange={this.handleSearch} placeholder = {this.state.searchBoxPlaceholder} />
              <img className="search-box-close" src={closeSvg} alt="" srcSet="" onClick={this.handleSearchClose} />
            </div>
            {
              this.state.showSelectionText &&
              <Fragment key={`frg-${this.state.name}-defaultSelectionText`}>
                <div className="option" htmlFor={`op-${this.state.id}-defaultCode`} onClick={(e) => this.onOptionClick(e, {})} >
                  <input type="checkbox" className="checkbox" id={`op-${this.state.id}-defaultCode`} name={`op-${this.state.name}`} value={this.state.selectionText} />
                  <label style={{ margin: 0 }}>{this.state.selectionText}</label>
                </div>
                <span style={{ borderBottom: '1px solid #e3e2e2' }}></span>
              </Fragment>
            }

            {this.state.data.map((option) => (
              <Fragment key={`frg-${this.state.name}-${option[this.state.definition.codeField]}`}>
                <div className="option" htmlFor={option[this.state.definition.codeField]} onClick={(e) => this.onOptionClick(e, option)} >
                  <input type="checkbox" className="checkbox" id={`op-${this.state.id}-${option[this.state.definition.codeField]}`} name={`op-${this.state.name}`} value={option[this.state.definition.descField]} />
                  <label id={`txtLbl-${this.state.id}-${option[this.state.definition.codeField]}`} name={`txtLbl-${this.state.name}`} style={{ margin: 0 }}>{option[this.state.definition.descField]}</label>
                </div>
                <span style={{ borderBottom: '1px solid #e3e2e2' }}></span>
              </Fragment>
            ))}
          </div>
          <div onClick={this.handleDropdownToggle} className="selected d-flex align-items-center" >
            <img src={this.props.icon} alt="" className='icon' />
            <div className={'text-truncate'}>
              <span className={this.state.selectedOption ? 'initText' : 'selectedInitText'}>
                {this.state.selectedOption ? this.state.selectedOption :  this.state.showSelectionText ? this.state.selectionText : null}
              </span>
            </div>
            <ChevronDown className={this.state.chevClasses.join(' ')} width="23px" />
          </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = (dispatch) => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(DropDown)
