import { toast } from 'react-toastify'

const toastify = (store) => (next) => (action) => {
  if (action.type === 'api/callFailed') {
    // toast.error(action.payload || 'Error')
  }
  next(action)
}

export default toastify
