import React from 'react'
import EnterIcon from '../../../assets/Enter_Arrow_Right.svg'
import UpdatePopover from '../popover/updatePopover'
import { formatDetectionDatetime } from '../../../utilities/datetime'
import DetailsPopover from '../popover/detailsPopover'

import './grid.scss'
import { Col, Container, Row } from 'react-bootstrap'
import { getOSType } from '../../../utilities/OSTypes'

const Grid = (props) => {
  const DeviceType = new Map([
    ['D', 'Desktop'],
    ['M', 'Mobile'],
  ])

  return (
    <div
      className="grid-card d-inline-flex flex-wrap"
      style={{ padding: '16px' }}
    >
      <Row style={{ marginLeft: '0px', width: '1276px' }}>
        {props.records.map((record, index) => (
          <Col
            xs="6"
            sm="6"
            md="6"
            lg="6"
            xl="6"
            style={{ padding: '0px', marginBottom: ' 16px' }}
            key={index}
          >
            <div
              key={record.id}
              className="grid-item"
              style={{ marginRight: '0px' }}
            >
              <div className="d-flex mb-2 align-items-center">
                {/* {record.triagedEvent === true ? (
                  <span className="mr-auto verifyText verify-status">
                    <b>Verified Phish</b>
                  </span>
                ) : record.type == 3 ? (
                  <span className="mr-auto reportedPhishText verify-status">
                    <b>Reported Phish</b>
                  </span>
                ) : (
                  <span className="mr-auto unverifyText verify-status">
                    <b>Suspicious</b>
                  </span>
                )} */}
                {<span className={`mr-auto verify-status ${record.status === "Verified Phish" ? 
                          "verifyText" : record.status === "Reported Phish"?
                          "reportedPhishText" : "unverifyText"}`}>
                    <b>{record.status}</b>
                  </span>}
                <div
                  className="grid-text font-weight-normal"
                  style={{ marginRight: '0.2rem' }}
                >
                  <UpdatePopover {...props} role="PixmAdmin" event={record} />
                </div>
              </div>
              <div
                className="image-container mb-2"
                style={{
                  lineHeight: '150px',
                  textAlign: 'center',
                }}
              >
                {record.resizedScreenshotURL && (
                  <img
                    src={record.resizedScreenshotURL}
                    style={{
                      borderRadius: '5px',
                      maxWidth: '582px',
                      maxHeight: '254px',
                    }}
                    alt=""
                    srcSet=""
                  />
                )}
                {!record.resizedScreenshotURL && 'NO DATA'}
              </div>

              <div>
                <table style={{ width: '100%', tableLayout: 'fixed' }}>
                  <tbody>
                    <tr style={{ background: 'white' }}>
                      <td>
                        <DetailsPopover
                          header={
                            record?.osUserId ||
                            record?.osComputerName ||
                            record?.osuuid ||
                            record?.email
                          }
                          data={{
                            User:
                              record?.osUserId ||
                              record?.osComputerName ||
                              record?.osuuid ||
                              record?.email,
                            'User ID': record.osUserId ? record.osUserId : 'NA',
                            Platform: `${getOSType(record.os)}, ${
                              record.browserName
                            }`,
                            'Machine ID': record.osuuid ? record.osuuid : 'NA',
                            'Computer Name': record.osComputerName
                              ? record.osComputerName
                              : 'NA',
                            Device: record.deviceType
                              ? DeviceType.get(record.deviceType)
                              : 'NA',
                          }}
                        />{' '}
                      </td>

                      <td width="55%">
                        <div
                          className="textWrap"
                          title={record.url}
                          style={{ width: '95%' }}
                        >
                          {record.url}
                        </div>
                      </td>
                      <td width="5%"></td>
                    </tr>
                    <tr>
                      {/* <td width="40%"><div style={{ width: "95%", color: "rgb(45, 95, 167)" }} title={[record.brands.slice(0, -1).join(', '), record.brands.slice(-1)[0]].join(record.brands.length < 2 ? '' : ' and ')} className="truncate"><b>{[record.brands.slice(0, -1).join(', '), record.brands.slice(-1)[0]].join(record.brands.length < 2 ? '' : ' and ')}</b></div></td> */}
                      <td width="30%">
                        <div
                          style={{ width: '95%', color: 'rgb(45, 95, 167)' }}
                          title={record.brands}
                          className="textWrap"
                        >
                          <b>{record.brands}</b>
                        </div>
                      </td>
                      <td width="55%">
                        <div
                          style={{ width: '95%' }}
                          title={formatDetectionDatetime(record.detectionTime)}
                          className="textWrap font-italic"
                        >
                          Opened at{' '}
                          {formatDetectionDatetime(record.detectionTime)}
                        </div>
                      </td>
                      <td width="5%" style={{ paddingLeft: '0px' }}>
                        {' '}
                        <img
                          style={{ cursor: 'pointer', width: '30px' }}
                          onClick={() => props.onDetails(record)}
                          src={EnterIcon}
                          alt="next-icon"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  )
}

export default Grid
