import React, { useState } from 'react'
import { Dropdown } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import './detailsPopover.scss'

const DetailsPopover = (props) => {
  const { header, data } = props
  const [show, setShow] = useState(false)

  const showDropdown = () => {
    setShow(!show)
  }
  const hideDropdown = () => {
    setShow(false)
  }

  return (
    <Dropdown onMouseEnter={showDropdown} onMouseLeave={hideDropdown}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="stausText"
      >
        <div className="userField textWrap userWrap">
          <b>{header}</b>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu show={show}>
        {Object.keys(data).map((key, index) => {
          return (
            <Dropdown.Item key={index}>
              <b>{key} : </b>
              <span>{data[key]}</span>
            </Dropdown.Item>
          )
        })}
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default DetailsPopover
