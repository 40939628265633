import React from "react";
import PopupIcon from '../assets/popup_menu.PNG';
import MiniDash from '../assets/Pixm_logo.PNG';
import Style from './NoLicensePopup.module.css'

const NoLicensePopup = props => {

  const clickHandler = () => {
    props.showOrHidePopup(false);
  }

  return (
      <div className="box">
        <div className={Style.innerBox}>  
        <div className={Style.mainDiv}>
            <div style={{display:'flex', flexDirection:'column', justifyContent:'space-between'}}>
              <p>Welcome to your <br />Threat Feed</p>
              <span className={Style.mainContent}><p>You currently don’t have the extension installed on any browsers. To get started, navigate to Deployment in main menu.</p></span>
            </div>  
            <span id={Style.menuImg}><img style={{ width: '180px' }} src={PopupIcon} alt="popup-icon" /></span>    
        </div>  
            <img className={Style.MiniDash} src={MiniDash} alt={'mini-icon'} />
          <div className= {Style.buttonDiv}>
            <button className= {Style.buttonOk} onClick={clickHandler}>Ok</button>
          </div>
        </div>
      </div>
  );
};
 
export default NoLicensePopup;